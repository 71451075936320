import { DesignToken } from '@livechat/design-system-react-components';

import { withVar } from 'helpers/styles';

import { type ITourStep } from '../interfaces';

import { ButtonStep, CustomSegmentsStep, ManageSegmentsStep } from './tour-steps';

export enum CustomSegmentsTourSteps {
  Button = 'Button',
  CustomSegments = 'CustomSegments',
  ManageSegments = 'ManageSegments',
}

export const customSegmentsSteps: ITourStep[] = [
  {
    id: CustomSegmentsTourSteps.Button,
    content: ButtonStep,
  },
  {
    id: CustomSegmentsTourSteps.CustomSegments,
    content: CustomSegmentsStep,
    elementStyles: {
      backgroundColor: withVar(DesignToken.SurfacePrimaryDefault),
    },
    placement: 'bottom',
  },
  {
    id: CustomSegmentsTourSteps.ManageSegments,
    content: ManageSegmentsStep,
    elementStyles: {
      backgroundColor: withVar(DesignToken.SurfacePrimaryDefault),
    },
    placement: 'left-start',
  },
];
