import { Cookie } from 'constants/cookies';
import { type Scope } from 'constants/scope';
import { getCookie, removeCookie, setCookie } from 'helpers/cookies';
import { type Nullable } from 'helpers/interface';

type StoreTokenOptions = {
  shouldSaveCookie: boolean;
  expiresInDays: number;
};

type StoreCredentialsOptions = {
  token: string;
  scopesString: string;
  expiresIn: string;
  isGhost: boolean;
};

/** Access token stored in memory */
let accessToken: Nullable<string> = null;

/** Scopes stored in memory */
let scopes: Nullable<Scope[]> = null;

/** Expiry time of access token stored in memory */
let expiresInString: Nullable<string> = null;

/** Whether the login is a ghost login */
let isGhost: boolean = false;

function convertSecondsToDays(seconds: string | number): number {
  return Number(seconds) / (60 * 60 * 24);
}

/**
 * Get access token from cookie
 * @returns {Nullable<string>} The access token or null if not found
 */
const getAccessTokenFromCookie = (): Nullable<string> => {
  return getCookie<string>(Cookie.AccessToken);
};

/**
 * Get scopes from cookie
 * @returns {Nullable<string>} The scopes or null if not found
 */
const getScopesFromCookie = (): Nullable<string> => {
  return getCookie<string>(Cookie.Scopes);
};

/**
 * Stores the access token in memory and optionally in a cookie.
 *
 * @param {string} token - The access token to be stored.
 * @param {StoreTokenOptions} options - An object containing options for storing the token.
 * @param {boolean} options.shouldSaveCookie - If true, the token will also be stored in a cookie.
 * @param {string} options.expiresInDays - The time in days until the token expires.
 */
const storeAccessToken = (token: string, { shouldSaveCookie, expiresInDays }: StoreTokenOptions): void => {
  accessToken = token;

  if (shouldSaveCookie) {
    setCookie(Cookie.AccessToken, token, { days: expiresInDays });
  }
};

/**
 * Store scopes in memory and in cookie
 * @param {string} scopesString - The scopes as a comma-separated string
 * @param {StoreTokenOptions} options - An object containing options for storing the scopes.
 * @param {boolean} options.shouldSaveCookie - If true, scopes will also be stored in a cookie.
 * @param {string} options.expiresInDays - The time in days until the token expires.
 */
const storeScopes = (scopesString: string, { shouldSaveCookie, expiresInDays }: StoreTokenOptions): void => {
  scopes = scopesString.split(',') as Scope[];

  if (shouldSaveCookie) {
    setCookie(Cookie.Scopes, scopesString, { days: expiresInDays });
  }
};

/**
 * Store the expiry time of the access token
 * @param {string} expiresIn - The time in seconds until the token expires
 */
const storeExpiresIn = (expiresIn: string): void => {
  expiresInString = expiresIn;
};

/**
 * Store whether the login is a ghost login
 * @param {boolean} isGhostLogin - Ghost login flag
 */
const storeGhostLogin = (isGhostLogin: boolean): void => {
  isGhost = isGhostLogin;
};

/**
 * Store credentials in memory and in cookies
 * @param {StoreCredentialsOptions} options - An object containing options for storing the credentials
 * @param {string} token - The access token
 * @param {string} scopesString - The scopes as a comma-separated string
 * @param {string} expiresIn - The time in seconds until the token expires
 * @param {boolean} isGhost - Whether the login is a ghost login
 */
export const storeCredentials = ({
  token,
  scopesString,
  expiresIn = '432000',
  isGhost = false,
}: StoreCredentialsOptions): void => {
  const shouldSaveCookie = !isGhost;
  const expiresInDays = convertSecondsToDays(expiresIn);
  storeAccessToken(token, { shouldSaveCookie, expiresInDays });
  storeScopes(scopesString, { shouldSaveCookie, expiresInDays });
  storeExpiresIn(expiresIn);
  storeGhostLogin(isGhost);
};

/**
 * Loads user credentials from cookies.
 *
 * This function retrieves the access token, scopes, and ghost login status from cookies
 * and assigns them to the respective variables.
 */
export const loadCredentialsFromCookies = (): void => {
  accessToken = getAccessTokenFromCookie();
  scopes = getScopesFromCookie()?.split(',') as Scope[];
};

/**
 * Get access token from memory
 * @returns {Nullable<string>} The access token or null if not found
 */
export const getAccessToken = (): Nullable<string> => {
  return accessToken;
};

/**
 * Get scopes from memory
 * @returns {Nullable<Scope[]>} Array of scopes or null if not found
 */
export const getScopes = (): Nullable<Scope[]> => {
  return scopes;
};

/**
 * Get expiry time of access token
 * @returns {Nullable<string>} The expiry time or null if not found
 */
export const getExpiresIn = (): Nullable<string> => {
  return expiresInString;
};

/**
 * Check if the login is a ghost login
 * @returns {boolean} Whether the login is a ghost login
 */
export const isGhostLogin = (): boolean => {
  return isGhost;
};

/**
 * Get region from access token
 * @returns {string} The region or null if not found
 */
export const getRegion = (): Nullable<string> => {
  if (!accessToken) {
    return null;
  }

  const parts = accessToken.split(':');

  return parts.length > 1 ? parts[0] : null;
};

/**
 * Clear credentials from memory and cookies
 */
export const clearCredentials = (): void => {
  accessToken = null;
  scopes = null;
  removeCookie(Cookie.AccessToken);
  removeCookie(Cookie.Scopes);
};
