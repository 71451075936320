import { UserType } from 'constants/user-type';
import type { KeyMap } from 'helpers/interface';

import type { IMessage, ISurveyMessage } from '../interfaces';

import { isMessage, isSurvey } from './common';

export function createLabeledMessageFromThreadEvents(
  events: KeyMap<IMessage | ISurveyMessage>,
  limit?: number,
): string {
  if (limit !== undefined && limit < 1) {
    throw new Error('Limit must be a positive number');
  }

  const messages: string[] = [];
  const agentMarker = '[Agent] ';
  const customerMarker = '[Customer] ';
  const customerPrechatMarker = '[Customer metadata] ';
  const endOfLineMarker = '\n';

  const reversedEvents = Object.values(events).reverse();
  const firstCustomerMessageIndex = reversedEvents.findIndex((event) => event.authorType === UserType.Customer);
  const reversedEventsFromFirstCustomerMessage = reversedEvents.slice(firstCustomerMessageIndex);

  for (const event of reversedEventsFromFirstCustomerMessage) {
    if (
      limit !== undefined &&
      messages.length >= limit &&
      messages.some((message) => message.includes(customerMarker))
    ) {
      break;
    }

    if (isMessage(event) && event.authorType === UserType.Agent) {
      messages.push(agentMarker.concat(event.text, endOfLineMarker));
    }

    if (isMessage(event) && event.authorType === UserType.Customer) {
      messages.push(`${customerMarker}${event.text}${endOfLineMarker}`);

      if (limit !== undefined && messages.length >= limit) {
        break;
      }
    }

    if (isSurvey(event)) {
      const surveyText = event.responses.reduce((acc, response) => {
        if (['email', 'name'].includes(response.type) || !response.answer) {
          return acc;
        }

        return acc.concat(customerPrechatMarker, `${response.question}: ${response.answer}`, endOfLineMarker);
      }, '');

      messages.push(surveyText);
    }
  }

  return messages.reverse().join('');
}
