import { createMlGatewayApiClient } from '../client';
import { type GenericMlGatewayError } from '../types';

import type {
  AutotagsSetupError,
  BatchDeleteCannedResponseAutotagsRequest,
  BatchDeleteCannedResponseAutotagsResponse,
  CreateCannedResponseAutotagsRequest,
  CreateCannedResponseAutotagsResponse,
  DeleteCannedResponseAutotagRequest,
  DeleteCannedResponseAutotagsRequest,
  GetCannedResponseAutotagsForOrganization,
  RemoveCannedResponseAutotagResponse,
  RemoveCannedResponseAutotagsResponse,
  SetupAutotagsResponse,
  SetupCannedResponseAutotagsRequest,
  UpdateCannedResponseAutotagsRequest,
  UpdateCannedResponseAutotagsResponse,
} from './types';

/**
 * Client is now an arrow function to avoid creating a client with missing region.
 * Region is saved in configuration during startup process, not at the time of invoking the main code line during imports.
 * todo: Remove the arrow function when regions are no longer present in the configuration object.
 */
const client = () => createMlGatewayApiClient();

export const autotagsClient = {
  getAutotagsConfig: async () => {
    return client().post<GetCannedResponseAutotagsForOrganization, GenericMlGatewayError>('autotags/list-configs', {});
  },

  createCannedResponseAutotags: async (data: CreateCannedResponseAutotagsRequest) => {
    return client().post<CreateCannedResponseAutotagsResponse, GenericMlGatewayError>('autotags/add-config', {
      json: data,
    });
  },

  updateCannedResponseAutotags: async (data: UpdateCannedResponseAutotagsRequest) => {
    return client().post<UpdateCannedResponseAutotagsResponse, GenericMlGatewayError>('autotags/set-config', {
      json: data,
    });
  },

  deleteCannedResponseAutotags: async (data: DeleteCannedResponseAutotagsRequest) => {
    return client().post<RemoveCannedResponseAutotagsResponse, GenericMlGatewayError>('autotags/delete-config', {
      json: data,
    });
  },

  deleteCannedResponseAutotag: async (data: DeleteCannedResponseAutotagRequest) => {
    return client().post<RemoveCannedResponseAutotagResponse, GenericMlGatewayError>('autotags/delete-tag', {
      json: data,
    });
  },

  batchDeleteCannedResponseAutotags: async (data: BatchDeleteCannedResponseAutotagsRequest[]) => {
    return client().post<BatchDeleteCannedResponseAutotagsResponse, GenericMlGatewayError>('autotags/cleanup-configs', {
      json: data,
    });
  },

  setupCannedResponseAutotags: async (data: SetupCannedResponseAutotagsRequest) => {
    return client().post<SetupAutotagsResponse, AutotagsSetupError>('autotags/create-initial-config', {
      json: data,
    });
  },
};
