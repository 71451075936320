import type { ThemeName } from 'constants/themes';

import { type IOutboxMessage } from './outbox-message';
import { createOutboxMessage } from './outbox-message-factory';
import { OutboxMessageTitles } from './outbox-message-titles';

interface ChangeTheme {
  theme: ThemeName;
}

interface ChangeThemeMessage extends IOutboxMessage {
  readonly title: OutboxMessageTitles.ChangeTheme;
  readonly data: ChangeTheme;
}

export function createChangeThemeMessage(theme: string): ChangeThemeMessage {
  return createOutboxMessage<ChangeThemeMessage>(OutboxMessageTitles.ChangeTheme, null, { theme });
}
