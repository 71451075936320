import { type FC } from 'react';

import { Info } from '@livechat/design-system-icons';
import { Icon, Switch, Tooltip } from '@livechat/design-system-react-components';
import { useDispatch, useSelector } from 'react-redux';

import { ThemeName } from 'constants/themes';
import { EventPlace } from 'helpers/analytics';
import { trackEvent } from 'services/event-tracking';
import { createChangeThemeMessage } from 'services/widget/message/outbox/change-theme-message';
import { AgentCustomPropertiesActions } from 'store/features/agent-custom-properties/actions';
import { AgentCustomPropertyName } from 'store/features/agent-custom-properties/interfaces';
import { isDarkModeEnabled } from 'store/features/agent-custom-properties/selectors';
import { ApplicationsActions } from 'store/features/applications/actions';
import { DarkModeEvents } from 'store/features/dark-mode/constants';

import { DARK_MODE_SWITCH_TEST_ID } from './constants';

import * as styles from './styles';

export const DarkModeSwitch: FC = () => {
  const isDarkMode = useSelector(isDarkModeEnabled);
  const dispatch = useDispatch();

  const handleDarkModeToggle = (): void => {
    const theme = !isDarkMode ? ThemeName.Dark : ThemeName.Light;
    trackEvent(DarkModeEvents.DarkModeToggle, EventPlace.DarkMode, { theme });
    dispatch(ApplicationsActions.sendBroadcastMessage(createChangeThemeMessage(theme)));
    // New theme will be applied to the ThemeProvider in the MainRouter.tsx useEffect on the state change
    dispatch(
      AgentCustomPropertiesActions.setAgentCustomProperty({
        [AgentCustomPropertyName.CurrentTheme]: theme,
      }),
    );
  };

  return (
    <Tooltip
      kind="invert"
      placement="right"
      offsetMainAxis={15}
      triggerRenderer={() => (
        <a type="button" onClick={handleDarkModeToggle} data-testid={DARK_MODE_SWITCH_TEST_ID}>
          Dark mode
          <Icon source={Info} className={styles.infoIcon} size="small" kind="primary" />
          <Switch size="compact" className="addition" on={isDarkMode} />
        </a>
      )}
    >
      <div>Available on Home, Chats and Traffic.</div>
    </Tooltip>
  );
};
