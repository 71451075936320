import { css } from '@emotion/css';

export const contentWrapper = css`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const content = css`
  display: flex;
  justify-content: center;
  align-items: center;
`;
