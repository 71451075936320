import { css } from '@emotion/css';

export const contentWrapper = css`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const content = css`
  display: flex;
  justify-content: center;
  text-align: center;
  max-width: 540px;
  white-space: pre-wrap;
`;
