import { type ReactEventHandler, type FC } from 'react';

import { Error as ErrorIcon } from '@livechat/design-system-icons';
import { Button, Link, Text } from '@livechat/design-system-react-components';
import * as Sentry from '@sentry/browser';
import { useLocation } from 'react-router-dom';

import { EventPlace } from 'helpers/analytics';
import { redirectTo } from 'helpers/redirect-to';
import { getHomePage } from 'helpers/routing';
import { parseQueryParams } from 'helpers/url';
import { useConnectivityStatus } from 'hooks/connectivity/status/use-connectivity-status';
import { useEffectOnce } from 'hooks/use-effect-once';
import { openChatWidget } from 'services/chat-widget';
import { trackEvent } from 'services/event-tracking';

import { FullscreenError } from '../components/FullscreenError/FullscreenError';

import {
  DEFAULT_ERROR_MESSAGE,
  OPEN_CHAT_WIDGET_BUTTON_TEXT,
  OPEN_CHAT_WIDGET_EVENT_NAME,
  TEXT_OFFLINE,
  TITLE_NETWORK_ISSUES,
  TITLE_OFFLINE,
  TRY_AGAIN_BUTTON_TEXT,
} from './constants';

import * as styles from './styles';

/**
 * Don't lazy-load this component. We want it to be available even when user has network issues.
 */
const NetworkIssues: FC = () => {
  const { search } = useLocation();
  const queryParams = parseQueryParams(search);
  const errorMessage = typeof queryParams.error === 'string' ? queryParams.error : DEFAULT_ERROR_MESSAGE;
  const { isOnline, isOffline, network, internet, services } = useConnectivityStatus();

  const handleOpenWidget: ReactEventHandler = (event) => {
    event.preventDefault();
    openChatWidget();
    trackEvent(OPEN_CHAT_WIDGET_EVENT_NAME, EventPlace.NetworkIssues);
  };

  const handleTryAgain = (): void => {
    redirectTo(getHomePage());
  };

  useEffectOnce(() => {
    Sentry.captureException(new Error(`${EventPlace.NetworkIssues}, error message = ${errorMessage}`), {
      extra: {
        errorMessage,
        connectivityStatus: {
          isOnline,
          isOffline,
          network,
          internet,
          services,
        },
      },
    });
  });

  return (
    <FullscreenError
      title={isOnline ? TITLE_NETWORK_ISSUES : TITLE_OFFLINE}
      iconSource={ErrorIcon}
      actionButtons={
        <>
          {isOnline && (
            <Button kind="secondary" onClick={handleOpenWidget}>
              {OPEN_CHAT_WIDGET_BUTTON_TEXT}
            </Button>
          )}
          <Button kind="primary" onClick={handleTryAgain}>
            {TRY_AGAIN_BUTTON_TEXT}
          </Button>
        </>
      }
    >
      <div className={styles.contentWrapper}>
        <Text className={styles.content}>
          {isOnline ? (
            <>
              Check your network settings,{' '}
              <Link href="https://status.livechat.com" target="_blank" rel="noopener noreferrer">
                LiveChat status
              </Link>
              , and reload the page.
            </>
          ) : (
            TEXT_OFFLINE
          )}
        </Text>
      </div>
    </FullscreenError>
  );
};

export default NetworkIssues;
