import mitt from 'mitt';

import { type IConfig } from '@config/interfaces';
import { getConfig } from 'helpers/config';
import { type DesktopAppApi } from 'interfaces/desktop-app/api';
import { type LoginStatusElectron } from 'interfaces/desktop-app/login-status';
import { type INotification } from 'store/features/browser-notifications/interfaces';

let desktopAppApi: DesktopAppApi | null = null;

const DESKTOP_APP_READY = 'desktop-app-ready';

type Events = {
  [DESKTOP_APP_READY]: DesktopAppApi;
};

const emitter = mitt<Events>();

async function waitForDesktopApp(): Promise<DesktopAppApi> {
  if (desktopAppApi) {
    return desktopAppApi;
  }

  return new Promise((resolve) => {
    emitter.on(DESKTOP_APP_READY, (api: DesktopAppApi) => {
      resolve(api);
    });
  });
}

function executeWhenReady(callback: (desktopAppApi: DesktopAppApi) => void): void {
  void waitForDesktopApp().then((api) => callback(api));
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export function prepareDesktopAppEncapsulation(): { config: IConfig; SmartClient: DesktopAppApi } {
  const config = getConfig();

  const smartClient: DesktopAppApi = {
    receive: {
      onStatusChange(callback) {
        void executeWhenReady((desktopAppApi: DesktopAppApi) => {
          desktopAppApi.receive.onStatusChange(callback);
        });
      },
      onAgentLogin(callback) {
        void executeWhenReady((desktopAppApi: DesktopAppApi) => {
          desktopAppApi.receive.onAgentLogin(callback);
        });
      },
      onLog(callback) {
        void executeWhenReady((desktopAppApi: DesktopAppApi) => {
          desktopAppApi.receive.onLog(callback);
        });
      },
      onNotificationShow(callback) {
        void executeWhenReady((desktopAppApi: DesktopAppApi) => {
          desktopAppApi.receive.onNotificationShow(callback);
        });
      },
      onNotificationClose(callback) {
        void executeWhenReady((desktopAppApi: DesktopAppApi) => {
          desktopAppApi.receive.onNotificationClose(callback);
        });
      },
      onAgentLogout(callback) {
        void executeWhenReady((desktopAppApi: DesktopAppApi) => {
          desktopAppApi.receive.onAgentLogout(callback);
        });
      },
    },
    send: {
      enableAdditionalLogs(enabled: boolean): void {
        void executeWhenReady((desktopAppApi: DesktopAppApi) => {
          desktopAppApi.send.enableAdditionalLogs(enabled);
        });
      },
      onCustomSoundsChange(): void {
        void executeWhenReady((desktopAppApi: DesktopAppApi) => {
          desktopAppApi.send.onCustomSoundsChange();
        });
      },
      onNotificationClick(afterClick: { targetPage: string }): void {
        void executeWhenReady((desktopAppApi: DesktopAppApi) => {
          desktopAppApi.send.onNotificationClick(afterClick);
        });
      },
      onStatusChange(status: LoginStatusElectron): void {
        void executeWhenReady((desktopAppApi: DesktopAppApi) => {
          desktopAppApi.send.onStatusChange(status);
        });
      },
      onIdle(isIdle: boolean): void {
        void executeWhenReady((desktopAppApi: DesktopAppApi) => {
          desktopAppApi.send.onIdle(isIdle);
        });
      },
      onWindowFocus(): void {
        void executeWhenReady((desktopAppApi: DesktopAppApi) => {
          desktopAppApi.send.onWindowFocus();
        });
      },
      onWindowBlur(): void {
        void executeWhenReady((desktopAppApi: DesktopAppApi) => {
          desktopAppApi.send.onWindowBlur();
        });
      },
    },
    handleBadgeContentChange(count: number): void {
      void executeWhenReady((desktopAppApi: DesktopAppApi) => {
        desktopAppApi.handleBadgeContentChange(count);
      });
    },
    displayNewNotification(notification: INotification & { body: string; icon: string }): void {
      void executeWhenReady((desktopAppApi: DesktopAppApi) => {
        desktopAppApi.displayNewNotification(notification);
      });
    },
    cancelNotifications(tags: string[]): void {
      void executeWhenReady((desktopAppApi: DesktopAppApi) => {
        desktopAppApi.cancelNotifications(tags);
      });
    },
    clearAllNotifications(): void {
      void executeWhenReady((desktopAppApi: DesktopAppApi) => {
        desktopAppApi.clearAllNotifications();
      });
    },
    getVersion(): string {
      return desktopAppApi?.getVersion() ?? '';
    },
    agentLoggedIn(agent: { login: string; appUrl: string }): void {
      void executeWhenReady((desktopAppApi: DesktopAppApi) => {
        desktopAppApi.agentLoggedIn(agent);
      });
    },
    saveAdvancedLog(message: string | string[]): void {
      void executeWhenReady((desktopAppApi: DesktopAppApi) => {
        desktopAppApi.saveAdvancedLog(message);
      });
    },
    enableAdditionalLogs(): boolean {
      return desktopAppApi?.enableAdditionalLogs() ?? false;
    },
    toString(): string {
      return desktopAppApi?.toString() ?? '';
    },
  };

  const app = {
    config,
    // eslint-disable-next-line @typescript-eslint/naming-convention
    SmartClient: smartClient,
  };

  return app;
}

export function desktopAppReady(desktopApp: DesktopAppApi): void {
  desktopAppApi = desktopApp;

  emitter.emit(DESKTOP_APP_READY, desktopApp);
}
