import { getGoogleAnalyticsAppId } from 'helpers/applications';
import { getConfig } from 'helpers/config';
import { featureBranchURL } from 'helpers/regexp';
import type { Product } from 'interfaces/product-cart';
import { type IApplication } from 'store/entities/applications/interfaces';

export const sortByCustomApplicationOrder = (applicationsOrder: string[]) => (a: IApplication, b: IApplication) => {
  const appAIndex = applicationsOrder.indexOf(a.id);
  const appBIndex = applicationsOrder.indexOf(b.id);

  return appAIndex - appBIndex;
};

export const getFullMarketplaceUrl = (): string => {
  const marketplaceUrl = new URL(getConfig().devMarketplace);

  // remove feature branch URL from pathname
  let marketplacePathname = window.location.pathname.replace(featureBranchURL, '');
  // remove /apps/marketplace so pathname would match marketplace routes
  marketplacePathname = marketplacePathname.replace('/apps/marketplace', '');
  // Marketplace should open on /apps page by default
  if (!window.location.pathname.includes('/apps/marketplace') || !marketplacePathname || marketplacePathname === '/') {
    marketplacePathname = '/apps';
  }

  // append pathname that would match marketplace routes
  marketplaceUrl.pathname = marketplacePathname;

  // append search params from the location.search
  marketplaceUrl.search = window.location.search;

  return marketplaceUrl.toString();
};

export const markGoogleAnalyticsForInstall = (product: Product): Product => {
  if (product.id === getGoogleAnalyticsAppId()) {
    return { ...product, isMarkedForInstall: true };
  }

  return product;
};
