// @ts-strict-ignore
import { type SagaIterator } from 'redux-saga';
import { all, call, put, select, takeEvery } from 'redux-saga/effects';

import { EventNames } from 'constants/event-bus-events';
import { PostMessageEvent } from 'constants/post-message-event';
import { QueryKey } from 'constants/query-key';
import { SubscriptionRouteEvent } from 'constants/subscription/event';
import { ToastAutoHideDelay, ToastContent } from 'constants/toasts';
import { EventPlace, trackGAEvent, trackGTMEvent, trackZapierEvent } from 'helpers/analytics';
import { sendPostMessageToMarketplace } from 'helpers/post-message';
import { isCovidCoupon } from 'helpers/recurly';
import { getToastContent } from 'helpers/toast';
import type { RequestResult } from 'interfaces/api/client';
import type { Product, ProductsCart } from 'interfaces/product-cart';
import { BillingCycleType, type INewSubscription, type ISubscription } from 'interfaces/subscription';
import { ApiManager } from 'services/api/api-manager';
import { type ISubscriptionDTO } from 'services/api/subscription/interfaces';
import { SubscriptionSerializer } from 'services/api/subscription/serializer';
import { PlatformNamespace } from 'services/connectivity/configuration-api/properties/constants';
import { EventBus } from 'services/event-bus';
import { trackEvent } from 'services/event-tracking';
import { getQueryClient } from 'services/query-client/client';
import { QUERY_KEYS } from 'services/query-client/keys';
import { getIsGoogleAnalyticsInstalled } from 'store/entities/applications/selectors';
import { LicenseCustomPropertiesActions } from 'store/entities/license-custom-properties/actions';
import { getRoktId } from 'store/entities/license-custom-properties/selectors';
import { getCurrentCart, getCurrentCartProductsToSubscribe } from 'store/entities/product-cart/selectors';
import { RecurlyActions } from 'store/entities/recurly/actions';
import { SubscriptionActionNames, SubscriptionActions } from 'store/entities/subscription/actions';
import { getEstimatedPlanPrice, getSubscriptionChange } from 'store/entities/subscription/selectors';
import { ApplicationsActions } from 'store/features/applications/actions';
import { getLicenseId } from 'store/features/session/selectors';
import { ToastsActions } from 'store/features/toasts/actions';
import { ToastVariant } from 'store/features/toasts/interfaces';
import { type IActionWithPayload } from 'store/helper';
import { SubscriptionViewActions } from 'store/views/subscription/actions';
import { getIsNewCouponApplicable } from 'store/views/subscription/selectors';

import { realizeCurrentCart, refreshApplicationsData } from '../product-cart/realize-cart';

import { GlobalEcomerceEvents, GTMEvents, SubscriptionEventFields } from './constants';
import {
  checkIsRecurlyError,
  deserializeError,
  getTrackerId,
  mapSubscriptionToNewSubscription,
  refreshCouponData,
  saveAutomaticUpsellingEnabled,
  triggerSalesTracker,
} from './helpers';
import { AppsInstallationStatus } from './update-subscription';

function* trackSubscriptionCreateEvents(
  subscription: ISubscription,
  appsInstallationStatus: AppsInstallationStatus,
  installedAppsNumber?: number,
): SagaIterator {
  const { amount, seats, billingCycle } = subscription;
  const isGoogleAnalyticsInstalled: boolean = yield select(getIsGoogleAnalyticsInstalled);
  const licenseId: string = yield select(getLicenseId);
  const transactionId = `${licenseId}.${new Date().getTime()}`;

  const price = amount * seats * billingCycle;

  trackEvent(SubscriptionRouteEvent.FirstPaymentFinished, EventPlace.Subscription, {
    [SubscriptionEventFields.GoogleAnalytics]: isGoogleAnalyticsInstalled,
    [SubscriptionEventFields.AppsInstallationStatus]: appsInstallationStatus,
    [SubscriptionEventFields.BillingPeriod]: billingCycle,
    ...(appsInstallationStatus === AppsInstallationStatus.Success
      ? { [SubscriptionEventFields.AppsInstalled]: installedAppsNumber }
      : {}),
  });
  trackGAEvent(GlobalEcomerceEvents.AddTransaction, {
    id: transactionId,
    revenue: price,
  });
  trackGAEvent(GlobalEcomerceEvents.AddItem, {
    id: transactionId,
    name: 'LiveChat - first payment',
    sku: subscription.planCode,
    price: amount,
    quantity: subscription.seats,
  });
  trackGAEvent(GlobalEcomerceEvents.Send);
  trackGTMEvent(GTMEvents.FirstPayment, {
    ecommerce: {
      purchase: {
        actionField: {
          id: transactionId,
          revenue: price,
          coupon: subscription.couponCode,
        },
        products: [
          {
            name: 'LiveChat - First payment',
            id: subscription.planCode,
            price: amount,
            quantity: subscription.seats,
          },
        ],
      },
    },
  });
  void trackZapierEvent(`/lzwx9g/?email=${encodeURIComponent(subscription.email)}`);
}

function* createSubscription({ payload }: IActionWithPayload<string, { threeDSecureTokenId: string }>): SagaIterator {
  const roktId: string | undefined = yield select(getRoktId);
  const newSubscription: INewSubscription = yield select(getSubscriptionChange);
  const isNewCouponApplicable = yield select(getIsNewCouponApplicable);
  const estimatedPlanPrice: number = yield select(
    getEstimatedPlanPrice,
    newSubscription.seats,
    newSubscription.planCode,
  );
  const serializedSubscription = SubscriptionSerializer.serialize(newSubscription, { isNewCouponApplicable });

  // 1. Create subscription
  try {
    const { error }: RequestResult<unknown> = yield call(ApiManager.subscriptionApi.create, {
      ...serializedSubscription,
      // eslint-disable-next-line @typescript-eslint/naming-convention
      rokt_id: roktId,
      // eslint-disable-next-line @typescript-eslint/naming-convention
      three_d_secure_action_result_token: payload.threeDSecureTokenId,
    });
    if (error) throw error;

    if (isCovidCoupon(serializedSubscription.coupon_code)) {
      yield put(LicenseCustomPropertiesActions.saveLicenseCustomProperty({ name: 'covid_coupon_used', value: '1' }));
    }

    yield call(saveAutomaticUpsellingEnabled);
    const queryClient = getQueryClient();
    yield call(
      queryClient.invalidateQueries.bind(queryClient),
      QUERY_KEYS[QueryKey.LicenseProperties](PlatformNamespace.BILLING),
    );

    yield put(
      ToastsActions.createToast({
        content: getToastContent(ToastContent.SUBSCRIPTION_CREATED),
        kind: ToastVariant.Success,
        autoHideDelayTime: ToastAutoHideDelay.Long,
      }),
    );

    const isMonthlyNew = serializedSubscription.months === BillingCycleType.Monthly;
    const isAnnualNew = serializedSubscription.months === BillingCycleType.Annually12;

    if (isMonthlyNew) {
      triggerSalesTracker(getTrackerId('newMonthly'), estimatedPlanPrice.toString());
    }
    if (isAnnualNew) {
      triggerSalesTracker(getTrackerId('newAnnually'), estimatedPlanPrice.toString());
    }

    EventBus.emit(EventNames.SubscriptionChanged);
  } catch (e) {
    const isErrorFromRecurly = checkIsRecurlyError(e);
    if (isErrorFromRecurly) {
      yield put(SubscriptionViewActions.setRecurlyError(e.error.recurly || e['#']));
    }
    const error = deserializeError(e);

    if (error.toLowerCase().includes('coupon')) {
      yield put(RecurlyActions.redemptionError({ error }));
    }

    if (!isErrorFromRecurly) {
      yield put(ToastsActions.createToast({ content: error, kind: ToastVariant.Error, autoHideDelayTime: null }));
    }
    trackEvent(SubscriptionRouteEvent.SubscriptionFailed, EventPlace.Subscription, { error });
    yield put(SubscriptionActions.createSubscriptionFailure({ error: JSON.stringify(e) }));

    return;
  }

  // 2. Load and deserialize new subscription
  const { result, error }: RequestResult<ISubscriptionDTO> = yield call(ApiManager.subscriptionApi.fetch);
  if (error) {
    yield put(SubscriptionViewActions.licenseDataRefreshFailure());

    return;
  }

  const deserializedSubscription = SubscriptionSerializer.deserialize(result);
  yield call(sendPostMessageToMarketplace, PostMessageEvent.RefreshInstalledApps);

  // 3. Install apps + refresh license / app data
  try {
    const cartProducts: Product[] = yield select(getCurrentCartProductsToSubscribe);
    const addonsToInstallNumber = cartProducts.length;
    const cartProductsIds = cartProducts.map(({ id }) => id);

    yield all([call(refreshCouponData, deserializedSubscription), call(realizeCurrentCart)]);
    yield call(refreshApplicationsData);
    // Mark purchased cart apps as selected
    yield put(ApplicationsActions.addCartApplicationsSelected(cartProductsIds));
    yield put(SubscriptionActions.createSubscriptionSuccess(deserializedSubscription));
    yield call(
      trackSubscriptionCreateEvents,
      deserializedSubscription,
      AppsInstallationStatus.Success,
      addonsToInstallNumber,
    );
    yield call(mapSubscriptionToNewSubscription, deserializedSubscription);
  } catch (error) {
    const err = deserializeError(error);
    const currentCart: ProductsCart | null = yield select(getCurrentCart);

    yield call(trackSubscriptionCreateEvents, deserializedSubscription, AppsInstallationStatus.Failure);
    trackEvent(SubscriptionRouteEvent.CreateSubscriptionSideEffectFailed, EventPlace.Subscription, {
      err,
      cartId: currentCart?.id,
    });
    yield put(
      ToastsActions.createToast({
        content: getToastContent(ToastContent.SOMETHING_WENT_WRONG),
        kind: ToastVariant.Error,
        autoHideDelayTime: ToastAutoHideDelay.Long,
      }),
    );
  }
}

export function* createSubscriptionSaga(): SagaIterator {
  yield takeEvery(SubscriptionActionNames.CREATE_SUBSCRIPTION, createSubscription);
}
