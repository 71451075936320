export const enum UserGuidedTourType {
  ChatCannedResponses = 'ChatCannedResponses',
  CustomSegments = 'CustomSegments',
  TicketPrivacy = 'TicketPrivacy',
  TicketStatus = 'TicketStatus',
  HomeScreenExplore = 'HomeScreenExplore',
  OpenChat = 'OpenChat',
  UnassignedChat = 'UnassignedChat',
  RichGreeting = 'RichGreeting',
  ReportViews = 'ReportViews',
  RoutingRules = 'RoutingRules',
}
