// @ts-strict-ignore
/* eslint-disable @typescript-eslint/no-unsafe-call */
import { addDays, isBefore, startOfDay } from 'date-fns';

import { SettingsRoutePage } from 'constants/settings/routing';
import { type ISideNavigationItem } from 'interfaces/side-navigation-item';
import { AppStateProvider } from 'services/app-state-provider';
import { getAccountCreateDate } from 'store/entities/accounts/selectors';
import { getLoggedInAgentAccountId } from 'store/entities/agents/selectors';
import { getCreationTimestamp } from 'store/features/session/selectors';

import { anyToBoolean, type BooleanNumericFlag, type TernaryNumericFlag } from './boolean';

/**
 * Different endpoints provide two different answers format for select
 * and radio fields.
 *
 * Expected formats are:
 *
 * {
 *  (...)
 *  answers: ['ans 1', 'ans 2'],
 *  checked: [true, false]
 * }
 *
 * or
 *
 * {
 *  (...)
 *  answers: {
 *    {label: 'ans 1', checked: true},
 *    {label: 'ans 2', checked: false}
 *  }
 *
 * }
 *
 * This function unifies the fromat to the second one.
 */
/* eslint-disable @typescript-eslint/no-unsafe-return */
function unifyAnswersFormat(field) {
  return field.answers.map((answer, i) => {
    if (answer.label) return answer;

    return {
      label: answer,
      checked: field.checked[i],
    };
  });
}

export function unifyAllFieldsAnswers(fields) {
  return fields.map((field) => {
    if (field.answers) {
      return {
        ...field,
        answers: unifyAnswersFormat(field),
      };
    }

    return field;
  });
}
/* eslint-enable @typescript-eslint/no-unsafe-return */

export function shouldShowNewBadge(sectionReleaseDate: string | Date, period: number): boolean {
  const visibilityEndDate = startOfDay(addDays(sectionReleaseDate, period));
  const licenseCreationDate = getCreationTimestamp(AppStateProvider.getState());
  const currentDay = startOfDay(new Date());

  return isBefore(currentDay, visibilityEndDate) && isBefore(licenseCreationDate, sectionReleaseDate);
}

export function shouldShowNewBadgeBasedOnAgentCreateDate(sectionReleaseDate: string | Date, period: number): boolean {
  const visibilityEndDate = startOfDay(addDays(sectionReleaseDate, period));
  const currentAgentId = AppStateProvider.selectFromStore(getLoggedInAgentAccountId);

  if (!currentAgentId) {
    return false;
  }

  const agentCreationDate = AppStateProvider.selectFromStore(getAccountCreateDate, currentAgentId);
  const currentDay = startOfDay(new Date());

  return isBefore(currentDay, visibilityEndDate) && isBefore(agentCreationDate, sectionReleaseDate);
}

export function hasItemNewBadge(item: ISideNavigationItem): boolean {
  return item.checkNewBadgeVisibility ? item.checkNewBadgeVisibility() : Boolean(item.newBadge);
}

export function hasItemBadge(item: ISideNavigationItem): boolean {
  return item.checkBadgeVisibility ? item.checkBadgeVisibility() : Boolean(item.badge);
}

export function isChildOf(
  items: ISideNavigationItem[],
  currentChildrenItem: ISideNavigationItem,
  parentId: string,
): boolean {
  const parent = items.find((navItem) => navItem.id === parentId);

  return parent ? !!parent.children?.find((child) => child.id === currentChildrenItem?.id) : false;
}

export function getIsManualRouting(
  isManualSelectionEnabledForLicense: BooleanNumericFlag,
  isManualSelectionEnabledForGroup: TernaryNumericFlag,
): boolean {
  return isManualSelectionEnabledForGroup === '-1'
    ? anyToBoolean(isManualSelectionEnabledForLicense)
    : anyToBoolean(isManualSelectionEnabledForGroup);
}

export function getSettingsPageTitle(page: SettingsRoutePage): string {
  switch (page) {
    case SettingsRoutePage.BannedCustomers:
      return 'Banned customers';
    case SettingsRoutePage.Overview:
      return 'Overview';
    case SettingsRoutePage.Code:
      return 'Website widget Livechat Code';
    case SettingsRoutePage.ChatPage:
      return 'Chat page';
    case SettingsRoutePage.ChatPageSetup:
      return 'Customization';
    case SettingsRoutePage.Email:
      return 'Email';
    case SettingsRoutePage.HelpDesk:
      return 'Email by HelpDesk';
    case SettingsRoutePage.FacebookMessenger:
      return 'Facebook Messenger';
    case SettingsRoutePage.AppleBusinessChat:
      return 'Apple Messages';
    case SettingsRoutePage.ThemeBase:
    case SettingsRoutePage.Theme:
      return 'Theme | LiveChat Settings';
    case SettingsRoutePage.Language:
      return 'Language';
    case SettingsRoutePage.Availability:
      return 'Availability';
    case SettingsRoutePage.WelcomeScreenBase:
    case SettingsRoutePage.WelcomeScreen:
      return 'Welcome screen';
    case SettingsRoutePage.PreChatForm:
      return 'Pre-chat form';
    case SettingsRoutePage.AskForEmail:
      return 'Ask for email';
    case SettingsRoutePage.PostChatForm:
      return 'Post-chat form';
    case SettingsRoutePage.Queued:
      return 'Queued';
    case SettingsRoutePage.TicketForm:
      return 'Ticket form';
    case SettingsRoutePage.EyeCatcher:
      return 'Eye-catcher | LiveChat Settings';
    case SettingsRoutePage.ChatButtons:
      return 'Chat buttons | LiveChat Settings';
    case SettingsRoutePage.ChatButtonsAddText:
    case SettingsRoutePage.ChatButtonsAddImage:
      return 'Add a chat button';
    case SettingsRoutePage.ChatButtonsInstall:
      return 'Install a chat button';
    case SettingsRoutePage.ChatButtonsEdit:
      return 'Edit a chat button';
    case SettingsRoutePage.Goals:
    case SettingsRoutePage.GoalsAdd:
    case SettingsRoutePage.GoalsEdit:
      return 'Goals';
    case SettingsRoutePage.SalesTracker:
    case SettingsRoutePage.SalesTrackerAdd:
    case SettingsRoutePage.SalesTrackerDetails:
    case SettingsRoutePage.SalesTrackerInstall:
      return 'Sales tracker';
    case SettingsRoutePage.MessagingMode:
      return 'Messaging mode';
    case SettingsRoutePage.ChatHistory:
      return 'Chat history';
    case SettingsRoutePage.CannedResponses:
      return 'Canned responses | LiveChat Settings';
    case SettingsRoutePage.SuggestedResponses:
      return 'Suggested responses | LiveChat Settings';
    case SettingsRoutePage.Tags:
      return 'Tags';
    case SettingsRoutePage.ChatsAssignment:
      return 'Chat assignment | LiveChat Settings';
    case SettingsRoutePage.GroupsRouting:
    case SettingsRoutePage.GroupsRoutingAdd:
    case SettingsRoutePage.GroupsRoutingEdit:
      return 'Routing rules';
    case SettingsRoutePage.ArchivesForwarding:
      return 'Transcripts forwarding';
    case SettingsRoutePage.FileSharing:
      return 'File sharing';
    case SettingsRoutePage.InactivityTimeouts:
      return 'Inactivity timeouts';
    case SettingsRoutePage.AutomatedEmails:
      return 'Automated emails';
    case SettingsRoutePage.QualityBadge:
      return 'Quality showcase';
    case SettingsRoutePage.QualityBadgeCustomization:
      return 'Quality showcase - add badge';
    case SettingsRoutePage.CreditCardsMasking:
      return 'Credit card masking';
    case SettingsRoutePage.TrustedDomains:
      return 'Trusted domains';
    case SettingsRoutePage.AccessRestriction:
      return 'Access restriction';
    case SettingsRoutePage.CannedResponsesNew:
      return 'New canned responses';
    case SettingsRoutePage.CannedResponsesEdit:
      return 'Edit canned responses';

    case SettingsRoutePage.ReplySuggestionsNew:
      return 'New reply suggestions';
    case SettingsRoutePage.LoginSettings:
      return 'Login settings';
    default:
      return null;
  }
}
