import { css, keyframes } from '@emotion/css';
import { DesignToken, RadiusToken, SpacingToken } from '@livechat/design-system-react-components';
import { ellipsis } from 'polished';

import { SPOTLIGHT_MEDIA_QUERY } from 'constants/spotlight';
import { mobileMediaQuery } from 'helpers/media-queries';
import { resetLegacyLcBorderBox } from 'styles/reset-border-box';

const rotateAndPulse = keyframes`
  0% {
    transform: rotate(0deg);
    opacity: 1;
  }
  20% {
    transform: rotate(72deg);
    opacity: 0.8;
  }
  40% {
    transform: rotate(144deg);
    opacity: 0.6;
  }
  60% {
    transform: rotate(216deg);
    opacity: 0.6;
  }
  80% {
    transform: rotate(288deg);
    opacity: 0.8;
  }
  100% {
    transform: rotate(360deg);
    opacity: 1;
  }
`;

export const trialExpiringBar = css`
  width: auto;
  padding: 0;
  justify-content: center;
  background-color: var(${DesignToken.SurfaceAccentEmphasisHighInfo});
  border-radius: var(${RadiusToken.Radius2});

  span {
    color: var(${DesignToken.ContentLockedWhite});
  }

  @media (max-width: ${SPOTLIGHT_MEDIA_QUERY}px) {
    border-radius: 0;
  }
`;

export const notificationBarContainer = css`
  padding-bottom: 6px;
`;

export const hiddenNotificationBarContainer = css`
  padding-bottom: 0;
`;

export const contentContainer = css`
  position: relative;
  top: 0;
  width: 100%;
  font-size: 14px;
  font-weight: 600;
  text-align: center;
  box-sizing: border-box;
  display: flex;
  flex: 0 1 auto;
  align-items: center;
  justify-content: space-evenly;
  color: var(${DesignToken.Background});
  /**
   * I am disabling CSS transitions for the notifications until we fully migrate from Backbone to React.
   */
  /*
    height: 0;
    padding: 0;
  */

  padding: 8px 0;
  line-height: 0;
  overflow: hidden;
  ${mobileMediaQuery} {
    font-size: 12px;
  }
  & a {
    color: inherit;
    cursor: pointer;
    text-decoration: underline;
  }
`;

export const numberCard = (warning: boolean): string => css`
  width: 20px;
  height: 25px;
  line-height: 25px;
  border-radius: var(${RadiusToken.Radius1});
  margin-right: 4px;
  color: ${warning ? `var(${DesignToken.ContentBasicPrimary})` : `var(${DesignToken.ContentInvertPrimary})`};
  background-color: ${warning
    ? `var(${DesignToken.SurfaceAccentEmphasisHighNegative})`
    : `var(${DesignToken.ActionHighContrastDefault})`};
  ${mobileMediaQuery} {
    width: 16px;
    height: 22px;
    line-height: 22px;
    font-size: 14px;
  }
`;

export const iconButton = css`
  display: flex;
  cursor: pointer;
  background-color: transparent;
  border: none;
  outline: none;
  padding: 0;
  justify-content: center;
  align-items: center;
`;

export const textStyles = css`
  ${ellipsis()};
`;

export const codeNotInstalledBarText = css`
  min-width: 350px;
`;

export const spotlightContainer = css`
  width: 100%;
`;

export const topBarWrapper = css`
  ${resetLegacyLcBorderBox};
  height: 100%;
  background-color: var(${DesignToken.NavbarBackground});
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
`;

export const triggerBackground = (isDarkMode: boolean): string => css`
  background-color: var(${DesignToken.DecorGray700});
  opacity: ${isDarkMode ? 0.5 : 0.7};
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
`;

export const spotlightWrapper = css`
  width: 100%;
  display: flex;
  justify-content: center;

  @media (max-width: 1030px) {
    justify-content: flex-start;
  }
`;

export const spotlightTrigger = (isDarkMode: boolean): string => css`
  ${resetLegacyLcBorderBox};
  width: 100%;
  max-width: 400px;
  position: relative;
  overflow: hidden;
  border-radius: 8px;
  color: var(${DesignToken.ContentLockedWhite});
  display: flex;
  align-items: center;
  justify-content: space-between;
  transition: width 0.5s ease;
  padding: 5px var(${SpacingToken.Spacing1}) 5px var(${SpacingToken.Spacing3});

  &:hover {
    cursor: pointer;
  }

  &:hover .spotlightTrigger {
    background-color: var(${DesignToken.DecorGray700});
    opacity: ${isDarkMode ? 0.75 : 0.85};
  }

  @media (max-width: 750px) {
    // preventing spotlight collapse with InviteAgents component
    max-width: 300px;
  }
`;

export const searchWrapper = css`
  display: flex;
  align-items: center;
  gap: var(${SpacingToken.Spacing2});
  flex-shrink: 0;
  opacity: 1;
  z-index: 0;
`;

export const shortcutWrapper = css`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: var(${SpacingToken.Spacing05});
  opacity: 1;
`;

export const blackTag = css`
  background-color: var(${DesignToken.NavbarBackground});
  color: var(${DesignToken.ContentLockedWhite});
`;

export const copilotLogoContainer = css`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  right: 3px;
  width: 34px;
  height: 34px;
  top: 0;
`;

export const imageContainer = css`
  position: relative;
  display: contents;
`;

export const copilotUnderlayer = css`
  position: absolute;
  width: var(${SpacingToken.Spacing12});
  height: var(${SpacingToken.Spacing12});
`;

export const copilotAssistant = css`
  position: relative;
  width: var(${SpacingToken.Spacing7});
  height: var(${SpacingToken.Spacing7});
  border-radius: 50%;

  &:hover {
    cursor: pointer;
  }
`;

export const borderAnimation = css`
  animation: ${rotateAndPulse} 1s cubic-bezier(0.015, 0.19, 0.09, 1.01);
`;

export const copilotBadge = css`
  position: absolute;
  top: 0px;
  right: 0px;
  border: var(${SpacingToken.Spacing05}) solid var(${DesignToken.NavbarBackground});

  span {
    max-width: var(${SpacingToken.Spacing1});
    max-height: var(${SpacingToken.Spacing1});
  }
`;

export const copilotPopoverShortcuts = css`
  ${shortcutWrapper};
  margin-top: var(${SpacingToken.Spacing1});
`;

export const shortcut = css`
  border: none;
  margin-right: var(${SpacingToken.Spacing1});
`;
