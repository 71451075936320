import type { EventPlace } from 'helpers/analytics';
import type { IInboxMessage } from 'services/widget/message/inbox/inbox-message';
import type { IOutboxBroadcastMessage, IOutboxMessage } from 'services/widget/message/outbox/outbox-message';
import type { IApplication } from 'store/entities/applications/interfaces';
import { type ActionsUnion, createAction } from 'store/helper';

import type {
  IHandlePutMessageParams,
  ICampaign,
  UninstallApplicationSuccessfulPayload,
  UninstallApplicationPayload,
  UninstallApplicationFailurePayload,
  SetApplicationsSelectedPayload,
  SaveApplicationsSelectedPayload,
  SetApplicationsOrderPayload,
  SaveApplicationsOrderPayload,
  RemoveApplicationsFromCustomOrderPayload,
} from './interfaces';

export enum ApplicationsActionNames {
  LOAD_WIDGET = 'FEATURES/APPLICATIONS/LOAD_WIDGET',
  LOAD_FULLSCREEN_WIDGET = 'FEATURES/APPLICATIONS/LOAD_FULLSCREEN_WIDGET',
  CONNECTION_ESTABLISHED = 'FEATURES/APPLICATIONS/CONNECTION_ESTABLISHED',
  WIDGETS_DISABLE = 'FEATURES/APPLICATIONS/WIDGETS_DISABLE',
  INSTALL_APPLICATION = 'FEATURES/APPLICATIONS/INSTALL_APPLICATION_REQUEST',
  INSTALL_APPLICATION_SUCCESSFUL = 'FEATURES/APPLICATIONS/INSTALL_APPLICATION_SUCCESS',
  INSTALL_APPLICATION_FAILURE = 'FEATURES/APPLICATIONS/INSTALL_APPLICATION_FAILURE',
  UNINSTALL_APPLICATION = 'FEATURES/APPLICATIONS/UNINSTALL_APPLICATION',
  UNINSTALL_APPLICATION_SUCCESSFUL = 'FEATURES/APPLICATIONS/UNINSTALL_APPLICATION_SUCCESSFUL',
  UNINSTALL_APPLICATION_FAILURE = 'FEATURES/APPLICATIONS/UNINSTALL_APPLICATION_FAILURE',
  UPDATE_APPLICATION = 'FEATURES/APPLICATIONS/UPDATE_APPLICATION',
  SET_APPLICATIONS_SELECTED = 'FEATURES/APPLICATIONS/SET_APPLICATIONS_SELECTED',
  ADD_APPLICATION_SELECTED = 'FEATURES/APPLICATIONS/ADD_APPLICATION_SELECTED',
  ADD_CART_APPLICATIONS_SELECTED = 'FEATURES/APPLICATIONS/ADD_CART_APPLICATIONS_SELECTED',
  SAVE_APPLICATIONS_SELECTED = 'FEATURES/APPLICATIONS/SAVE_APPLICATIONS_SELECTED',
  SET_APPLICATIONS_ORDER = 'FEATURES/APPLICATIONS/SET_APPLICATIONS_ORDER',
  SAVE_APPLICATIONS_ORDER = 'FEATURES/APPLICATIONS/SAVE_APPLICATIONS_ORDER',
  REMOVE_APPLICATIONS_FROM_CUSTOM_ORDER = 'FEATURES/APPLICATIONS/REMOVE_APPLICATIONS_FROM_CUSTOM_ORDER',
  // Agent App Widgets event handlers
  HANDLE_PUT_MESSAGE = 'FEATURES/APPLICATIONS/HANDLE/PUT_MESSAGE',
  WIDGET_INBOX_MESSAGE = 'FEATURES/APPLICATIONS/HANDLE/WIDGET_INBOX_MESSAGE',
  WIDGET_OUTBOX_MESSAGE = 'FEATURES/APPLICATIONS/HANDLE/WIDGET_OUTBOX_MESSAGE',
  WIDGET_OUTBOX_BROADCAST_MESSAGE = 'FEATURES/APPLICATIONS/HANDLE/WIDGET_OUTBOX_BROADCAST_MESSAGE',

  // Marketplace campaigns
  FETCH_CAMPAIGNS = 'FEATURES/APPLICATIONS/CAMPAIGNS/FETCH',
  FETCH_CAMPAIGNS_SUCCESSFUL = 'FEATURES/APPLICATIONS/CAMPAIGNS/FETCH_SUCCESSFUL',
  FETCH_CAMPAIGNS_FAILURE = 'FEATURES/APPLICATIONS/CAMPAIGNS/FETCH_FAILURE',
}

export enum ApplicationsRequestFetchingSelectorsNames {
  INSTALL_APPLICATION = 'FEATURES/APPLICATIONS/INSTALL_APPLICATION',
}

export const ApplicationsActions = {
  loadWidget: (id: string) => createAction(ApplicationsActionNames.LOAD_WIDGET, { id }),
  loadFullscreenWidget: (id: string) => createAction(ApplicationsActionNames.LOAD_FULLSCREEN_WIDGET, { id }),
  connectionEstablished: (pluginId: string, connectionId: string) =>
    createAction(ApplicationsActionNames.CONNECTION_ESTABLISHED, { pluginId, connectionId }),
  disableWidgets: () => createAction(ApplicationsActionNames.WIDGETS_DISABLE, {}),
  handlePutMessage: (payload: IHandlePutMessageParams) =>
    createAction(ApplicationsActionNames.HANDLE_PUT_MESSAGE, payload),
  installApplication: (id: string, name?: string, eventPlace?: EventPlace) =>
    createAction(ApplicationsActionNames.INSTALL_APPLICATION, { id, name, eventPlace }),
  installApplicationSuccessful: (applicationApiPayload: Pick<IApplication, 'id' | 'widgets'>) =>
    createAction(ApplicationsActionNames.INSTALL_APPLICATION_SUCCESSFUL, applicationApiPayload),
  installApplicationFailure: (applicationApiPayload: Pick<IApplication, 'id' | 'name'> & { eventPlace?: EventPlace }) =>
    createAction(ApplicationsActionNames.INSTALL_APPLICATION_FAILURE, applicationApiPayload),
  uninstallApplication: (payload: UninstallApplicationPayload) =>
    createAction(ApplicationsActionNames.UNINSTALL_APPLICATION, payload),
  uninstallApplicationSuccessful: (payload: UninstallApplicationSuccessfulPayload) =>
    createAction(ApplicationsActionNames.UNINSTALL_APPLICATION_SUCCESSFUL, payload),
  uninstallApplicationFailure: (payload: UninstallApplicationFailurePayload) =>
    createAction(ApplicationsActionNames.UNINSTALL_APPLICATION_FAILURE, payload),
  fetchCampaigns: (ids: string[]) => createAction(ApplicationsActionNames.FETCH_CAMPAIGNS, { ids }),
  fetchCampaignsSuccessful: (campaigns: ICampaign[]) =>
    createAction(ApplicationsActionNames.FETCH_CAMPAIGNS_SUCCESSFUL, { campaigns }),
  fetchCampaignsFailure: (ids: string[]) => createAction(ApplicationsActionNames.FETCH_CAMPAIGNS_FAILURE, { ids }),
  updateApplication: (payload: IInboxMessage) => createAction(ApplicationsActionNames.UPDATE_APPLICATION, payload),
  receiveInboxMessage: (message: IInboxMessage, connectionId: string) =>
    createAction(ApplicationsActionNames.WIDGET_INBOX_MESSAGE, { message, connectionId }),
  sendOutboxMessage: (message: IOutboxMessage) =>
    createAction(ApplicationsActionNames.WIDGET_OUTBOX_MESSAGE, { message }),
  sendBroadcastMessage: (message: IOutboxBroadcastMessage) =>
    createAction(ApplicationsActionNames.WIDGET_OUTBOX_BROADCAST_MESSAGE, { message }),
  setApplicationsSelected: (payload: SetApplicationsSelectedPayload) =>
    createAction(ApplicationsActionNames.SET_APPLICATIONS_SELECTED, payload),
  addApplicationSelected: (payload: string) => createAction(ApplicationsActionNames.ADD_APPLICATION_SELECTED, payload),
  addCartApplicationsSelected: (payload: string[]) =>
    createAction(ApplicationsActionNames.ADD_CART_APPLICATIONS_SELECTED, payload),
  saveApplicationsSelected: (payload: SaveApplicationsSelectedPayload) =>
    createAction(ApplicationsActionNames.SAVE_APPLICATIONS_SELECTED, payload),
  setApplicationsOrder: (payload: SetApplicationsOrderPayload) =>
    createAction(ApplicationsActionNames.SET_APPLICATIONS_ORDER, payload),
  saveApplicationsOrder: (payload: SaveApplicationsOrderPayload) =>
    createAction(ApplicationsActionNames.SAVE_APPLICATIONS_ORDER, payload),
  removeApplicationsFromCustomOrder: (payload: RemoveApplicationsFromCustomOrderPayload) =>
    createAction(ApplicationsActionNames.REMOVE_APPLICATIONS_FROM_CUSTOM_ORDER, payload),
};

export type ApplicationsAction = ActionsUnion<typeof ApplicationsActions>;
