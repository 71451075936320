export interface IFeaturesResponse {
  feature_flags: ResponseFeature[];
}

export interface ResponseFeature {
  name: string;
  value: boolean;
  variant?: { name: string; payload: { type: string; value: string } };
}

export enum FeatureNames {
  TestFeature = 'testFeature',
  ChatsListPagination = 'chatsListPagination',
  RichTextEditor = 'richTextEditor',
  DisturbingContentDetection = 'disturbingContentDetection',
  CustomerIntentEnabled = 'customerIntentEnabled',
  ReviewsReportEnabled = 'reviewsReportEnabled',
  InsightsReportsEnabled = 'recentInsightsEnabled',
  ShowHelloTextProduct = 'showHelloTextProduct',
  AISuggestions = 'aiSuggestions',
  ProductSwitcher = 'productSwitcher',
  PriorityChat = 'priorityChat',
  KnowledgeHubAddWebsiteOnboarding = 'knowledgeHubAddWebsiteOnboarding',
  CopilotStreaming = 'copilotStreaming',
  AICannedInChips = 'aiCannedInChips',
  ReplySuggestionsFullMessageContext = 'replySuggestionsFullMessageContext',
  KnowledgeHubPdfCustomLimit = 'knowledgeHubPdfCustomLimit',
}

type CustomFeatures = {
  [FeatureNames.KnowledgeHubPdfCustomLimit]: {
    enabled: boolean;
    limit: number;
  };
};

export type Features = {
  [Key in FeatureNames]: Key extends keyof CustomFeatures ? CustomFeatures[Key] : boolean;
};
