import { useCallback } from 'react';

import { useLocation, useNavigate } from 'react-router-dom';

import { SideNavigationItemLabelWithBadge } from 'components/side-navigation-v2/components/SideNavigationItemLabelWithBadge';
import { SideNavigationItemWithRightNode } from 'components/side-navigation-v2/components/SideNavigationItemWithRightNode';
import { SideNavigationItem } from 'components/side-navigation-v2/SideNavigationItem';
import { openInNewCard } from 'helpers/routing';
import { stringifyQueryParams } from 'helpers/url';
import { type ISideNavigationItem } from 'interfaces/side-navigation-item';
import { browserHistory } from 'services/browser-history';

interface Props {
  item: ISideNavigationItem;
  isMainEntry?: boolean;
  isActive: boolean;
}

export const ReportsSideNavigationItem: React.FC<Props> = ({ item, isMainEntry, isActive }) => {
  const { path, external, onItemHover, tour } = item;
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const handleNavigationClick = useCallback((): void => {
    if (external && path) {
      openInNewCard(path);

      return;
    }

    if (path && pathname !== path) {
      navigate({ pathname: path, search: stringifyQueryParams(browserHistory.queryParams) });
    }
  }, [external, path, pathname, navigate]);

  return (
    <SideNavigationItem
      label={<SideNavigationItemLabelWithBadge isActive={isActive} isMainEntry={isMainEntry} item={item} />}
      url={path}
      isMainEntry={isMainEntry}
      onClick={handleNavigationClick}
      isActive={isActive}
      rightNode={<SideNavigationItemWithRightNode item={item} />}
      onItemHover={onItemHover}
      tour={tour}
    />
  );
};
