import { useCallback, useEffect, type FC } from 'react';

import { ChevronRight, ChevronUp } from '@livechat/design-system-icons';
import { Icon } from '@livechat/design-system-react-components';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { SideNavigationItem } from 'components/side-navigation-v2/SideNavigationItem';
import { SideNavigationList } from 'components/side-navigation-v2/SideNavigationList';
import { useSessionStorage } from 'hooks/use-session-storage';
import { useAllVisibleApps } from 'routes/apps/hooks/use-all-visible-apps';
import { useSelectedVisibleApps } from 'routes/apps/hooks/use-selected-visible-apps';
import {
  getFilteredInstalledAndPrivateSortedApplications,
  getUnselectedApplications,
  hasInitializedAllApplicationLists,
} from 'store/entities/applications/selectors';
import { hasFetchedAgentCustomProperties } from 'store/features/agent-custom-properties/selectors';

import { normalizeListPathname } from '../helpers';

import { AppsConfigurationList } from './AppsConfigurationList/AppsConfigurationList';
import { INSTALLED_APPS_STORAGE_KEY, SHOW_LESS_APPS_TEST_ID, SHOW_MORE_APPS_TEST_ID } from './constants';
import { YourAppsItem } from './YourAppsItem/YourAppsItem';

import { labelStyles, listStyles } from './styles';

export const YourAppsList: FC = () => {
  const { pathname } = useLocation();
  const listPathname = normalizeListPathname(pathname);
  const [areAllAppsVisible, setAreAllAppsVisible] = useSessionStorage<boolean>(INSTALLED_APPS_STORAGE_KEY, false);

  const installedApplications = useSelector(getFilteredInstalledAndPrivateSortedApplications);
  const arePropertiesFetched = useSelector(hasFetchedAgentCustomProperties);
  const areAppsReady = useSelector(hasInitializedAllApplicationLists);

  const showMoreApplications = useCallback(() => setAreAllAppsVisible(true), [setAreAllAppsVisible]);
  const showLessApplications = useCallback(() => setAreAllAppsVisible(false), [setAreAllAppsVisible]);

  const unselectedApplications = useSelector(getUnselectedApplications);
  const [selectedVisibleApps] = useSelectedVisibleApps();
  const allApps = useAllVisibleApps();

  const displayedApps = areAllAppsVisible ? allApps : selectedVisibleApps;
  const hasUnselectedApps = unselectedApplications.length > 0;
  const shouldDisplayShowLess = areAllAppsVisible && hasUnselectedApps;
  const shouldDisplayShowMore = !areAllAppsVisible && hasUnselectedApps;

  const shouldResetVisibility =
    areAppsReady &&
    arePropertiesFetched &&
    !hasUnselectedApps &&
    unselectedApplications.length !== installedApplications.length;

  useEffect(() => {
    // If all apps are selected and `Show x` gets hidden, reset the visibility state to default
    if (shouldResetVisibility) {
      setAreAllAppsVisible(false);
    }
  }, [shouldResetVisibility, setAreAllAppsVisible]);

  if (!arePropertiesFetched || !areAppsReady || !allApps.length) {
    return null;
  }

  return (
    <SideNavigationList
      label="Your apps"
      rightNode={<AppsConfigurationList />}
      className={listStyles}
      labelWrapperClassName={labelStyles}
    >
      {displayedApps.map((app) => (
        <YourAppsItem key={app.id} app={app} listPathname={listPathname} />
      ))}

      {shouldDisplayShowLess && (
        <div data-testid={SHOW_LESS_APPS_TEST_ID}>
          <SideNavigationItem
            icon={<Icon source={ChevronUp} size="small" />}
            label="Show less"
            onClick={showLessApplications}
          />
        </div>
      )}

      {shouldDisplayShowMore && (
        <div data-testid={SHOW_MORE_APPS_TEST_ID}>
          <SideNavigationItem
            icon={<Icon source={ChevronRight} size="small" />}
            label="Show more"
            onClick={showMoreApplications}
          />
        </div>
      )}
    </SideNavigationList>
  );
};
