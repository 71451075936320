import { type ITourStep } from '../interfaces';

import { MySavedViewsPageStep, SavedViewsToolbarStep } from './tour-steps';

export enum ReportViewsStep {
  SavedViewsToolbar = 'SavedViewsToolbar',
  MySavedViewsPage = 'MySavedViewsPage',
}

export const reportViewsSteps: ITourStep[] = [
  {
    id: ReportViewsStep.SavedViewsToolbar,
    content: SavedViewsToolbarStep,
    placement: 'bottom-end',
  },
  {
    id: ReportViewsStep.MySavedViewsPage,
    content: MySavedViewsPageStep,
    placement: 'right',
  },
];
