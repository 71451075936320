/**
 * A list of noisy errors that won't be fixed.
 */
export const ignoredErrors = [
  /Unexpected context found on stack/,
  /undefined is not an object \(evaluating 'a\[0\]'\)/,
  /The request is not allowed by the user agent or the platform in the current context/,
  /The play\(\) request was interrupted by a call to pause\(\)/,
  /**
   * Safe to ignore.
   * @see https://stackoverflow.com/a/50387233
   */
  /ResizeObserver loop limit exceeded/,
  /**
   * This error means that Resize observer cannot deliver all observations whithin a single animation frame
   * and is caused by react-virtuoso which uses the Resize Observer underneath.
   * The error can be safely ignored because it is a benign error and the creator of this specification confirmed it.
   * @see https://stackoverflow.com/questions/49384120/resizeobserver-loop-limit-exceeded/50387233#50387233
   * @see https://stackoverflow.com/questions/64238740/how-to-ignore-the-resizeobserver-loop-limit-exceeded-in-testcafe/64257593#64257593
   */
  /ResizeObserver loop completed with undelivered notifications/,
  /**
   * @see https://confluence.atlassian.com/jirakb/exception-ns_error_file_corrupted-779158960.html
   * @see https://stackoverflow.com/questions/18877643/error-in-local-storage-ns-error-file-corrupted-firefox/18878068#18878068
   */
  /NS_ERROR_FILE_CORRUPTED/,
];
