import { AppStateProvider } from 'services/app-state-provider';
import { getRegion } from 'services/auth/auth-storage-manager';
import { ApplicationsActions } from 'store/features/applications/actions';

import { getConfig } from './config';

export const getEmailiniumId = (): string => {
  const { emailiniumAppId } = getConfig();

  const region = getRegion();

  if (!region) {
    throw new Error('Region not specified. Could not get emailinium app id.');
  }

  return emailiniumAppId[region] as string;
};

export const installEmailinium = (): void => {
  const appId = getEmailiniumId();
  AppStateProvider.dispatch(ApplicationsActions.installApplication(appId));
};
