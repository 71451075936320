import { getConfig } from 'helpers/config';
import { getAccessToken, getRegion } from 'services/auth/auth-storage-manager';

import { createApiClient } from '../http/client';
import { type ApiClientInstance } from '../http/types';

let instance: ApiClientInstance | null = null;

/**
 * A singleton approach is temporary to avoid creating a new client on each method call.
 * It is related to the fact that region may not be available in the configuration object when creating the client.
 * todo: Remove the singleton when regions are no longer present in the configuration object.
 */
export const createMlGatewayApiClient = (): ApiClientInstance => {
  if (!instance) {
    instance = createApiClient({
      baseURL: getConfig().mlGatewayApiUrl,
      getCredentials: getAccessToken,
      getRegion,
      prefix: '/nlp-gateway/v2/',
    });
  }

  return instance;
};
