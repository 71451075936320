import { createMlGatewayApiClient } from '../client';

import { type MLGatewaySendEvent } from './types';

/**
 * Client is now an arrow function to avoid creating a client with missing region.
 * Region is saved in configuration during startup process, not at the time of invoking the main code line during imports.
 * todo: Remove the arrow function when regions are no longer present in the configuration object.
 */
const client = () => createMlGatewayApiClient();

export const eventCollectorClient = {
  sendEvent: (data: MLGatewaySendEvent) => client().post('events/send-event', { json: data }),
};
