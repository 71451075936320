export enum Cookie {
  AccessToken = 'credentials.access_token',
  ChatsEmptyStateBanner = 'chats-empty-state-banner',
  CompanyDetailsShown = 'company-details-shown',
  CouponCode = 'lc_coupon_code',
  EnableNotificationsBarSkipped = 'lc_enable-notifications-bar-skipped',
  LastClosedBannerCookie = 'last_closed_banner',
  Region = 'region',
  Scopes = 'credentials.scopes',
  UnblockNotificationsBarSkipped = 'lc_unblock-notifications-bar-skipped',
  UtmSource = 'utm_source',
}
