import { NO_WEBSITE_ACTIVITY_DATE } from 'services/connectivity/configuration-api/properties/constants';
import { getItem, LocalStorageKey } from 'services/local-storage';

export function calculateHasWebsiteLastActivity(lastActivityValue: string | undefined): boolean {
  const mockedValue = getItem<boolean>(LocalStorageKey.MockCodeNotInstalled);
  const lastActivity = !mockedValue ? lastActivityValue : undefined;

  const hasLastActivity = !!lastActivity;
  const isNotNoActivityDate = lastActivity !== NO_WEBSITE_ACTIVITY_DATE;
  const isValidDate = lastActivity ? !isNaN(new Date(lastActivity).getTime()) : false;

  return hasLastActivity && isNotNoActivityDate && isValidDate;
}
