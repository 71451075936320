import { LogicOperator } from 'constants/logic-operator';
import { SortOrder } from 'constants/sort-order';
import { type AgentCustomPropertiesAction } from 'store/features/agent-custom-properties/actions';
import { type IActionWithPayload } from 'store/helper';

import { type TrafficAction, TrafficActionNames } from './actions';
import { DEFAULT_COLUMNS_ORDER, DEFAULT_VISIBLE_COLUMNS } from './constants';
import {
  getStateForAddFilter,
  getStateForApplySegmentFilters,
  getStateForChangeOperator,
  getStateForClearFilters,
  getStateForRefreshList,
  getStateForRemoveFilter,
  getStateForSetColumnsOrder,
  getStateForSetColumnsVisibility,
  getStateForSetCustomMessageModalState,
  getStateForSetCustomersCounters,
  getStateForSortColumn,
  getStateForUpdateFilter,
  getStateForSetIntentCampaignModalState,
} from './helpers/reducer';
import {
  type IAddFilterPayload,
  type IApplySegmentFilterPayload,
  type IChangeOperatorPayload,
  type IRefreshCustomersPayload,
  type IRemoveFilterPayload,
  type ISetColumnsOrderPayload,
  type ISetColumnsVisibilityPayload,
  type ISetCustomMessageModalStatePayload,
  type ISortColumnPayload,
  type ITrafficViewState,
  type IUpdateCustomersCountersPayload,
  type IUpdateFilterPayload,
  TrafficColumn,
  type ISetIntentCampaignModalStatePayload,
  SegmentsActionsState,
} from './interfaces';

const initialState: ITrafficViewState = {
  customersList: [],
  filters: [],
  visibleColumns: DEFAULT_VISIBLE_COLUMNS,
  hiddenColumns: [],
  columnsOrder: DEFAULT_COLUMNS_ORDER,
  sortedByColumn: TrafficColumn.VisitingTime,
  sortOrder: SortOrder.Asc,
  filterOperator: LogicOperator.And,
  customersOnlineCount: 0,
  segmentsActionsState: SegmentsActionsState.Enabled,
};

export function trafficReducer(
  state: ITrafficViewState = initialState,
  action: TrafficAction | AgentCustomPropertiesAction,
): ITrafficViewState {
  switch (action.type) {
    case TrafficActionNames.SORT_COLUMN: {
      const { payload } = action as IActionWithPayload<string, ISortColumnPayload>;

      return getStateForSortColumn(state, payload);
    }
    case TrafficActionNames.REFRESH_LIST: {
      const { payload } = action as IActionWithPayload<string, IRefreshCustomersPayload>;
      const newState = getStateForRefreshList(state, payload);

      return newState;
    }
    case TrafficActionNames.ADD_FILTER: {
      const { payload } = action as IActionWithPayload<string, IAddFilterPayload>;

      return getStateForAddFilter(state, payload);
    }
    case TrafficActionNames.REMOVE_FILTER: {
      const { payload } = action as IActionWithPayload<string, IRemoveFilterPayload>;

      return getStateForRemoveFilter(state, payload);
    }
    case TrafficActionNames.UPDATE_FILTER: {
      const { payload } = action as IActionWithPayload<string, IUpdateFilterPayload>;

      return getStateForUpdateFilter(state, payload);
    }
    case TrafficActionNames.CHANGE_OPERATOR: {
      const { payload } = action as IActionWithPayload<string, IChangeOperatorPayload>;

      return getStateForChangeOperator(state, payload);
    }
    case TrafficActionNames.APPLY_SEGMENT_FILTERS: {
      const { payload } = action as IActionWithPayload<string, IApplySegmentFilterPayload>;

      return getStateForApplySegmentFilters(state, payload);
    }
    case TrafficActionNames.SET_COLUMNS_VISIBILITY: {
      const { payload } = action as IActionWithPayload<string, ISetColumnsVisibilityPayload>;

      return getStateForSetColumnsVisibility(state, payload);
    }
    case TrafficActionNames.SET_COLUMNS_ORDER: {
      const { payload } = action as IActionWithPayload<string, ISetColumnsOrderPayload>;

      return getStateForSetColumnsOrder(state, payload);
    }
    case TrafficActionNames.CLEAR_FILTERS: {
      return getStateForClearFilters(state);
    }
    case TrafficActionNames.SET_CUSTOM_MESSAGE_MODAL_STATE: {
      const { payload } = action as IActionWithPayload<string, ISetCustomMessageModalStatePayload>;

      return getStateForSetCustomMessageModalState(state, payload);
    }
    case TrafficActionNames.UPDATE_CUSTOMERS_COUNTERS: {
      const { payload } = action as IActionWithPayload<string, IUpdateCustomersCountersPayload>;

      return getStateForSetCustomersCounters(state, payload);
    }

    case TrafficActionNames.SET_INTENT_CAMPAIGN_MODAL_STATE: {
      const { payload } = action as IActionWithPayload<string, ISetIntentCampaignModalStatePayload>;

      return getStateForSetIntentCampaignModalState(state, payload);
    }

    case TrafficActionNames.SET_SEGMENTS_ACTIONS_STATE: {
      const { payload } = action as IActionWithPayload<string, SegmentsActionsState>;

      return { ...state, segmentsActionsState: payload };
    }

    default:
      return state;
  }
}
