// @ts-strict-ignore
import { createSelector } from 'reselect';

import { type LogicOperator } from 'constants/logic-operator';
import { type SortOrder } from 'constants/sort-order';
import { agentExists, type WithAgentsState } from 'store/entities/agents/selectors';
import { botExists, type IWithBotsState } from 'store/entities/bots/selectors';
import type { ICustomer } from 'store/entities/customers/interfaces';
import { groupExists, type IWithGroupsState } from 'store/entities/groups/selectors';
import { type IWithSessionState } from 'store/features/session/selectors';

import {
  FilterType,
  FilterValueType,
  SegmentsActionsState,
  type ITrafficViewState,
  type TrafficColumn,
  type TrafficFilter,
} from './interfaces';

export interface IWithTrafficViewState {
  views: {
    traffic: ITrafficViewState;
  };
}

export function getCustomersList(state: IWithTrafficViewState): ICustomer[] {
  return state.views.traffic.customersList;
}

export function getSegmentCounter(state: IWithTrafficViewState, segmentId: string): number {
  if (!state.views.traffic.segmentsCounters) {
    return null;
  }

  return state.views.traffic.segmentsCounters[segmentId];
}

export function getCustomersSortedColumn(state: IWithTrafficViewState): TrafficColumn {
  return state.views.traffic.sortedByColumn;
}

export function getColumnSortOrder(state: IWithTrafficViewState): SortOrder {
  return state.views.traffic.sortOrder;
}

export function getTrafficFilters(state: IWithTrafficViewState): TrafficFilter[] {
  return state.views.traffic.filters;
}

export function getTrafficFiltersOperator(state: IWithTrafficViewState): LogicOperator {
  return state.views.traffic.filterOperator;
}

export function getIsLastFilterADraft(state: IWithTrafficViewState): boolean {
  return state.views.traffic.isLastFilterADraft;
}

export function getVisibleColumns(state: IWithTrafficViewState): TrafficColumn[] {
  return state.views.traffic.visibleColumns;
}

export function getHiddenColumns(state: IWithTrafficViewState): TrafficColumn[] {
  return state.views.traffic.hiddenColumns;
}

export function getAllColumnsOrder(state: IWithTrafficViewState & IWithSessionState): TrafficColumn[] {
  return state.views.traffic.columnsOrder;
}

export function isCustomMessageModalOpened(state: IWithTrafficViewState): boolean {
  return state.views.traffic.isCustomMessageModalOpened;
}

export function getCustomMessageModalCustomerId(state: IWithTrafficViewState): string {
  return state.views.traffic.customMessageModalCustomerId;
}

export function getCustomersOnlineCount(state: IWithTrafficViewState): number {
  return state.views.traffic.customersOnlineCount;
}

export function isFilterInvalid(
  state: IWithTrafficViewState & WithAgentsState & IWithBotsState & IWithGroupsState,
  filter: TrafficFilter,
): boolean {
  const { filterType, valueType } = filter;

  if (valueType === FilterValueType.SetInclusive) {
    const { value } = filter;

    if (filterType === FilterType.AssignedAgent) {
      return value.some((agentId) => !agentExists(state, agentId) && !botExists(state, agentId));
    }

    if (filterType === FilterType.Group) {
      return value.some((groupId) => !groupExists(state, groupId));
    }
  }

  return false;
}

export function isIntentCampaignModalOpen(state: IWithTrafficViewState): boolean {
  return state.views.traffic.isIntentCampaignModalOpen;
}

export function getIntentCampaignModalCustomerId(state: IWithTrafficViewState): string {
  return state.views.traffic.intentCampaignModalCustomerId;
}

export const getIsCustomersListEmpty = createSelector(getCustomersList, (customers) => customers.length === 0);

export function getIsSegmentsActionsDisabled(state: IWithTrafficViewState): boolean {
  return state.views.traffic.segmentsActionsState === SegmentsActionsState.Disabled;
}
