// @ts-strict-ignore
import { type SagaIterator } from 'redux-saga';
import { all, call, put, select, takeEvery } from 'redux-saga/effects';

import { EventNames } from 'constants/event-bus-events';
import { type PlanType } from 'constants/plan-type';
import { PostMessageEvent } from 'constants/post-message-event';
import { QueryKey } from 'constants/query-key';
import { SubscriptionRouteEvent } from 'constants/subscription/event';
import { ToastAutoHideDelay, ToastContent } from 'constants/toasts';
import { EventPlace } from 'helpers/analytics';
import { sendPostMessageToMarketplace } from 'helpers/post-message';
import { isCovidCoupon } from 'helpers/recurly';
import { redirectToAccounts } from 'helpers/redirect-to-accounts';
import { getToastContent } from 'helpers/toast';
import type { RequestResult } from 'interfaces/api/client';
import type { Product, ProductsCart } from 'interfaces/product-cart';
import {
  BillingCycleType,
  SubscriptionChangedType,
  type INewSubscription,
  type ISubscription,
} from 'interfaces/subscription';
import { ApiManager } from 'services/api/api-manager';
import type { ISubscriptionDTO } from 'services/api/subscription/interfaces';
import { SubscriptionSerializer } from 'services/api/subscription/serializer';
import { PlatformNamespace } from 'services/connectivity/configuration-api/properties/constants';
import { EventBus } from 'services/event-bus';
import { trackEvent } from 'services/event-tracking';
import { getQueryClient } from 'services/query-client/client';
import { QUERY_KEYS } from 'services/query-client/keys';
import { getIsGoogleAnalyticsInstalled } from 'store/entities/applications/selectors';
import { BillingActionNames } from 'store/entities/billing/actions';
import { getApplicationsToUninstall } from 'store/entities/billing/selectors';
import { LicenseCustomPropertiesActions } from 'store/entities/license-custom-properties/actions';
import { getCurrentCart, getCurrentCartProductsToSubscribe } from 'store/entities/product-cart/selectors';
import { RecurlyActions } from 'store/entities/recurly/actions';
import { RedemptionResource, type IRecurlyCoupon } from 'store/entities/recurly/interfaces';
import { getNewRecurlyCoupon } from 'store/entities/recurly/selectors';
import { SubscriptionActionNames, SubscriptionActions } from 'store/entities/subscription/actions';
import { getEstimatedPlanPrice, getSubscription, getSubscriptionChange } from 'store/entities/subscription/selectors';
import { ApplicationsActions } from 'store/features/applications/actions';
import { ToastsActions } from 'store/features/toasts/actions';
import { ToastVariant } from 'store/features/toasts/interfaces';
import { getSignInError } from 'store/views/startup-error/selectors';
import { SubscriptionViewActions } from 'store/views/subscription/actions';
import {
  getHighlightedFeatures,
  getIsNewCouponApplicable,
  getRecommendedPlan,
} from 'store/views/subscription/selectors';

import { unsubscribeApplication } from '../applications/unsubscribe-application';
import { realizeCurrentCart, refreshApplicationsData } from '../product-cart/realize-cart';

import { SubscriptionEventFields } from './constants';
import {
  checkIsRecurlyError,
  deserializeError,
  getTrackerId,
  mapSubscriptionToNewSubscription,
  refreshCouponData,
  saveAutomaticUpsellingEnabled,
  triggerSalesTracker,
} from './helpers';

export enum AppsInstallationStatus {
  Success = 'success',
  Failure = 'failure',
  Skipped = 'skipped',
}

function* handleApplicationsUninstall(): SagaIterator {
  const applicationsToUninstall: Product[] = yield select(getApplicationsToUninstall);
  yield all(
    applicationsToUninstall.map(({ id }) =>
      call(unsubscribeApplication, {
        type: BillingActionNames.CANCEL_APPLICATION_RECURRING_REQUEST,
        payload: { applicationId: id },
      }),
    ),
  );
  yield put(
    ApplicationsActions.removeApplicationsFromCustomOrder({
      applications: applicationsToUninstall.map(({ id }) => id),
    }),
  );
}

function* updateCoupon(couponCode: string): SagaIterator {
  const coupon: IRecurlyCoupon = yield select(getNewRecurlyCoupon);

  // TODO
  // At this moment we can add only account lvl coupons by this endpoint
  // The condition could be removed after changes in API
  if (coupon.exists && coupon.redemptionResource === RedemptionResource.Account) {
    const { error }: RequestResult<unknown, { error: string }> = yield call(
      ApiManager.subscriptionApi.updateCoupon,
      couponCode,
    );
    if (error) throw error;
  }
}

export function* trackSubscriptionUpdateEvents(
  newSubscription: INewSubscription,
  subscription: ISubscription,
  appsInstallationStatus: AppsInstallationStatus,
  installedAppsNumber?: number,
  uninstalledApsNumber?: number,
): SagaIterator {
  const recommended: PlanType = yield select(getRecommendedPlan);
  const highlighted: string[] = yield select(getHighlightedFeatures);
  const isGoogleAnalyticsInstalled: boolean = yield select(getIsGoogleAnalyticsInstalled);
  const hadErrorOnSignIn = yield select(getSignInError);

  trackEvent(SubscriptionRouteEvent.SubscriptionUpdated, EventPlace.Subscription, {
    [SubscriptionEventFields.SubscriptionChangeType]: newSubscription.changeType,
    [SubscriptionEventFields.OldPlan]: subscription.plan,
    [SubscriptionEventFields.NewPlan]: newSubscription.plan,
    [SubscriptionEventFields.IsPlanChanged]: subscription.plan !== newSubscription.plan,
    [SubscriptionEventFields.OldSeats]: subscription.seats,
    [SubscriptionEventFields.NewSeats]: newSubscription.seats,
    [SubscriptionEventFields.IsSeatsFieldChanged]: subscription.seats !== newSubscription.seats,
    [SubscriptionEventFields.OldMonths]: subscription.billingCycle,
    [SubscriptionEventFields.NewMonths]: newSubscription.billingCycle,
    [SubscriptionEventFields.IsMonthsFieldChanged]: subscription.billingCycle !== newSubscription.billingCycle,
    [SubscriptionEventFields.OldFlexiblePricing]: subscription.automaticUpsellingEnabled,
    [SubscriptionEventFields.NewFlexiblePricing]: newSubscription.automaticUpsellingEnabled,
    [SubscriptionEventFields.GoogleAnalytics]: isGoogleAnalyticsInstalled,
    [SubscriptionEventFields.RecommendedPlan]: recommended,
    [SubscriptionEventFields.HighlightedFeatures]: highlighted.join(','),
    [SubscriptionEventFields.AppsInstallationStatus]: appsInstallationStatus,
    ...(appsInstallationStatus === AppsInstallationStatus.Success
      ? {
          [SubscriptionEventFields.AppsChanged]: installedAppsNumber + uninstalledApsNumber,
          [SubscriptionEventFields.AppsInstalled]: installedAppsNumber,
          [SubscriptionEventFields.AppsUninstalled]: uninstalledApsNumber,
        }
      : {}),
  });

  if (hadErrorOnSignIn) {
    redirectToAccounts();
  }
}

/**
 * Update subscription saga, divided into 3 separate transactions
 * 1. Update subscription - if this one fails then cancell the saga
 * 2. Product cart realization - basing on results we are sending proper event of subscription update with apps instalation status
 * 3. Side effects - refresh license and subscription data
 */
function* updateSubscription(): SagaIterator {
  const oldSubscription: ISubscription = yield select(getSubscription);
  const newSubscription: INewSubscription = yield select(getSubscriptionChange);
  const isNewCouponApplicable = yield select(getIsNewCouponApplicable);
  const estimatedPlanPrice: number = yield select(
    getEstimatedPlanPrice,
    newSubscription.seats,
    newSubscription.planCode,
  );
  const serializedSubscription = SubscriptionSerializer.serialize(newSubscription, { isNewCouponApplicable });

  // 1. Subscription update
  try {
    if (isNewCouponApplicable && newSubscription.changeType === SubscriptionChangedType.Unchanged) {
      yield call(updateCoupon, newSubscription.couponCode);
    } else {
      const { error }: RequestResult<unknown> = yield call(ApiManager.subscriptionApi.update, serializedSubscription);
      if (error) throw error;
    }

    if (isCovidCoupon(newSubscription.couponCode)) {
      yield put(LicenseCustomPropertiesActions.saveLicenseCustomProperty({ name: 'covid_coupon_used', value: '1' }));
    }

    yield call(saveAutomaticUpsellingEnabled);

    const queryClient = getQueryClient();
    yield call(
      queryClient.invalidateQueries.bind(queryClient),
      QUERY_KEYS[QueryKey.LicenseProperties](PlatformNamespace.BILLING),
    );

    yield put(
      ToastsActions.createToast({
        content: getToastContent(ToastContent.SUBSCRIPTION_UPDATED),
        kind: ToastVariant.Success,
        autoHideDelayTime: ToastAutoHideDelay.Long,
      }),
    );

    const isUpgrade = newSubscription.changeType === SubscriptionChangedType.Upgrade;
    const isMonthlyUpgrade = isUpgrade && serializedSubscription.months === BillingCycleType.Monthly;
    const isAnnualUpgrade = isUpgrade && serializedSubscription.months === BillingCycleType.Annually12;

    if (isMonthlyUpgrade) {
      triggerSalesTracker(getTrackerId('upgradesMonthly'), estimatedPlanPrice.toString());
    }
    if (isAnnualUpgrade) {
      triggerSalesTracker(getTrackerId('upgradesAnnually'), estimatedPlanPrice.toString());
    }

    EventBus.emit(EventNames.SubscriptionChanged);
  } catch (e) {
    const isErrorFromRecurly = checkIsRecurlyError(e);
    if (isErrorFromRecurly) {
      yield put(SubscriptionViewActions.setRecurlyError(e.error.recurly || e['#']));
    }
    const error = deserializeError(e);

    if (error.toLowerCase().includes('coupon')) {
      yield put(RecurlyActions.redemptionError({ error }));
    }

    if (!isErrorFromRecurly) {
      yield put(ToastsActions.createToast({ content: error, kind: ToastVariant.Error, autoHideDelayTime: undefined }));
    }
    trackEvent(SubscriptionRouteEvent.SubscriptionFailed, EventPlace.Subscription, { error });
    yield put(SubscriptionActions.updateSubscriptionFailure(e));

    return;
  }

  // 2. Addons installation / deinstallation + track update event with apps installation status
  try {
    const cartProducts: Product[] = yield select(getCurrentCartProductsToSubscribe);
    const addonsToUninstall: Product[] = yield select(getApplicationsToUninstall);
    const addonsToUninstallNumber = addonsToUninstall.length;
    const addonsToInstallNumber = cartProducts.length;
    const cartProductsIds = cartProducts.map(({ id }) => id);

    yield all([call(realizeCurrentCart), call(handleApplicationsUninstall)]);
    yield call(refreshApplicationsData);
    // Mark purchased cart apps as selected
    yield put(ApplicationsActions.addCartApplicationsSelected(cartProductsIds));
    yield call(
      trackSubscriptionUpdateEvents,
      newSubscription,
      oldSubscription,
      AppsInstallationStatus.Success,
      addonsToInstallNumber,
      addonsToUninstallNumber,
    );
  } catch (e) {
    const error = deserializeError(e);
    const currentCart: ProductsCart | null = yield select(getCurrentCart);

    yield put(ToastsActions.createToast({ content: error, kind: ToastVariant.Error }));
    trackEvent(SubscriptionRouteEvent.ProductCartRealizationFailed, EventPlace.Subscription, {
      error,
      cartId: currentCart?.id,
    });
    yield call(trackSubscriptionUpdateEvents, newSubscription, oldSubscription, AppsInstallationStatus.Failure);
  }

  // Refresh license / subscription / cart / applications data
  try {
    const { result, error }: RequestResult<ISubscriptionDTO> = yield call(ApiManager.subscriptionApi.fetch);
    if (error) {
      yield put(SubscriptionViewActions.licenseDataRefreshFailure());

      return;
    }

    const deserializedSubscription = SubscriptionSerializer.deserialize(result);

    yield call(refreshCouponData, deserializedSubscription);
    yield put(SubscriptionActions.updateSubscriptionSuccess(deserializedSubscription));
    yield call(mapSubscriptionToNewSubscription, deserializedSubscription);
    yield call(sendPostMessageToMarketplace, PostMessageEvent.RefreshSubscriptionInfo);
  } catch (e) {
    const error = deserializeError(e);

    trackEvent(SubscriptionRouteEvent.UpdateSubscriptionSideEffectFailed, EventPlace.Subscription, { error });
    yield put(
      ToastsActions.createToast({
        content: getToastContent(ToastContent.SOMETHING_WENT_WRONG),
        kind: ToastVariant.Error,
        autoHideDelayTime: ToastAutoHideDelay.Long,
      }),
    );
  }
}

export function* updateSubscriptionSaga(): SagaIterator {
  yield takeEvery(SubscriptionActionNames.UPDATE_SUBSCRIPTION, updateSubscription);
}
