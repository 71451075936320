// @ts-strict-ignore
import { type KeyMap } from 'helpers/interface';
import type { CustomSegment, CustomSegmentValue } from 'interfaces/custom-segment';
import { type IWithAgentCustomPropertiesState } from 'store/features/agent-custom-properties/selectors';

import type { SectionCustomSegments, EditedSegment, CustomSegmentsState } from './interfaces';

export interface WithCustomSegmentsState {
  features: {
    customSegments: CustomSegmentsState;
  };
}

function getCustomSegmentsSortedIds(state: WithCustomSegmentsState, sectionName: string): string[] | undefined {
  return state.features.customSegments[sectionName]?.sortedIds;
}

/**
 * Indicates whether custom segments are configured in the agent's custom properties (even as empty array)
 * @returns true if custom segments are in the agent's props, false otherwise or if custom props are not yet fetched
 */
export function areCustomSegmentsConfigured(state: WithCustomSegmentsState, sectionName: string): boolean {
  return Boolean(getCustomSegmentsSortedIds(state, sectionName));
}

/**
 * Indicates whether agent's custom properties are processed and you can check if custom segments are configured in the props
 * @returns true if agent's props are processed, false otherwise
 */
export function areCustomSegmentsAvailable(state: WithCustomSegmentsState, sectionName: string): boolean {
  return Boolean(state.features.customSegments[sectionName]);
}

export function getSectionCustomSegmentsIds(
  state: WithCustomSegmentsState & IWithAgentCustomPropertiesState,
  sectionName: string,
): string[] | null {
  const areCustomSegments = areCustomSegmentsConfigured(state, sectionName);

  if (!areCustomSegments) return null;

  return getCustomSegmentsSortedIds(state, sectionName);
}

export function getSectionCustomSegmentsData(
  state: WithCustomSegmentsState,
  sectionName: string,
): KeyMap<CustomSegment> {
  return state.features.customSegments[sectionName]?.data;
}

export function getSectionCustomSegments(state: WithCustomSegmentsState, sectionName: string): SectionCustomSegments {
  return state.features.customSegments[sectionName];
}

export function getSectionSelectedSegmentId(state: WithCustomSegmentsState, sectionName: string): string {
  return state.features.customSegments[sectionName]?.selectedId;
}

export function getCustomSegmentData(
  state: WithCustomSegmentsState,
  sectionName: string,
  segmentId: string,
): CustomSegment {
  return state.features.customSegments[sectionName]?.data[segmentId];
}

export function getSectionSegmentDataValue(
  state: WithCustomSegmentsState,
  sectionName: string,
  sectionSelectedId: string,
): CustomSegmentValue | undefined {
  return state.features.customSegments[sectionName]?.data[sectionSelectedId]?.value;
}

export function getSectionSelectedSegmentDataValue(
  state: WithCustomSegmentsState,
  sectionName: string,
): CustomSegmentValue | undefined {
  const sectionSelectedId = getSectionSelectedSegmentId(state, sectionName);

  return getSectionSegmentDataValue(state, sectionName, sectionSelectedId);
}

export function getSectionCustomSegmentsCurrentlyEditedId(state: WithCustomSegmentsState, sectionName: string): string {
  return state.features.customSegments[sectionName]?.editedSegment?.id;
}

export function getSectionCustomSegmentsCurrentlyEdited(
  state: WithCustomSegmentsState,
  sectionName: string,
): EditedSegment {
  return state.features.customSegments[sectionName]?.editedSegment;
}
