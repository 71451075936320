export enum ApiErrorMessagePart {
  AccountSuspended = 'Your account is suspended.',
  AlreadyLoggedIn = 'Already logged in',
  AlreadyLoggingIn = 'Already logging in',
  AwaitingApproval = 'Your account is awaiting approval.',
  ExceededMaxStringLength = 'value must be at most',
  InternalServerError = 'Internal server error',
  InvalidAccessToken = 'Invalid access token',
  IpNotAllowed = 'IP not allowed',
  LicenseExpired = 'License expired',
  Unauthorized = 'Unauthorized',
  UserNotActive = 'User not active',
}

export enum HTTPStatusCode {
  BadRequest = 400,
  Unauthorized = 401,
  Forbidden = 403,
  NotFound = 404,
  Conflict = 409,
  Empty = 0,
}
