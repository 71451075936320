// @ts-strict-ignore
import { type SessionAction, SessionActionNames } from 'store/features/session/actions';

import type { ISessionState } from './interfaces';

export const initialState = {
  license: null,
  rights: null,
  isGhost: false,
  checkedSessionsLimit: false,
};

export const sessionReducer = (state: ISessionState = initialState, action?: SessionAction): ISessionState => {
  switch (action.type) {
    case SessionActionNames.SAVE_CURRENT_LICENSE: {
      const { license } = action.payload;

      return {
        ...state,
        license: {
          ...state.license,
          ...license,
        },
      };
    }

    case SessionActionNames.SAVE_LICENSE_PLAN: {
      const { plan } = action.payload;

      return {
        ...state,
        license: {
          ...state.license,
          plan,
        },
      };
    }

    case SessionActionNames.SAVE_RIGHTS: {
      const { rights } = action.payload;

      return {
        ...state,
        rights: {
          ...rights,
        },
      };
    }

    case SessionActionNames.SAVE_GHOST_STATUS: {
      const { isGhost } = action.payload;

      return {
        ...state,
        isGhost,
      };
    }

    case SessionActionNames.SAVE_HAS_LOGGED_IN: {
      const { hasLoggedIn } = action.payload;

      return {
        ...state,
        hasLoggedIn,
      };
    }

    case SessionActionNames.SAVE_AUTO_AWAY_STATUS: {
      const { isAway } = action.payload;

      return {
        ...state,
        isAutoAwaySet: isAway,
      };
    }

    case SessionActionNames.CHECKED_SESSIONS_LIMIT: {
      return {
        ...state,
        checkedSessionsLimit: true,
      };
    }

    default:
      return state;
  }
};
