// @ts-strict-ignore
/* eslint-disable @typescript-eslint/naming-convention */
import { format, isBefore, isValid, parseISO } from 'date-fns';
import isNumber from 'lodash.isnumber';
import uniq from 'lodash.uniq';
import memoizeOne from 'memoize-one';

import { DateFormat } from 'constants/date';
import { type CustomDateRangeFilter } from 'constants/filters/date-range-filter';
import { PriorityFilter } from 'constants/filters/priority-filter';
import { TagFilter } from 'constants/filters/tag-filter';
import { ReportDistribution } from 'constants/reports/distribution';
import { NavigationItems } from 'constants/reports/navigation-items';
import { ReportType } from 'constants/reports/report-type';
import { getConfig } from 'helpers/config';
import { parseDate } from 'helpers/date';
import { deepMerge } from 'helpers/object';
import { getParsedReportsQueryParams, getReportsViewQueryParams } from 'helpers/reports';
import { navigate } from 'helpers/routing';
import { formatTimeFromHours } from 'helpers/time';
import { stringifyQueryParams, type IKeyValue } from 'helpers/url';
import type {
  ChartValue,
  IChartAxisCategory,
  IChartOptions,
  IChartSeries,
  IChartSeriesPoint,
  IExportData,
  IHeatmapPoint,
  ISerializedFilters,
  ISerializedFiltersWithRawDates,
} from 'interfaces/reports';
import { AppStateProvider } from 'services/app-state-provider';
import { ThreadCustomPropertyName } from 'services/socket-lc3/chat/event-handling/constants';
import type { WithAgentsState } from 'store/entities/agents/selectors';
import type { IWithBotsState } from 'store/entities/bots/selectors';
import { getGroupName, type IWithGroupsState } from 'store/entities/groups/selectors';
import { getIs24hFormat, type IWithAgentCustomPropertiesState } from 'store/features/agent-custom-properties/selectors';
import {
  getFilterAgents,
  getFilterGroup,
  hasReportComparisonDisabled,
  hasReportDateRangeFilterComparison,
  hasReportFiltersComparison,
  type IWithReportsViewState,
} from 'store/views/reports/selectors';
import { getAgentOrBotName } from 'store/views/team/computed';

import { type IChartLegend } from './components/interfaces';

type CSVField = string | number | boolean | null | undefined;

export const LABEL_GLUE = '<br>';
const ACCOUNT_ID = getConfig().accountsClientId;

export function getExportFilename(
  name: string,
  dateFrom: Date,
  dateTo: Date,
  distribution: ReportDistribution = ReportDistribution.Day,
): string {
  return [
    'livechatinc',
    name,
    dateFrom && isValid(dateFrom) && format(dateFrom, DateFormat.ISO8601Date),
    dateTo && isValid(dateTo) && format(dateTo, DateFormat.ISO8601Date),
    distribution === ReportDistribution.Hour ? '24h' : null,
  ]
    .filter(Boolean)
    .join('_');
}

function prepareChartLabel(label = '', separator = ', '): string {
  const state = AppStateProvider.getState();
  const agent = getFilterAgents(state);
  const group = getFilterGroup(state);
  const chartLabel = label;

  const labelParts = [
    chartLabel,
    group ? (getGroupName(state, group.groups[0]) || '').trim() : 'all groups',
    agent ? (getAgentOrBotName(state, agent.agents[0]) || '').trim() : 'all agents',
  ];

  return labelParts.filter(Boolean).join(separator);
}

const LEGACY_HEATMAP_NAMES = ['Queued customers', 'Tickets'];

function getExportDataByHeatmapOptions(
  heatmapOptions: IChartOptions<IHeatmapPoint, ISerializedFiltersWithRawDates | ISerializedFilters>,
): (string | number)[][] {
  const isLegacyHeatmap = LEGACY_HEATMAP_NAMES.includes(heatmapOptions.series[0].name);

  const categoriesX = heatmapOptions.xAxis.categories as IChartAxisCategory[];
  const categoriesY = heatmapOptions.yAxis.categories;

  const valueLabel = prepareChartLabel('value', '|').toLowerCase();
  const heatmapData = heatmapOptions.series[0].data;

  if (isLegacyHeatmap) {
    return [
      ['date', 'time', valueLabel],
      ...heatmapData.map((element) => {
        const rawDayString = categoriesX[element.x].value as string;
        const day = format(parseISO(rawDayString), DateFormat.ISO8601Date);
        const hour = categoriesY[element.y];

        return [day, hour, element.value];
      }),
    ];
  }

  const heatmapDataByDay = heatmapData.reduce(
    (acc, point) => {
      const rawDayString = categoriesX[point.x].value as string;
      const day = format(parseISO(rawDayString), DateFormat.ISO8601Date);
      const hour = categoriesY[point.y];

      if (!acc[day]) {
        acc[day] = [[day, hour, point.value]];
      } else {
        acc[day].push([day, hour, point.value]);
      }

      return acc;
    },
    {} as Record<string, [string, string, number][]>,
  );

  const heatmapReversedData = Object.values(heatmapDataByDay).flatMap((dayData) => dayData.reverse());

  return [['date', 'time', valueLabel], ...heatmapReversedData];
}

export function escapeCSVField(field: CSVField | CSVField[]): string {
  if (field === null || field === undefined) {
    return '';
  }

  if (Array.isArray(field)) {
    return escapeCSVField(field.join('|'));
  }

  const stringField = String(field);

  // If the field starts with =, +, -, @, prepend a single quote and escape any existing double quotes
  if (/^[=+\-@]/.test(stringField)) {
    return `'${stringField.replace(/"/g, '""')}`;
  }

  // If the field contains commas, newlines, or double quotes, escape any existing double quotes
  if (/[,\n"]/.test(stringField)) {
    return `${stringField.replace(/"/g, '""')}`;
  }

  return stringField;
}

export function getExportDataByChartOptions(
  chartOptions: Partial<IChartOptions<ChartValue, ISerializedFiltersWithRawDates | ISerializedFilters>>,
  mapper = (
    series: IChartSeries<ChartValue, ISerializedFiltersWithRawDates | ISerializedFilters>,
  ): IChartSeries<ChartValue, ISerializedFiltersWithRawDates | ISerializedFilters> => series,
  state: IWithReportsViewState &
    IWithGroupsState &
    WithAgentsState &
    IWithBotsState &
    IWithAgentCustomPropertiesState = AppStateProvider.getState(),
): IExportData {
  if (chartOptions.chart.type === 'heatmap') {
    return getExportDataByHeatmapOptions(
      chartOptions as IChartOptions<IHeatmapPoint, ISerializedFiltersWithRawDates | ISerializedFilters>,
    );
  }

  const hasComparison = !hasReportComparisonDisabled(state) && hasReportFiltersComparison(state);
  const hasDateRangeComparison = hasReportDateRangeFilterComparison(state);
  const is24hFormat = getIs24hFormat(state);

  const rows: IExportData = [];

  const distribution = chartOptions.xAxis.distribution || ReportDistribution.Day;

  const categories = (chartOptions.xAxis.categories || []).map((category: IChartAxisCategory) => {
    if (distribution === ReportDistribution.Hour) {
      return is24hFormat ? category.value[0] : category.label;
    }

    const value = [...category.value];

    if (hasDateRangeComparison && value.length < 2) {
      value.push(null);
    }

    return value;
  });

  const series = [...(chartOptions.series || [])]
    .sort((a, b) => {
      const orderA = a.csvColumnOrder || 0;
      const orderB = b.csvColumnOrder || 0;

      return orderA - orderB;
    })
    .map(mapper);

  if (!series.length) {
    return null;
  }

  rows.push([
    distribution !== ReportDistribution.Hour ? 'date' : 'time',
    ...series.map((s) => {
      const {
        name,
        csvColumnName,
        filters: { dateFrom, dateTo, groups, agents },
      } = s;

      const columnName = csvColumnName || name || '';

      const labelParts = [columnName];

      if (hasComparison) {
        const groupsNames = groups && groups.map((group) => getGroupName(state, group).trim()).join(' OR ');
        const agentsNames = agents && agents.map((agent) => getAgentOrBotName(state, agent).trim()).join(' OR ');
        labelParts.push(
          ...[
            format(dateFrom, DateFormat.ISO8601Date),
            format(dateTo, DateFormat.ISO8601Date),
            groupsNames,
            agentsNames,
          ],
        );
      }

      return escapeCSVField(labelParts.filter(Boolean).join('|').toLowerCase());
    }),
  ]);

  categories.forEach((label, index) => {
    const row = [label];
    series.forEach((s) => {
      const value = s.data[index];
      row.push(isNumber(value) ? escapeCSVField(value) : '-');
    });
    rows.push(row);
  });

  return rows;
}

/* eslint-disable no-invalid-this */

export const getNavigationResolver =
  (
    pathname = '/archives',
    queryParamsParser = (params: IKeyValue, _point: IChartSeriesPoint) => params,
  ): ((distribution: ReportDistribution) => void) =>
  (distribution: ReportDistribution) =>
    function navigationResolver(this: IChartSeriesPoint): void {
      const queryParams = getParsedReportsQueryParams(this, distribution, queryParamsParser);

      if (queryParams) {
        navigate(`${pathname}?${queryParams}`);
      }
    };

/* eslint-enable no-invalid-this */

function defaultLegendQueryParamsParser(params: IKeyValue, ..._props): IKeyValue {
  const queryParams = { ...params };

  delete queryParams.group_by;

  return queryParams;
}

export const getLegendNavigationResolver =
  (pathname = '/archives', queryParamsParser = defaultLegendQueryParamsParser) =>
  (singleSeries: IChartLegend): string => {
    const { urlParams, filters } = singleSeries;
    const state = AppStateProvider.getState();

    const filtersQueryParams = getReportsViewQueryParams(state);

    const hasComparison = !hasReportComparisonDisabled(state) && hasReportFiltersComparison(state);

    if (hasComparison) {
      if (filters.dateFrom && filters.dateTo && filtersQueryParams.date_from && filtersQueryParams.date_to) {
        filtersQueryParams.date_from = format(filters.dateFrom, DateFormat.ISO8601Date);
        filtersQueryParams.date_to = format(filters.dateTo, DateFormat.ISO8601Date);
      }

      if (filters.groups && filtersQueryParams.group) {
        filtersQueryParams.group = filters.groups;
      }

      if (filters.agents && filtersQueryParams.agent) {
        filtersQueryParams.agent = filters.agents;
      }

      delete filtersQueryParams.compare_date_from;
      delete filtersQueryParams.compare_date_to;
      delete filtersQueryParams.compare_group;
      delete filtersQueryParams.compare_agent;
    }

    if (filters?.tagged === '0') {
      filtersQueryParams.tag = [TagFilter.NotTagged];
    }

    if (filters?.properties?.[ACCOUNT_ID]?.[ThreadCustomPropertyName.PriorityChat]?.values?.[0] === true) {
      filtersQueryParams.priority = PriorityFilter.Yes;
    } else if (filters?.properties?.[ACCOUNT_ID]?.[ThreadCustomPropertyName.PriorityChat]?.exists === false) {
      filtersQueryParams.priority = PriorityFilter.No;
    }

    const params = {
      ...filtersQueryParams,
      ...(urlParams || {}),
    };

    return `${pathname}?${stringifyQueryParams(queryParamsParser(params, singleSeries))}`;
  };

export function getWeekdayLabelFromDay(day: string | null | undefined): string | null {
  if (!day) {
    return null;
  }

  const date = parseDate(day);
  if (!isValid(date)) {
    return null;
  }

  return format(date, DateFormat.DayOfWeek);
}

export function getFormattedChartLabel(value: string, distribution: ReportDistribution): string {
  if (!value || !distribution) {
    return null;
  }

  if (distribution === ReportDistribution.Hour) {
    return formatTimeFromHours(value);
  }
  const date = parseDate(value);

  if (!isValid(date)) {
    return null;
  }

  if (distribution === ReportDistribution.Month) {
    return format(date, DateFormat.ChartMonthlyLabel);
  }

  return format(date, DateFormat.ChartDailyLabel);
}

function getDistributionChartLabel(values: string | string[], distribution: ReportDistribution): string {
  if (!Array.isArray(values)) {
    return values;
  }

  return uniq(values)
    .map((value) => getFormattedChartLabel(value, distribution))
    .join(LABEL_GLUE);
}

export function defaultLabelFormatter(value: string | string[]): string {
  if (!value) {
    return '';
  }

  if (Array.isArray(value)) {
    return value.filter(Boolean).join(LABEL_GLUE);
  }

  return value;
}

function getDistributionOptions(
  distribution: ReportDistribution,
  xLabels: (string[] | string)[],
): Partial<IChartOptions> {
  const navigationOptions = distribution === ReportDistribution.Day && {
    plotOptions: {
      series: {
        cursor: undefined,
        point: undefined,
      },
    },
  };

  return {
    xAxis: {
      categories: xLabels.map((values) => ({ label: getDistributionChartLabel(values, distribution), values })),
    },
    ...navigationOptions,
  };
}

export function getChartOptions(
  series: IChartSeries[],
  xLabels: (string | string[])[],
  distribution: ReportDistribution | null = ReportDistribution.Day,
  navigationResolver = getNavigationResolver(),
  ...options: Partial<IChartOptions>[]
): Partial<IChartOptions> {
  const state = AppStateProvider.getState();
  const is24hFormat = getIs24hFormat(state);
  const isHourDistribution = [ReportDistribution.Hour, ReportDistribution.DayHours].includes(distribution);
  const xAxisFormat = isHourDistribution && is24hFormat ? '{value.value}' : '{value.label}';

  const chartOptions: Partial<IChartOptions> = {
    chart: {
      type: 'column',
    },
    xAxis: {
      categories: xLabels.map((value) => ({ label: defaultLabelFormatter(value), value })),
      distribution,
      labels: {
        format: xAxisFormat,
      },
    },
    yAxis: {
      max:
        [].concat(...series.map((s) => s.data)).reduce((sum: number, curr: number) => sum + curr, 0) === 0 ? 1 : null,
    },
    series,
  };

  const navigationOptions: Partial<IChartOptions> = distribution !== ReportDistribution.Hour &&
    navigationResolver && {
      plotOptions: {
        series: {
          cursor: 'pointer',
          point: {
            events: {
              click: navigationResolver(distribution),
            },
          },
        },
      },
    };

  const distributionOptions = distribution && getDistributionOptions(distribution, xLabels);

  const forceReportType: Partial<IChartOptions> = xLabels.length > 21 && {
    chart: {
      type: 'line',
    },
    xAxis: {
      tickInterval: xLabels.length > 35 ? 7 : 4,
    },
    tooltip: {
      shared: true,
    },
  };

  return deepMerge(
    true,
    {},
    chartOptions,
    navigationOptions,
    distributionOptions,
    forceReportType,
    ...options,
  ) as Partial<IChartOptions>;
}

// GLOBAL ACCOUNTS TODO: LICENSE PLAN SCOPE CHECK
export const getNavigationItems = memoizeOne(
  (canExportData: boolean, hasReportsAccess: boolean, areNativeTicketsDisabled: boolean) => {
    let items = NavigationItems.slice();

    if (hasReportsAccess && !canExportData) {
      items = items.filter((item) => item.id !== 'export');
    }

    if (hasReportsAccess && areNativeTicketsDisabled) {
      items = items.filter((item) => item.id !== 'tickets');
    }

    return items;
  },
);

export function prepareHeatmapLabel(hasAnyFilterComparison: boolean): string {
  if (hasAnyFilterComparison) {
    return 'Comparing filters such as time, groups or agents is unavailable in heatmap visualization.';
  }

  return null;
}

export function offlineMessageParamsParser(params: IKeyValue): IKeyValue {
  return {
    ...params,
    offline_message: 0,
  };
}

export function chatFirstResponseTimesParamsParser(params: IKeyValue): IKeyValue {
  return {
    ...params,
    agent_response: 1,
  };
}

export function chatResponseTimesParamsParser(params: IKeyValue): IKeyValue {
  return {
    ...params,
    agent_response: 0,
  };
}

export function isCustomDateRangeFilterBefore(filter: CustomDateRangeFilter, targetDate: string | Date): boolean {
  if (!filter) {
    return false;
  }
  const { from, compare } = filter;
  const { from: fromCompare } = compare ?? {};

  return (from && isBefore(from, targetDate)) || (fromCompare && isBefore(fromCompare, targetDate));
}

export function isReportAvailableMarketplace(reportType: ReportType): boolean {
  const { reportsApplications } = getConfig();

  return Object.keys(reportsApplications).some((r) => ReportType[r] === reportType);
}
