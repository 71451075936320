import { createMlGatewayApiClient } from '../client';
import { type GenericMlGatewayError } from '../types';

import type {
  CreateTextGrammarCorrectionRequest,
  CreateTextRewordRequest,
  CreateTextSentimentRequest,
  CreateTextTranslationRequest,
  TextEnhancementResponse,
} from './types';

/**
 * Client is now an arrow function to avoid creating a client with missing region.
 * Region is saved in configuration during startup process, not at the time of invoking the main code line during imports.
 * todo: Remove the arrow function when regions are no longer present in the configuration object.
 */
const client = () => createMlGatewayApiClient();

export const textEnhancementsClient = {
  createTextTranslation: async (data: CreateTextTranslationRequest) => {
    return client().post<TextEnhancementResponse, GenericMlGatewayError>('text-enhancements/translations', {
      json: data,
    });
  },

  createTextSentiment: async (data: CreateTextSentimentRequest) => {
    return client().post<TextEnhancementResponse, GenericMlGatewayError>('text-enhancements/sentiments', {
      json: data,
    });
  },

  createTextGrammarCorrection: async (data: CreateTextGrammarCorrectionRequest) => {
    return client().post<TextEnhancementResponse, GenericMlGatewayError>('text-enhancements/grammar-corrections', {
      json: data,
    });
  },

  createTextReword: async (data: CreateTextRewordRequest) => {
    return client().post<TextEnhancementResponse, GenericMlGatewayError>('text-enhancements/rewordings', {
      json: data,
    });
  },
};
