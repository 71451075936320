import type { SagaIterator } from 'redux-saga';
import { call, delay, put, select, take, takeEvery, takeLatest } from 'redux-saga/effects';

import { PostMessageEvent } from 'constants/post-message-event';
import { ToastContent } from 'constants/toasts';
import { getConfig } from 'helpers/config';
import { sendPostMessageToMarketplace } from 'helpers/post-message';
import { getToastContent } from 'helpers/toast';
import type { RequestResult } from 'interfaces/api/client';
import { ApiManager } from 'services/api/api-manager';
import type { RequestError } from 'services/api/types';
import { IntegrationLicensePropertiesActions } from 'store/entities/integration-license-properties/actions';
import {
  ApplicationsActionNames as ApplicationsFeaturesActionNames,
  ApplicationsActions as ApplicationsFeaturesActions,
} from 'store/features/applications/actions';
import { ToastsActions } from 'store/features/toasts/actions';
import { ToastVariant } from 'store/features/toasts/interfaces';
import type { IActionWithPayload } from 'store/helper';
import { getHasVisitedMarketplace } from 'store/views/navigation/selectors';

import { CRUDAction, RequestAction } from '../actions';

import { ApplicationsActionNames, ApplicationsActions } from './actions';
import type { IApplication, RefreshInstalledApplicationsData } from './interfaces';
import {
  applicationsSelector,
  getInitialSelectedApplicationIds,
  getInitialUnselectedApplicationIds,
  type IAppsMap,
} from './selectors';

const MAX_POLLING_RETRIES = 5;

function* fetchApplications(): SagaIterator {
  const { result, error }: RequestResult<IApplication[], RequestError> = yield call(ApiManager.integrationsApi.fetch);

  if (error) {
    yield put(ApplicationsActions.fetchCollectionFailure({ error: error?.message || 'Failed to fetch applications' }));
    yield put(
      ToastsActions.createToast({
        content: getToastContent(ToastContent.INSTALLED_APPLICATIONS_FETCH_ERROR),
        kind: ToastVariant.Error,
      }),
    );
  } else {
    const applications = result || [];
    yield put(ApplicationsActions.fetchCollectionSuccess({ values: applications }));
  }
}

function* fetchApplicationsSuccess(): SagaIterator {
  const installedAppsByIds: IAppsMap = yield select(applicationsSelector);
  const config = getConfig();
  const hasInstalledChatAnalyser = !!installedAppsByIds[config.chatsAnalyserAppId];

  if (hasInstalledChatAnalyser) {
    yield put(IntegrationLicensePropertiesActions.fetchForNamespace({ namespace: config.chatsAnalyserNamespace }));
  }
}

function* fetchPrivateApplications(): SagaIterator {
  const { integrationsApi } = ApiManager;
  const { result, error }: RequestResult<IApplication[]> = yield call(integrationsApi.fetchPrivateApps);

  if (!error) {
    const privateApplications = result || [];
    yield put(ApplicationsActions.fetchPrivateApplicationsSuccess(privateApplications));
  } else {
    yield put(ApplicationsActions.fetchPrivateApplicationsFailure(error));
  }
}

function* fetchInReviewApplications(): SagaIterator {
  const { integrationsApi } = ApiManager;
  const { result, error }: RequestResult<IApplication[]> = yield call(integrationsApi.fetchInReviewApps);

  if (!error) {
    const inReviewApplications = result || [];
    yield put(ApplicationsActions.fetchInReviewApplicationsSuccess(inReviewApplications));
  } else {
    yield put(ApplicationsActions.fetchInReviewApplicationsFailure(error));
  }
}

function* refreshInstalledApplicationsData(
  action: IActionWithPayload<string, RefreshInstalledApplicationsData>,
): SagaIterator {
  const { notifyMarketplace = true, installedAppId } = action.payload;
  const isMarketplaceActive = yield select(getHasVisitedMarketplace);

  // To minimize risk of race condition with the installed applications list update
  yield delay(250);

  if (installedAppId) {
    yield call(fetchInstalledAppsPoll, installedAppId);
  } else {
    yield put(ApplicationsActions.fetchCollection({}));
  }

  if (notifyMarketplace && isMarketplaceActive) {
    yield call(sendPostMessageToMarketplace, PostMessageEvent.RefreshInstalledApps);
  }
}

// Refetch the installed applications list until the app will be on installed apps list
function* fetchInstalledAppsPoll(installedAppId: string, retryCount = 0): SagaIterator {
  while (retryCount < MAX_POLLING_RETRIES) {
    yield put(ApplicationsActions.fetchCollection({}));

    // Wait for the installed applications list to be updated
    yield take(ApplicationsActionNames[CRUDAction.FETCH_COLLECTION][RequestAction.SUCCESS]);

    const installedAppsByIds: IAppsMap = yield select(applicationsSelector);
    const isInstalled = !!installedAppsByIds[installedAppId];

    if (!isInstalled) {
      yield delay(150);
      yield call(fetchInstalledAppsPoll, installedAppId, retryCount + 1);
    }

    return;
  }
}

function* addApplicationSelected(action: IActionWithPayload<string, string>): SagaIterator {
  const { payload } = action;
  const selectedApplications: string[] = yield select(getInitialSelectedApplicationIds);
  const uniqueSelectedApplications = new Set([payload, ...selectedApplications]);
  const unselectedApplications: string[] = yield select(getInitialUnselectedApplicationIds);

  yield put(
    ApplicationsFeaturesActions.saveApplicationsSelected({
      selectedApplications: [...uniqueSelectedApplications],
      unselectedApplications,
    }),
  );
}

function* addCartApplicationsSelected(action: IActionWithPayload<string, string[]>): SagaIterator {
  const { payload } = action;
  const cartProductsIds = [...payload].sort((a, b) => a.localeCompare(b));
  const selectedApplications: string[] = yield select(getInitialSelectedApplicationIds);
  const uniqueSelectedApplications = new Set([...cartProductsIds, ...selectedApplications]);
  const unselectedApplications: string[] = yield select(getInitialUnselectedApplicationIds);

  yield put(
    ApplicationsFeaturesActions.saveApplicationsSelected({
      selectedApplications: [...uniqueSelectedApplications],
      unselectedApplications,
    }),
  );
}

export function* applicationsSagas(): SagaIterator {
  yield takeLatest(ApplicationsActionNames[CRUDAction.FETCH_COLLECTION][RequestAction.REQUEST], fetchApplications);
  yield takeEvery(
    ApplicationsActionNames[CRUDAction.FETCH_COLLECTION][RequestAction.SUCCESS],
    fetchApplicationsSuccess,
  );
  yield takeEvery(ApplicationsActionNames.FETCH_PRIVATE_APPLICATIONS, fetchPrivateApplications);
  yield takeEvery(ApplicationsActionNames.FETCH_IN_REVIEW_APPLICATIONS, fetchInReviewApplications);
  yield takeEvery(ApplicationsActionNames.REFRESH_INSTALLED_APPLICATIONS_DATA, refreshInstalledApplicationsData);
  yield takeEvery(ApplicationsFeaturesActionNames.ADD_APPLICATION_SELECTED, addApplicationSelected);
  yield takeEvery(ApplicationsFeaturesActionNames.ADD_CART_APPLICATIONS_SELECTED, addCartApplicationsSelected);
}
