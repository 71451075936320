import { type FC, type ReactEventHandler } from 'react';

import { Error as ErrorIcon } from '@livechat/design-system-icons';
import { Button, Text } from '@livechat/design-system-react-components';
import * as Sentry from '@sentry/browser';
import { useLocation } from 'react-router-dom';

import { EventPlace } from 'helpers/analytics';
import { redirectTo } from 'helpers/redirect-to';
import { getHomePage } from 'helpers/routing';
import { parseQueryParams } from 'helpers/url';
import { useEffectOnce } from 'hooks/use-effect-once';
import { FullscreenError } from 'routes/error-pages/components/FullscreenError/FullscreenError';
import { openChatWidget } from 'services/chat-widget';
import { trackEvent } from 'services/event-tracking';

import {
  DEFAULT_ERROR_MESSAGE,
  OPEN_CHAT_WIDGET_BUTTON_TEXT,
  OPEN_CHAT_WIDGET_EVENT_NAME,
  TEXT,
  TITLE,
  TRY_AGAIN_BUTTON_TEXT,
} from './constants';

import * as styles from './styles';

/**
 * Don't lazy-load this component. We want it to be available even when user has network issues.
 */
const StartupError: FC = () => {
  const { search } = useLocation();
  const queryParams = parseQueryParams(search);
  const errorMessage = typeof queryParams.error === 'string' ? queryParams.error : DEFAULT_ERROR_MESSAGE;

  const handleOpenWidget: ReactEventHandler = (event) => {
    event.preventDefault();
    openChatWidget();
    trackEvent(OPEN_CHAT_WIDGET_EVENT_NAME, EventPlace.StartupError);
  };

  useEffectOnce(() => {
    Sentry.captureException(new Error(`${EventPlace.StartupError}, error message = ${errorMessage}`));
  });

  return (
    <FullscreenError
      title={TITLE}
      iconSource={ErrorIcon}
      actionButtons={
        <>
          <Button kind="secondary" onClick={handleOpenWidget}>
            {OPEN_CHAT_WIDGET_BUTTON_TEXT}
          </Button>
          <Button
            kind="primary"
            onClick={() => {
              // redirect with refresh to start over
              redirectTo(getHomePage());
            }}
          >
            {TRY_AGAIN_BUTTON_TEXT}
          </Button>
        </>
      }
    >
      <div className={styles.contentWrapper}>
        <Text className={styles.content}>{TEXT}</Text>
      </div>
    </FullscreenError>
  );
};

export default StartupError;
