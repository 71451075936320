interface WithRequestId {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  request_id?: string;
}

interface WithRequest {
  request?: unknown;
}

interface WithLocal {
  local?: WithRequest;
}

function hasNonObjectConstructor(obj: unknown): boolean {
  return !(obj instanceof Object) || obj.constructor.name !== 'Object';
}

function parseAdvancedObject(obj: unknown): { constr: string } {
  return {
    constr: (obj as { constructor?: { name: string } }).constructor?.name ?? 'Unknown',
  };
}

export function errorToString(error: unknown): string {
  if (typeof error === 'string') {
    return `A string error: ${error}`;
  }

  if (error === undefined) {
    return 'An undefined error';
  }

  if (error === null) {
    return 'A null error';
  }

  if (error instanceof Error) {
    return `Error object: ${error.name}: ${error.message}`;
  }

  if (Array.isArray(error)) {
    return JSON.stringify(error);
  }

  if (hasNonObjectConstructor(error)) {
    return JSON.stringify(parseAdvancedObject(error));
  }

  const { request_id: requestId, ...errorWithoutRequestId } = error as WithRequestId;

  const { local } = errorWithoutRequestId as WithLocal;

  if (local) {
    if (hasNonObjectConstructor(local)) {
      errorWithoutRequestId['local'] = parseAdvancedObject(local);
    } else {
      const { request } = local;
      if (request && hasNonObjectConstructor(request)) {
        errorWithoutRequestId['local'] = { ...local, request: parseAdvancedObject(request) };
      }
    }
  }

  const { request } = errorWithoutRequestId as WithRequest;

  if (request && hasNonObjectConstructor(request)) {
    errorWithoutRequestId['request'] = parseAdvancedObject(request);
  }

  return JSON.stringify(errorWithoutRequestId);
}
