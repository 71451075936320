import { type SagaIterator } from 'redux-saga';
import { call, cancel, delay, put, select, takeEvery, takeLatest } from 'redux-saga/effects';

import { TopBarNotificationType } from 'constants/notifications';
import { Section } from 'constants/section';
import { ToastContent } from 'constants/toasts';
import { EventPlace, trackGTMEvent } from 'helpers/analytics';
import { markOnboardingStepComplete } from 'helpers/product-onboarding';
import { getToastContent } from 'helpers/toast';
import type { RequestResult } from 'interfaces/api/client';
import { ProductOnboardingStep } from 'interfaces/entities/product-onboarding';
import { fetchWebsiteLastActivity } from 'services/activity/fetch-website-last-activity';
import { getLastActivityInDays } from 'services/activity/website-last-activity';
import { ApiManager } from 'services/api/api-manager';
import { trackEvent } from 'services/event-tracking';
import { LocalStorageKey, getItem } from 'services/local-storage';
import { selectIsNewOnboardingChecklistEnabled } from 'store/entities/experiments/selectors';
import type { RequestFailure } from 'store/entities/interfaces';
import { getHadChatsRecently, getIsCodeInstalled } from 'store/features/code-installation/selectors';

import { NotificationsBarActions } from '../notifications-bar/actions';
import { getIsOnSection } from '../routing/selectors';
import { SessionActionNames } from '../session/actions';
import { getIsOnTrial } from '../session/selectors';
import { ToastsActions } from '../toasts/actions';
import { ToastVariant } from '../toasts/interfaces';

import { CodeInstallationActions, CodeInstallationActionsNames } from './actions';
import { deserializeChannelsActivity, type IChannelActivityData } from './serializers';

function* initializeSaga(): SagaIterator {
  const isCodeInstalled = yield select(getIsCodeInstalled);
  const hadChatsRecently = yield select(getHadChatsRecently);

  if (!isCodeInstalled || !hadChatsRecently) {
    yield put(CodeInstallationActions.scanForCode());
  }
}

function* scanForCodeSaga(): SagaIterator {
  const hasCodeInstalled: boolean = yield select(getIsCodeInstalled);
  const hadChatsRecently: boolean = yield select(getHadChatsRecently);

  if (hasCodeInstalled && hadChatsRecently) {
    yield put(CodeInstallationActions.codeFound());
    yield cancel();
  }

  while (true) {
    yield call(handleCodeScan, hasCodeInstalled);
    yield call(handleDelay);
  }
}

function* handleCodeScan(hasCodeInstalled: boolean): SagaIterator {
  yield call(fetchWebsiteLastActivity);
  const daysSinceLastActivity = getLastActivityInDays();
  if (daysSinceLastActivity !== undefined) {
    const hasRecentActivity = Number.isInteger(daysSinceLastActivity) && daysSinceLastActivity >= 0;
    const codeNotInstalledMocked = getItem<boolean>(LocalStorageKey.MockCodeNotInstalled);
    const isCodeFound = daysSinceLastActivity < 3;
    if (hasRecentActivity && isCodeFound && !codeNotInstalledMocked) {
      const activityAction = hasCodeInstalled
        ? CodeInstallationActions.activityFound
        : CodeInstallationActions.codeFound;

      yield put(activityAction());
      yield cancel();
    }
  }
}

function* handleDelay(): SagaIterator {
  const isOnboardingSection = yield select(getIsOnSection, Section.Onboarding, false);
  const isWelcomeSection = yield select(getIsOnSection, Section.Welcome, false);
  const isHomeSection = yield select(getIsOnSection, Section.HomePage, false);
  const isInstallCodeSection = yield select(getIsOnSection, Section.InstallCode, false);
  const inNewChecklistEnabled = yield select(selectIsNewOnboardingChecklistEnabled);
  const isOnboarding =
    isOnboardingSection || isInstallCodeSection || (isHomeSection && inNewChecklistEnabled) || isWelcomeSection;
  const delayValue = isOnboarding ? 5000 : 60000;
  yield delay(delayValue);
}

function* checkCodeInstallationSaga(): SagaIterator {
  const hasCodeInstalled = yield select(getIsCodeInstalled);
  yield call(fetchWebsiteLastActivity);
  const daysSinceLastActivity = getLastActivityInDays();
  if (daysSinceLastActivity !== undefined) {
    const hasRecentActivity = Number.isInteger(daysSinceLastActivity) && daysSinceLastActivity >= 0;
    const codeNotInstalledMocked = getItem<boolean>(LocalStorageKey.MockCodeNotInstalled);

    const isCodeFound = daysSinceLastActivity < 3;
    if (hasRecentActivity && isCodeFound && !codeNotInstalledMocked) {
      const activityAction = hasCodeInstalled
        ? CodeInstallationActions.activityFound
        : CodeInstallationActions.codeFound;

      yield put(activityAction());
      yield put(
        ToastsActions.createToast({
          content: getToastContent(ToastContent.CODE_INSTALLATION_SUCCESS),
          kind: ToastVariant.Success,
        }),
      );
      yield cancel();

      return;
    }
  }

  yield put(
    ToastsActions.createToast({
      content: getToastContent(ToastContent.CODE_INSTALLATION_FAILURE),
      kind: ToastVariant.Error,
    }),
  );
}

function* fetchChannelsActivity(): SagaIterator {
  const API = ApiManager.channelsActivityApi;
  const { result, error }: RequestResult<IChannelActivityData[], RequestFailure> = yield call(API.fetch);

  if (error) {
    yield put(CodeInstallationActions.fetchChannelsActivityFailure(error));
    yield cancel();
  }

  if (result) {
    const activity = deserializeChannelsActivity(result);
    yield put(CodeInstallationActions.fetchChannelsActivitySuccess(activity));
  }
}

function* codeFoundSaga(): SagaIterator {
  trackEvent('Tracking code found on website', EventPlace.Installation);

  if (yield select(getIsOnTrial)) {
    yield call(trackGTMEvent, 'codeDetected', {
      codeDetectedValue: true,
    });
  }

  yield call(window.LiveChatWidget.call, 'update_session_variables', {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    og_cv_code_installed: true,
  });

  yield put(NotificationsBarActions.hideNotificationsBar(TopBarNotificationType.InstallCode));
  yield put(CodeInstallationActions.fetchChannelsActivity());

  yield call(markOnboardingStepComplete, ProductOnboardingStep.CodeInstalled);
}

function* sendGTMEvent(): SagaIterator {
  const hasActivity: ReturnType<typeof getIsCodeInstalled> = yield select(getIsCodeInstalled);
  const isOnTrialValue: ReturnType<typeof getIsOnTrial> = yield select(getIsOnTrial);

  if (isOnTrialValue) {
    yield call(trackGTMEvent, 'codeDetected', {
      codeDetectedValue: hasActivity,
    });
  }
}

export function* codeInstallationSaga(): SagaIterator {
  yield takeLatest('APP_READY', initializeSaga);
  yield takeEvery(CodeInstallationActionsNames.SCAN_FOR_CODE, scanForCodeSaga);
  yield takeEvery(CodeInstallationActionsNames.CODE_FOUND, codeFoundSaga);
  yield takeEvery(CodeInstallationActionsNames.FETCH_CHANNELS_ACTIVITY, fetchChannelsActivity);
  yield takeEvery(SessionActionNames.SAVE_CURRENT_LICENSE, sendGTMEvent);
  yield takeEvery(CodeInstallationActionsNames.CHECK_CODE_INSTALLATION, checkCodeInstallationSaga);
}
