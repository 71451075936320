import { useEffect, type FC, type MouseEvent } from 'react';

import { cx } from '@emotion/css';
import { Close } from '@livechat/design-system-icons';
import { Avatar, Text, Button, Icon } from '@livechat/design-system-react-components';
import { useDispatch, useSelector } from 'react-redux';

import { COPILOT_LOGO } from 'constants/copilot';
import { CopilotTrackEvent } from 'constants/copilot-event';
import { EventPlace } from 'helpers/analytics';
import { getVisibleNotifications } from 'helpers/notification-bar';
import { trackEvent } from 'services/event-tracking';
import { isDarkModeEnabled } from 'store/features/agent-custom-properties/selectors';
import { getNotifications } from 'store/features/notifications-bar/selectors';
import { CopilotViewActions } from 'store/views/copilot/actions';
import {
  getIsCopilotModalOpened,
  getIsCopilotNewMessageToastVisible,
  getCopilotUndreadMessageCount,
} from 'store/views/copilot/selectors';

import { COPILOT_MESSAGE_TOAST_DURATION } from './constants';

import * as styles from './styles';

export const CopilotNewMessageToast: FC = () => {
  const dispatch = useDispatch();
  const isCopilotNewMessageToastVisible = useSelector(getIsCopilotNewMessageToastVisible);
  const unreadCopilotMessages = useSelector(getCopilotUndreadMessageCount);
  const isCopilotModalOpened = useSelector(getIsCopilotModalOpened);
  const isDarkMode = useSelector(isDarkModeEnabled);
  const notifications = useSelector(getNotifications);
  const hasVisibleNotifications = getVisibleNotifications(notifications).length > 0;

  const handleCloseTooltip = (event: MouseEvent): void => {
    event.stopPropagation();
    dispatch(CopilotViewActions.hideMessageToast());
  };

  const handleShowCopilotModal = (): void => {
    dispatch(CopilotViewActions.showModal());
  };

  useEffect(() => {
    if (unreadCopilotMessages > 0) {
      dispatch(CopilotViewActions.showMessageToast());

      const timer = setTimeout(() => {
        dispatch(CopilotViewActions.hideMessageToast());
      }, COPILOT_MESSAGE_TOAST_DURATION);

      return () => clearTimeout(timer);
    }
  }, [unreadCopilotMessages, dispatch]);

  useEffect(() => {
    if (isCopilotModalOpened) {
      dispatch(CopilotViewActions.resetCopilotUnreadMessagesCount());
    }
  }, [isCopilotModalOpened, dispatch]);

  useEffect(() => {
    if (isCopilotNewMessageToastVisible) {
      trackEvent(CopilotTrackEvent.NewMessage, EventPlace.One);
    }
  }, [isCopilotNewMessageToastVisible]);

  if (
    !isCopilotNewMessageToastVisible ||
    unreadCopilotMessages === 0 ||
    (unreadCopilotMessages > 0 && isCopilotModalOpened)
  ) {
    return null;
  }

  return (
    <div
      className={cx(
        styles.toastContainer(isDarkMode, hasVisibleNotifications),
        { [styles.copilotAnimation(isDarkMode)]: isCopilotNewMessageToastVisible },
        'lc-dark-theme',
      )}
    >
      <div className={styles.toastWrapper} onClick={handleShowCopilotModal}>
        <div>
          <Avatar size="xsmall" type="image" src={COPILOT_LOGO} className={styles.copilotLogo} />
          <Text as="h2">New message</Text>
        </div>
        <div>
          <Button
            kind="plain"
            size="compact"
            onClick={handleCloseTooltip}
            icon={<Icon className={styles.icon} source={Close} />}
          />
        </div>
      </div>
    </div>
  );
};
