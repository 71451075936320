// @ts-strict-ignore
import type { CustomSounds, CustomSound } from 'interfaces/sound';
import { trackEvent } from 'services/event-tracking';

interface IElectronApiCallOptions {
  ignoreTimeout: boolean;
}

export interface IElectronApi {
  initiated: boolean;
  platform: 'win32' | 'darwin';
  customSounds?: CustomSounds; // TO REMOVE AFTER FULL ADOPTION -> contextBridge made this immutable
  getCustomSounds?(): CustomSounds;
  updateBadge(count: number): void;
  setEventTracker?(tracker): void;
  call<TPayload, TResult>(
    actionName: string,
    payload?: TPayload,
    options?: Partial<IElectronApiCallOptions>,
  ): Promise<TResult>;
}

const { LCSmartClient } = window;

export const initialized = !!LCSmartClient;

if (initialized && LCSmartClient.setEventTracker) {
  LCSmartClient.setEventTracker(trackEvent);
}

export function updateBadge(count: number): void {
  if (!initialized) {
    return;
  }

  LCSmartClient.updateBadge(count);
}

function getSmartClientCustomSounds(): CustomSounds | null {
  return LCSmartClient.customSounds || (LCSmartClient.getCustomSounds && LCSmartClient.getCustomSounds());
}

export function areCustomSoundsSupported(): boolean {
  if (!initialized) {
    return false;
  }

  return !!getSmartClientCustomSounds();
}

export function getCustomSounds(): CustomSounds {
  if (!areCustomSoundsSupported()) {
    return {};
  }

  return getSmartClientCustomSounds() || {};
}

function getCustomSoundByName(soundName: string): CustomSound {
  const customSounds = getCustomSounds();

  return customSounds[soundName] || null;
}

export function getCustomSoundName(soundName: string): string {
  const customSound = getCustomSoundByName(soundName);

  return customSound ? customSound.name : null;
}

export function getHasDirtyStateChanged(skipError = true): Promise<boolean> {
  if (!areCustomSoundsSupported()) {
    if (skipError) {
      return Promise.resolve(false);
    }

    return Promise.reject(new Error("Smart Client is not initialized or doesn't support the custom sounds"));
  }

  return LCSmartClient.call('hasDirtyStateChanged', null, { ignoreTimeout: true });
}

export function createCustomSoundsDirtyState(skipError = true): Promise<void> {
  if (!areCustomSoundsSupported()) {
    if (skipError) {
      return Promise.resolve();
    }

    return Promise.reject(new Error("Smart Client is not initialized or doesn't support the custom sounds"));
  }

  return LCSmartClient.call('createCustomSoundsDirtyState');
}

export function saveCustomSoundsDirtyState(skipError = true): Promise<void> {
  if (!areCustomSoundsSupported()) {
    if (skipError) {
      return Promise.resolve();
    }

    return Promise.reject(new Error("Smart Client is not initialized or doesn't support the custom sounds"));
  }

  return LCSmartClient.call('saveCustomSoundsDirtyState');
}

export function discardCustomSoundsDirtyState(skipError = true): Promise<void> {
  if (!areCustomSoundsSupported()) {
    if (skipError) {
      return Promise.resolve();
    }

    return Promise.reject(new Error("Smart Client is not initialized or doesn't support the custom sounds"));
  }

  return LCSmartClient.call('discardCustomSoundsDirtyState');
}

export function selectCustomSoundFile(soundName: string): Promise<string> {
  if (!areCustomSoundsSupported()) {
    return Promise.reject(new Error("Smart Client is not initialized or doesn't support the custom sounds"));
  }

  return LCSmartClient.call('selectCustomSound', soundName, { ignoreTimeout: true });
}

export function setCustomSound(soundName: string, customSound: CustomSound): Promise<void> {
  if (!areCustomSoundsSupported()) {
    return Promise.reject(new Error("Smart Client is not initialized or doesn't support the custom sounds"));
  }

  return LCSmartClient.call('updateCustomSoundsDirtyState', { soundName, customSound }, { ignoreTimeout: true });
}
