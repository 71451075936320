import { createMlGatewayApiClient } from '../client';
import { type GenericMlGatewayError } from '../types';

import { type FetchTagSuggestionsRequest, type FetchTagSuggestionsResponse } from './types';

/**
 * Client is now an arrow function to avoid creating a client with missing region.
 * Region is saved in configuration during startup process, not at the time of invoking the main code line during imports.
 * todo: Remove the arrow function when regions are no longer present in the configuration object.
 */
const client = () => createMlGatewayApiClient();

export const tagSuggestionsClient = {
  fetchTagSuggestions: async (data: FetchTagSuggestionsRequest) => {
    return client().post<FetchTagSuggestionsResponse, GenericMlGatewayError>('predict/tag-suggestions', {
      json: data,
    });
  },
};
