import debug from 'debug';
import throttle from 'lodash.throttle';
import mitt from 'mitt';

import { DebugLogsNamespace } from 'constants/debug-logs-namespace';
import { shuffleArray } from 'helpers/sort';

import {
  DEFAULT_CHECK_URL_TIMEOUT,
  DEFAULT_INTERNET_CHECK_INTERVAL,
  DEFAULT_INTERNET_CHECK_URLS,
  DEFAULT_OFFLINE_THROTTLE_INTERVAL,
  DEFAULT_SERVICES_URLS,
  INITIAL_RETRY_INTERVAL,
  MAX_RETRY_INTERVAL,
  NETWORK_CHECK_INTERVAL,
} from './constants';
import { checkUrl } from './helpers';
import {
  ConnectivityStatusEvent,
  InternetStatus,
  NetworkStatus,
  ServiceStatus,
  type ConnectivityStatus,
  type ConnectivityStatusInstance,
  type CreateConnectivityStatusOptions,
} from './types';

const log = debug(DebugLogsNamespace.AppConnectivityStatus);

function create(options: CreateConnectivityStatusOptions = {}): ConnectivityStatusInstance {
  const emitter = mitt();

  const effectiveOptions = {
    ...options,
    servicesURLs: options.servicesURLs ?? DEFAULT_SERVICES_URLS,
    internetCheckInterval: options.internetCheckInterval ?? DEFAULT_INTERNET_CHECK_INTERVAL,
    internetCheckURLs: options.internetCheckURLs ?? DEFAULT_INTERNET_CHECK_URLS,
    checkURLTimeout: options.checkURLTimeout ?? DEFAULT_CHECK_URL_TIMEOUT,
    offlineThrottleInterval: options.offlineThrottleInterval ?? DEFAULT_OFFLINE_THROTTLE_INTERVAL,
  };

  let networkCheckIntervalId: NodeJS.Timeout | null = null;
  let internetCheckIntervalId: NodeJS.Timeout | null = null;
  let retryIntervalId: NodeJS.Timeout | null = null;
  let isInitialized = false;
  let currentRetryInterval = INITIAL_RETRY_INTERVAL;

  const connectivityStatus: ConnectivityStatus = {
    network: NetworkStatus.ONLINE,
    internet: InternetStatus.CONNECTED,
    services: Object.fromEntries(effectiveOptions.servicesURLs.map((service) => [service, ServiceStatus.REACHABLE])),
    throttledNetworkStatus: NetworkStatus.ONLINE,
    throttledInternetStatus: InternetStatus.CONNECTED,
  };

  function emitOnlineStatusChanged(): void {
    log('Emitting online status changed:', getIsOnline());
    emitter.emit(ConnectivityStatusEvent.ONLINE_STATUS_CHANGED, getIsOnline());
  }

  function emitThrottledIsOfflineChanged(): void {
    log('Emitting throttled is offline changed:', getThrottledIsOffline());
    emitter.emit(ConnectivityStatusEvent.THROTTLED_IS_OFFLINE_CHANGED, getThrottledIsOffline());
  }

  // Network Status

  function getNetworkStatus(): NetworkStatus {
    return connectivityStatus.network;
  }

  function getThrottledNetworkStatus(): NetworkStatus {
    return connectivityStatus.throttledNetworkStatus;
  }

  function setNetworkStatus(status: NetworkStatus): void {
    const currentIsOnline = getIsOnline();

    if (connectivityStatus.network !== status) {
      log('Network status changed from %s to %s', connectivityStatus.network, status);
      connectivityStatus.network = status;
      emitter.emit(ConnectivityStatusEvent.NETWORK_STATUS_CHANGED, status);

      if (currentIsOnline !== getIsOnline()) {
        emitOnlineStatusChanged();
      }

      if (status === NetworkStatus.OFFLINE && effectiveOptions.offlineThrottleInterval > 0) {
        throttledSetThrottledNetworkStatus(status);
      } else {
        throttledSetThrottledNetworkStatus.cancel();
        setThrottledNetworkStatus(status);
      }
    }
  }

  const throttledSetThrottledNetworkStatus = throttle(
    (status: NetworkStatus) => {
      log('Setting throttled network status:', status);
      setThrottledNetworkStatus(status);
    },
    effectiveOptions.offlineThrottleInterval,
    { leading: false, trailing: true },
  );

  function setThrottledNetworkStatus(status: NetworkStatus): void {
    const currentThrottledIsOffline = getThrottledIsOffline();

    if (connectivityStatus.throttledNetworkStatus !== status) {
      log('Throttled network status changed from %s to %s', connectivityStatus.throttledNetworkStatus, status);
      connectivityStatus.throttledNetworkStatus = status;
      emitter.emit(ConnectivityStatusEvent.THROTTLED_NETWORK_STATUS_CHANGED, status);

      if (currentThrottledIsOffline !== getThrottledIsOffline()) {
        emitThrottledIsOfflineChanged();
      }
    }
  }

  // Internet Status

  function getInternetStatus(): InternetStatus {
    return connectivityStatus.internet;
  }

  function getThrottledInternetStatus(): InternetStatus {
    return connectivityStatus.throttledInternetStatus;
  }

  function setInternetStatus(status: InternetStatus): void {
    const currentIsOnline = getIsOnline();

    if (connectivityStatus.internet !== status) {
      log('Internet status changed from %s to %s', connectivityStatus.internet, status);
      connectivityStatus.internet = status;
      emitter.emit(ConnectivityStatusEvent.INTERNET_STATUS_CHANGED, status);

      if (currentIsOnline !== getIsOnline()) {
        emitOnlineStatusChanged();
      }

      if (status === InternetStatus.DISCONNECTED && effectiveOptions.offlineThrottleInterval > 0) {
        throttledSetThrottledInternetStatus(status);
      } else {
        throttledSetThrottledInternetStatus.cancel();
        setThrottledInternetStatus(status);
      }
    }
  }

  const throttledSetThrottledInternetStatus = throttle(
    (status: InternetStatus) => {
      log('Setting throttled internet status:', status);
      setThrottledInternetStatus(status);
    },
    effectiveOptions.offlineThrottleInterval,
    { leading: false, trailing: true },
  );

  function setThrottledInternetStatus(status: InternetStatus): void {
    const currentThrottledIsOffline = getThrottledIsOffline();

    if (connectivityStatus.throttledInternetStatus !== status) {
      log('Throttled internet status changed from %s to %s', connectivityStatus.throttledInternetStatus, status);
      connectivityStatus.throttledInternetStatus = status;
      emitter.emit(ConnectivityStatusEvent.THROTTLED_INTERNET_STATUS_CHANGED, status);

      if (currentThrottledIsOffline !== getThrottledIsOffline()) {
        emitThrottledIsOfflineChanged();
      }
    }
  }

  async function checkInternetStatus(): Promise<InternetStatus> {
    if (connectivityStatus.network === NetworkStatus.OFFLINE) {
      log('Network is offline; returning InternetStatus.DISCONNECTED');

      return InternetStatus.DISCONNECTED;
    }

    log('Checking internet status...');
    const shuffledUrls = shuffleArray(effectiveOptions.internetCheckURLs);
    for (const url of shuffledUrls) {
      const serviceStatus = await checkUrl(url, effectiveOptions.checkURLTimeout);
      log('Checked URL %s: %s', url, serviceStatus);
      if (serviceStatus === ServiceStatus.REACHABLE) {
        return InternetStatus.CONNECTED;
      }
    }

    log('All URLs unreachable; returning InternetStatus.DISCONNECTED');

    return InternetStatus.DISCONNECTED;
  }

  function checkAndUpdateNetworkStatus(): void {
    log('Checking and updating network status...');
    try {
      const actualStatus = navigator.onLine ? NetworkStatus.ONLINE : NetworkStatus.OFFLINE;
      if (actualStatus !== connectivityStatus.network) {
        setNetworkStatus(actualStatus);
      }
    } catch (error) {
      log('Error checking network status:', error);
    }
  }

  async function checkAndUpdateInternetStatus(useBackoff: boolean = false): Promise<void> {
    log('Checking and updating internet status...');
    const status = await checkInternetStatus();
    setInternetStatus(status);
    if (status === InternetStatus.DISCONNECTED) {
      if (useBackoff) {
        currentRetryInterval = Math.min(currentRetryInterval * 2, MAX_RETRY_INTERVAL);
        log('Using backoff; current retry interval is %d ms', currentRetryInterval);
      }
      startRetryInterval();
    } else {
      stopRetryInterval();
    }
  }

  function startRetryInterval(): void {
    log('Starting retry interval...');
    if (retryIntervalId !== null) {
      clearInterval(retryIntervalId);
    }
    retryIntervalId = setInterval(() => void checkAndUpdateInternetStatus(true), currentRetryInterval);
  }

  function stopRetryInterval(): void {
    log('Stopping retry interval...');
    if (retryIntervalId !== null) {
      clearInterval(retryIntervalId);
      retryIntervalId = null;
    }
    currentRetryInterval = INITIAL_RETRY_INTERVAL;
  }

  // Services Status

  function getServiceStatus(service: string): ServiceStatus {
    return connectivityStatus.services[service];
  }

  function getServiceStatuses(): Record<string, ServiceStatus> {
    return connectivityStatus.services;
  }

  function setServiceStatus(service: string, status: ServiceStatus): void {
    if (connectivityStatus.services[service] !== status) {
      log('Service status for %s changed from %s to %s', service, connectivityStatus.services[service], status);
      connectivityStatus.services[service] = status;
      emitter.emit(ConnectivityStatusEvent.SERVICE_STATUS_CHANGED, { service, status });
    }
  }

  async function checkServicesStatus(): Promise<ConnectivityStatus['services']> {
    log('Checking services status...');
    const statuses: ConnectivityStatus['services'] = {};

    for (const service of effectiveOptions.servicesURLs) {
      statuses[service] = await checkUrl(service, effectiveOptions.checkURLTimeout);
      log('Checked service %s: %s', service, statuses[service]);
    }

    return statuses;
  }

  async function checkAndUpdateServicesStatus(): Promise<void> {
    log('Checking and updating services status...');
    const statuses = await checkServicesStatus();
    for (const [service, status] of Object.entries(statuses)) {
      setServiceStatus(service, status);
    }
  }

  // Helpers

  function getIsOnline(): boolean {
    return (
      connectivityStatus.network === NetworkStatus.ONLINE && connectivityStatus.internet === InternetStatus.CONNECTED
    );
  }

  function isOffline(): boolean {
    return (
      connectivityStatus.network === NetworkStatus.OFFLINE ||
      connectivityStatus.internet === InternetStatus.DISCONNECTED
    );
  }

  function getThrottledIsOffline(): boolean {
    return (
      connectivityStatus.throttledNetworkStatus === NetworkStatus.OFFLINE ||
      connectivityStatus.throttledInternetStatus === InternetStatus.DISCONNECTED
    );
  }

  function handleOnline(): void {
    log('Handling online event...');
    setNetworkStatus(NetworkStatus.ONLINE);
    void checkAndUpdateInternetStatus();
    void checkAndUpdateServicesStatus();
  }

  function handleOffline(): void {
    log('Handling offline event…');
    setNetworkStatus(NetworkStatus.OFFLINE);
    setInternetStatus(InternetStatus.DISCONNECTED);
    Object.keys(connectivityStatus.services).forEach((service) => {
      setServiceStatus(service, ServiceStatus.UNREACHABLE);
    });

    startRetryInterval();
  }

  async function initialize(): Promise<void> {
    if (isInitialized) {
      log('Already initialized; skipping initialization.');

      return;
    }

    log('Initializing connectivity status…');
    isInitialized = true;
    checkAndUpdateNetworkStatus();
    await checkAndUpdateInternetStatus();
    await checkAndUpdateServicesStatus();

    window.addEventListener('online', handleOnline);
    window.addEventListener('offline', handleOffline);

    networkCheckIntervalId = setInterval(() => checkAndUpdateNetworkStatus(), NETWORK_CHECK_INTERVAL);

    internetCheckIntervalId = setInterval(
      () => void checkAndUpdateInternetStatus(),
      effectiveOptions.internetCheckInterval,
    );
  }

  function destroy(): void {
    log('Destroying connectivity status…');
    if (internetCheckIntervalId !== null) {
      clearInterval(internetCheckIntervalId);
    }
    if (networkCheckIntervalId !== null) {
      clearInterval(networkCheckIntervalId);
    }
    if (retryIntervalId !== null) {
      clearInterval(retryIntervalId);
    }
    window.removeEventListener('online', handleOnline);
    window.removeEventListener('offline', handleOffline);
    isInitialized = false;
  }

  function resetThrottledStatuses(): void {
    log('Resetting throttled statuses…');
    setThrottledNetworkStatus(connectivityStatus.network);
    setThrottledInternetStatus(connectivityStatus.internet);
  }

  return {
    getIsOnline,
    getIsOffline: isOffline,
    getThrottledIsOffline,
    getNetworkStatus,
    getThrottledNetworkStatus,
    getInternetStatus,
    getThrottledInternetStatus,
    getServiceStatus,
    getServiceStatuses,
    resetThrottledStatuses,
    subscribe: emitter.on,
    unsubscribe: emitter.off,
    initialize,
    destroy,
  };
}

export default { create };
