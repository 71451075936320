// @ts-strict-ignore
import { type SagaIterator } from 'redux-saga';
import { takeEvery, put, select, race, take, call } from 'redux-saga/effects';

import {
  CustomSegmentsSection,
  CustomSegmentEvent,
  DEFAULT_SEGMENTS_CONFIG,
  SEGMENTS_DEFAULT_SEGMENT_ID,
} from 'constants/custom-segment';
import { ToastContent } from 'constants/toasts';
import { enumToArray } from 'helpers/enum';
import { type KeyMap } from 'helpers/interface';
import { dasherize } from 'helpers/string';
import { getToastContent } from 'helpers/toast';
import type { CustomSegment } from 'interfaces/custom-segment';
import { ApiErrorMessagePart } from 'services/api/constants';
import { trackEvent } from 'services/event-tracking';
import { RequestAction } from 'store/entities/actions';
import { AgentCustomPropertiesActions, AGENT_CUSTOM_PROPERTIES } from 'store/features/agent-custom-properties/actions';
import {
  AgentCustomPropertyName,
  type IFetchAgentCustomPropertiesSuccessPayload,
} from 'store/features/agent-custom-properties/interfaces';
import { getCustomSegmentsUsageAllowed } from 'store/features/session/selectors';
import { ToastsActions } from 'store/features/toasts/actions';
import { ToastVariant } from 'store/features/toasts/interfaces';
import { type IActionWithPayload } from 'store/helper';
import { TrafficActions } from 'store/views/traffic/actions';
import { SegmentsActionsState } from 'store/views/traffic/interfaces';

import { CustomSegmentsActionsNames, CustomSegmentsActions } from './actions';
import { getCustomSegmentsPropertyName } from './computed';
import {
  getSectionSegmentsFromEntries,
  getSectionSegmentsSortedIdsFromEntries,
  buildSegmentsForTraffic,
} from './helpers/sagas';
import type {
  UpdateCustomSegmentLabelPayload,
  SectionCustomSegments,
  SaveCustomSegmentsPayload,
  UpdateCustomSegmnetsOrderPayload,
  DeleteCustomSegmentPayload,
  SaveCustomSegmentPayload,
  UpdateCustomSegmentPayload,
  DeleteCustomSegmentSuccessPayload,
  UpdateCustomSegmentFailurePayload,
  UpdateCustomSegmentLabelSuccessPayload,
} from './interfaces';
import {
  getCustomSegmentData,
  getSectionCustomSegments,
  getSectionCustomSegmentsData,
  getSectionSelectedSegmentId,
  getSectionCustomSegmentsIds,
} from './selectors';

const CUSTOM_SEGMENTS_SECTIONS = enumToArray(CustomSegmentsSection);

function* createCustomSegment(action: IActionWithPayload<string, SaveCustomSegmentPayload>): SagaIterator {
  const { segmentName, sectionName, valueToSave, eventPlace } = action.payload;
  const sectionSegmentsOrderIds = yield select(getSectionCustomSegmentsIds, sectionName);
  const newSegmentId = dasherize(segmentName);

  const newSegmentValue = {
    id: newSegmentId,
    label: segmentName,
    value: valueToSave,
  };

  const newSegmentIndex = sectionSegmentsOrderIds?.length;
  const newSegmentsOrderIds = [...(sectionSegmentsOrderIds ?? []), newSegmentId];
  const customSegmentPropertyName = getCustomSegmentsPropertyName(sectionName, newSegmentIndex);
  const customSegmentsOrderPropertyName = getCustomSegmentsPropertyName(
    sectionName,
    AgentCustomPropertyName.CustomSegmentOrder,
  );

  yield put(
    AgentCustomPropertiesActions.setAgentCustomProperty({
      [customSegmentPropertyName]: newSegmentValue,
    }),
  );

  const { success, failure } = yield race({
    success: take(AGENT_CUSTOM_PROPERTIES.SET_AGENT_CUSTOM_PROPERTY[RequestAction.SUCCESS]),
    failure: take(AGENT_CUSTOM_PROPERTIES.SET_AGENT_CUSTOM_PROPERTY[RequestAction.FAILURE]),
  });

  if (success) {
    const segmentFilterTypes = newSegmentValue.value.filters.map((filter) => Object.keys(filter)).flat();
    trackEvent(CustomSegmentEvent.SegmentAdded, eventPlace, {
      segment: newSegmentValue.id,
      segmentFilterTypes,
      segmentFilterOperator: newSegmentValue.value.operator,
    });

    yield put(
      AgentCustomPropertiesActions.setAgentCustomProperty({
        [customSegmentsOrderPropertyName]: newSegmentsOrderIds,
      }),
    );

    yield put(
      CustomSegmentsActions.createCustomSegmentSuccess({
        segmentName,
        sectionName,
        valueToSave,
        newSortedIds: newSegmentsOrderIds,
      }),
    );
  } else {
    const { error } = failure.payload;

    yield put(CustomSegmentsActions.createCustomSegmentFailure({ error }));
  }
}

function* createCustomSegmentSuccess(): SagaIterator {
  yield put(
    ToastsActions.createToast({
      content: getToastContent(ToastContent.CUSTOM_SEGMENT_SAVE_SUCCESS),
      kind: ToastVariant.Success,
    }),
  );
}

function* createCustomSegmentFailure(
  action: IActionWithPayload<string, UpdateCustomSegmentFailurePayload>,
): SagaIterator {
  const { error } = action.payload;
  const hasExceededMaxLength = error.includes(ApiErrorMessagePart.ExceededMaxStringLength);

  const toastContent = hasExceededMaxLength
    ? ToastContent.CUSTOM_SEGMENT_SAVE_FAILURE_TOO_COMPLEX_FILTERS
    : ToastContent.CUSTOM_SEGMENT_SAVE_FAILURE;

  yield put(
    ToastsActions.createToast({
      content: getToastContent(toastContent),
      kind: ToastVariant.Error,
    }),
  );
}

function* updateCustomSegment(action: IActionWithPayload<string, UpdateCustomSegmentPayload>): SagaIterator {
  const { segmentName, sectionName, valueToSave, eventPlace } = action.payload;
  const sectionSegmentsOrderIds: string[] = yield select(getSectionCustomSegmentsIds, sectionName);
  const newSegmentId = dasherize(segmentName);
  const sectionSelectedId = yield select(getSectionSelectedSegmentId, sectionName);
  const segmentIdToUpdateIndex = sectionSegmentsOrderIds.findIndex((id) => id === sectionSelectedId);

  const newSegmentValue = {
    id: newSegmentId,
    label: segmentName,
    value: valueToSave,
  };

  const newSegmentsOrderIds = sectionSegmentsOrderIds.map((id) => (id === sectionSelectedId ? newSegmentId : id));
  const customSegmentPropertyName = getCustomSegmentsPropertyName(sectionName, segmentIdToUpdateIndex);
  const customSegmentsOrderPropertyName = getCustomSegmentsPropertyName(
    sectionName,
    AgentCustomPropertyName.CustomSegmentOrder,
  );

  yield put(
    AgentCustomPropertiesActions.setAgentCustomProperty({
      [customSegmentPropertyName]: newSegmentValue,
    }),
  );

  const { success, failure } = yield race({
    success: take(AGENT_CUSTOM_PROPERTIES.SET_AGENT_CUSTOM_PROPERTY[RequestAction.SUCCESS]),
    failure: take(AGENT_CUSTOM_PROPERTIES.SET_AGENT_CUSTOM_PROPERTY[RequestAction.FAILURE]),
  });

  if (success) {
    const segmentFilterTypes = newSegmentValue.value.filters.map((filter) => Object.keys(filter)).flat();
    trackEvent(CustomSegmentEvent.SegmentChanged, eventPlace, {
      segment: newSegmentValue.id,
      segmentFilterTypes,
      segmentFilterOperator: newSegmentValue.value.operator,
    });

    yield put(
      AgentCustomPropertiesActions.setAgentCustomProperty({
        [customSegmentsOrderPropertyName]: newSegmentsOrderIds,
      }),
    );

    yield put(
      CustomSegmentsActions.updateCustomSegmentSuccess({
        segmentName,
        sectionName,
        valueToSave,
        newSortedIds: newSegmentsOrderIds,
      }),
    );
  } else {
    const { error } = failure.payload;

    yield put(
      CustomSegmentsActions.updateCustomSegmentFailure({
        error,
      }),
    );
  }
}

function* updateCustomSegmentSuccess(): SagaIterator {
  yield put(
    ToastsActions.createToast({
      content: getToastContent(ToastContent.CUSTOM_SEGMENT_SAVE_SUCCESS),
      kind: ToastVariant.Success,
    }),
  );
}

function* updateCustomSegmentFailure(
  action: IActionWithPayload<string, UpdateCustomSegmentFailurePayload>,
): SagaIterator {
  const { error } = action.payload;
  const hasExceededMaxLength = error.includes(ApiErrorMessagePart.ExceededMaxStringLength);

  const toastContent = hasExceededMaxLength
    ? ToastContent.CUSTOM_SEGMENT_SAVE_FAILURE_TOO_COMPLEX_FILTERS
    : ToastContent.CUSTOM_SEGMENT_SAVE_FAILURE;

  yield put(
    ToastsActions.createToast({
      content: getToastContent(toastContent),
      kind: ToastVariant.Error,
    }),
  );
}

function* saveCustomSegments(action: IActionWithPayload<string, SaveCustomSegmentsPayload>): SagaIterator {
  const { segments, sectionName } = action.payload;

  const segmentsToSave = Object.keys(segments).reduce((acc, segment, index) => {
    const customSegmentPropertyName = getCustomSegmentsPropertyName(sectionName, index);

    acc[customSegmentPropertyName] = segments[segment];

    return acc;
  }, {});

  const customSegmentsOrderPropertyName = getCustomSegmentsPropertyName(
    sectionName,
    AgentCustomPropertyName.CustomSegmentOrder,
  );

  const segmentsOrderToSave = {
    [customSegmentsOrderPropertyName]: Object.keys(segments),
  };

  yield put(
    AgentCustomPropertiesActions.setAgentCustomProperty({
      ...segmentsToSave,
      ...segmentsOrderToSave,
    }),
  );
}

function* updateCustomSegmentLabel(action: IActionWithPayload<string, UpdateCustomSegmentLabelPayload>): SagaIterator {
  const { name, segmentId, sectionName, eventPlace } = action.payload;
  const segmentToUpdate: CustomSegment = yield select(getCustomSegmentData, sectionName, segmentId);
  const sectionSegmentsOrderIds: string[] = yield select(getSectionCustomSegmentsIds, sectionName);
  const sectionSegments: SectionCustomSegments = yield select(getSectionCustomSegments, sectionName);
  const segmentToUpdateIndex = Object.keys(sectionSegments.data).findIndex((id) => id === segmentId);

  const newSegmentId = dasherize(name);
  const toUpdate = {
    ...segmentToUpdate,
    id: newSegmentId,
    label: name,
  };

  const newSegmentsOrderIds = sectionSegmentsOrderIds.map((id) => (id === segmentId ? newSegmentId : id));
  const customSegmentPropertyName = getCustomSegmentsPropertyName(sectionName, segmentToUpdateIndex);
  const customSegmentsOrderPropertyName = getCustomSegmentsPropertyName(
    sectionName,
    AgentCustomPropertyName.CustomSegmentOrder,
  );

  yield put(
    AgentCustomPropertiesActions.setAgentCustomProperty({
      [customSegmentPropertyName]: toUpdate,
      [customSegmentsOrderPropertyName]: newSegmentsOrderIds,
    }),
  );

  const { success }: { success: boolean; failure: boolean } = yield race({
    success: take(AGENT_CUSTOM_PROPERTIES.SET_AGENT_CUSTOM_PROPERTY[RequestAction.SUCCESS]),
    failure: take(AGENT_CUSTOM_PROPERTIES.SET_AGENT_CUSTOM_PROPERTY[RequestAction.FAILURE]),
  });

  if (success) {
    trackEvent(CustomSegmentEvent.SegmentLabelChanged, eventPlace, {
      segment: toUpdate.id,
      segmentLabel: toUpdate.label,
    });

    yield put(
      CustomSegmentsActions.updateCustomSegmentLabelSuccess({
        sectionName,
        segmentId,
        newSegmentId,
        newSegmentValues: toUpdate,
        newSortedIds: newSegmentsOrderIds,
      }),
    );
  } else {
    trackEvent(CustomSegmentEvent.SegmentLabelChangedError, eventPlace, {
      segment: toUpdate.id,
      segmentLabel: toUpdate.label,
    });

    yield put(CustomSegmentsActions.updateCustomSegmentLabelFailure({ sectionName }));
  }
}

function* updateCustomSegmentLabelSuccess(
  action: IActionWithPayload<string, UpdateCustomSegmentLabelSuccessPayload>,
): SagaIterator {
  const { sectionName, segmentId, newSegmentId } = action.payload;
  const selectedSegmentId: string = yield select(getSectionSelectedSegmentId, sectionName as string);

  if (segmentId === selectedSegmentId) {
    yield put(CustomSegmentsActions.selectCustomSegment({ segmentId: newSegmentId, sectionName }));
  }

  yield put(
    ToastsActions.createToast({
      content: getToastContent(ToastContent.CUSTOM_SEGMENT_LABEL_UPDATE_SUCCESS),
      kind: ToastVariant.Success,
    }),
  );
}

function* updateCustomSegmentLabelFailure(): SagaIterator {
  yield put(
    ToastsActions.createToast({
      content: getToastContent(ToastContent.CUSTOM_SEGMENT_LABEL_UPDATE_FAILURE),
      kind: ToastVariant.Error,
    }),
  );
}

function* updateCustomSegmentsOrder(
  action: IActionWithPayload<string, UpdateCustomSegmnetsOrderPayload>,
): SagaIterator {
  const { sectionName, currentIndex, newIndex, eventPlace } = action.payload;
  const sectionSegmentsOrderIds: string[] = yield select(getSectionCustomSegmentsIds, sectionName);

  const customSegmentsOrderPropertyName = getCustomSegmentsPropertyName(
    sectionName,
    AgentCustomPropertyName.CustomSegmentOrder,
  );

  yield put(
    AgentCustomPropertiesActions.setAgentCustomProperty({
      [customSegmentsOrderPropertyName]: sectionSegmentsOrderIds,
    }),
  );

  const { success }: { success: boolean; failure: boolean } = yield race({
    success: take(AGENT_CUSTOM_PROPERTIES.SET_AGENT_CUSTOM_PROPERTY[RequestAction.SUCCESS]),
    failure: take(AGENT_CUSTOM_PROPERTIES.SET_AGENT_CUSTOM_PROPERTY[RequestAction.FAILURE]),
  });

  if (success) {
    trackEvent(CustomSegmentEvent.OrderChanged, eventPlace);

    yield put(CustomSegmentsActions.updateCustomSegmentsOrderSuccess());
  } else {
    trackEvent(CustomSegmentEvent.OrderChangedError, eventPlace);

    yield put(
      CustomSegmentsActions.updateCustomSegmentsOrderFailure({
        currentIndex: newIndex,
        newIndex: currentIndex,
        sectionName,
      }),
    );
  }
}

function* updateCustomSegmentsOrderSuccess(): SagaIterator {
  yield put(
    ToastsActions.createToast({
      content: getToastContent(ToastContent.CUSTOM_SEGMENTS_ORDER_UPDATE_SUCCESS),
      kind: ToastVariant.Success,
    }),
  );
}

function* updateCustomSegmentsOrderFailure(): SagaIterator {
  yield put(
    ToastsActions.createToast({
      content: getToastContent(ToastContent.CUSTOM_SEGMENTS_ORDER_UPDATE_FAILURE),
      kind: ToastVariant.Error,
    }),
  );
}

function* updateCustomSegments(sectionName: CustomSegmentsSection): SagaIterator {
  const sectionSegments: KeyMap<CustomSegment> = yield select(getSectionCustomSegmentsData, sectionName);

  const segmentsToSave = Object.keys(sectionSegments).reduce((acc, segment, index) => {
    const customSegmentPropertyName = getCustomSegmentsPropertyName(sectionName, index);

    acc[customSegmentPropertyName] = sectionSegments[segment];

    return acc;
  }, {});

  const customSegmentsOrderPropertyName = getCustomSegmentsPropertyName(
    sectionName,
    AgentCustomPropertyName.CustomSegmentOrder,
  );

  const segmentsOrderToSave = {
    [customSegmentsOrderPropertyName]: Object.keys(sectionSegments),
  };

  const indexToRemove = Object.keys(segmentsToSave).length;

  yield put(
    AgentCustomPropertiesActions.setAgentCustomProperty({
      ...segmentsToSave,
      ...segmentsOrderToSave,
    }),
  );
  yield put(
    AgentCustomPropertiesActions.deleteAgentCustomProperty({
      id: `${AgentCustomPropertyName.CustomSegmentPrefix}${sectionName}_${indexToRemove}`,
    }),
  );
  yield put(TrafficActions.setSegmentsActionsState(SegmentsActionsState.Enabled));
}

function* deleteCustomSegment(action: IActionWithPayload<string, DeleteCustomSegmentPayload>): SagaIterator {
  const { segmentId, sectionName, eventPlace } = action.payload;
  const sectionSegments: KeyMap<CustomSegment> = yield select(getSectionCustomSegmentsData, sectionName);
  const sectionSegmentsOrderIds: string[] = yield select(getSectionCustomSegmentsIds, sectionName);
  const segmentToUpdateIndex = Object.keys(sectionSegments).findIndex((id) => id === segmentId);
  const customSegmentPropertyName = getCustomSegmentsPropertyName(sectionName, segmentToUpdateIndex);

  yield put(TrafficActions.setSegmentsActionsState(SegmentsActionsState.Disabled));

  yield put(
    AgentCustomPropertiesActions.deleteAgentCustomProperty({
      id: customSegmentPropertyName,
    }),
  );

  const { success }: { success: boolean; failure: boolean } = yield race({
    success: take(AGENT_CUSTOM_PROPERTIES.DELETE_AGENT_CUSTOM_PROPERTY[RequestAction.SUCCESS]),
    failure: take(AGENT_CUSTOM_PROPERTIES.DELETE_AGENT_CUSTOM_PROPERTY[RequestAction.FAILURE]),
  });

  if (success) {
    trackEvent(CustomSegmentEvent.SegmentDeleted, eventPlace, { segment: segmentId });

    const newSegmentsOrderIds = sectionSegmentsOrderIds.filter((id) => id !== segmentId);

    yield put(
      CustomSegmentsActions.deleteCustomSegmentSuccess({
        sectionName,
        segmentId,
        sortedIds: newSegmentsOrderIds,
      }),
    );

    yield call(updateCustomSegments, sectionName);
  } else {
    yield put(CustomSegmentsActions.deleteCustomSegmentFailure());
    yield put(TrafficActions.setSegmentsActionsState(SegmentsActionsState.Enabled));
  }
}

function* deleteCustomSegmentSuccess(
  action: IActionWithPayload<string, DeleteCustomSegmentSuccessPayload>,
): SagaIterator {
  const { sectionName, segmentId } = action.payload;
  const selectedSegmentId = yield select(getSectionSelectedSegmentId, sectionName);

  if (segmentId === selectedSegmentId) {
    yield put(
      CustomSegmentsActions.selectCustomSegment({ segmentId: SEGMENTS_DEFAULT_SEGMENT_ID[sectionName], sectionName }),
    );
  }

  yield put(
    ToastsActions.createToast({
      content: getToastContent(ToastContent.CUSTOM_SEGMENT_DELETE_SUCCESS),
      kind: ToastVariant.Success,
    }),
  );
}

function* deleteCustomSegmentFailure(): SagaIterator {
  yield put(
    ToastsActions.createToast({
      content: getToastContent(ToastContent.CUSTOM_SEGMENT_DELETE_FAILURE),
      kind: ToastVariant.Error,
    }),
  );
}

function* loadCustomSegments(
  action: IActionWithPayload<string, IFetchAgentCustomPropertiesSuccessPayload>,
): SagaIterator {
  const loadDefaults = !(yield select(getCustomSegmentsUsageAllowed));
  const agentCustomProperties = action.payload;
  let shouldSaveTrafficSegments = false;

  const customSegmentsData = CUSTOM_SEGMENTS_SECTIONS.reduce((acc, sectionName) => {
    const sectionSegments = getSectionSegmentsFromEntries(agentCustomProperties, sectionName);
    const sortedIds = getSectionSegmentsSortedIdsFromEntries(agentCustomProperties, sectionName);

    // custom-segments used in the new traffic section need to be backward compatible with the legacy traffic section hence if
    if ((sectionName as CustomSegmentsSection) === CustomSegmentsSection.Traffic && !acc[sectionName]) {
      const {
        sortedIds: trafficSortedIds,
        segments,
        areLegacySegments,
      } = buildSegmentsForTraffic(agentCustomProperties, sortedIds, sectionSegments);
      acc[sectionName] = {
        selectedId: SEGMENTS_DEFAULT_SEGMENT_ID[sectionName],
        sortedIds: trafficSortedIds,
        data: segments,
      };
      shouldSaveTrafficSegments = areLegacySegments;
    } else {
      acc[sectionName] = {
        selectedId: SEGMENTS_DEFAULT_SEGMENT_ID[sectionName],
        sortedIds,
        data: sectionSegments,
      };
    }

    if (loadDefaults) {
      acc[sectionName] = {
        selectedId: SEGMENTS_DEFAULT_SEGMENT_ID[sectionName],
        sortedIds,
        data: DEFAULT_SEGMENTS_CONFIG[sectionName],
      };
    }

    return acc;
  }, {});

  if (shouldSaveTrafficSegments) {
    yield put(
      CustomSegmentsActions.saveCustomSegments({
        segments: customSegmentsData[CustomSegmentsSection.Traffic].data,
        sectionName: CustomSegmentsSection.Traffic,
      }),
    );
  }

  yield put(CustomSegmentsActions.loadCustomSegments({ customSegments: customSegmentsData }));
}

function* handleCustomPropertiesFetched(
  action: IActionWithPayload<string, IFetchAgentCustomPropertiesSuccessPayload>,
): SagaIterator {
  yield call(loadCustomSegments, action);
}

export function* customSegmentsSagas(): SagaIterator {
  yield takeEvery(CustomSegmentsActionsNames.CREATE_CUSTOM_SEGMENT, createCustomSegment);
  yield takeEvery(CustomSegmentsActionsNames.CREATE_CUSTOM_SEGMENT_SUCCESS, createCustomSegmentSuccess);
  yield takeEvery(CustomSegmentsActionsNames.CREATE_CUSTOM_SEGMENT_FAILURE, createCustomSegmentFailure);
  yield takeEvery(CustomSegmentsActionsNames.UPDATE_CUSTOM_SEGMENT, updateCustomSegment);
  yield takeEvery(CustomSegmentsActionsNames.UPDATE_CUSTOM_SEGMENT_SUCCESS, updateCustomSegmentSuccess);
  yield takeEvery(CustomSegmentsActionsNames.UPDATE_CUSTOM_SEGMENT_FAILURE, updateCustomSegmentFailure);
  yield takeEvery(CustomSegmentsActionsNames.SAVE_CUSTOM_SEGMENTS, saveCustomSegments);
  yield takeEvery(CustomSegmentsActionsNames.UPDATE_CUSTOM_SEGMENT_LABEL, updateCustomSegmentLabel);
  yield takeEvery(CustomSegmentsActionsNames.UPDATE_CUSTOM_SEGMENT_LABEL_SUCCESS, updateCustomSegmentLabelSuccess);
  yield takeEvery(CustomSegmentsActionsNames.UPDATE_CUSTOM_SEGMENT_LABEL_FAILURE, updateCustomSegmentLabelFailure);
  yield takeEvery(CustomSegmentsActionsNames.UPDATE_CUSTOM_SEGMENTS_ORDER, updateCustomSegmentsOrder);
  yield takeEvery(CustomSegmentsActionsNames.UPDATE_CUSTOM_SEGMENTS_ORDER_SUCCESS, updateCustomSegmentsOrderSuccess);
  yield takeEvery(CustomSegmentsActionsNames.UPDATE_CUSTOM_SEGMENTS_ORDER_FAILURE, updateCustomSegmentsOrderFailure);
  yield takeEvery(CustomSegmentsActionsNames.DELETE_CUSTOM_SEGMENT, deleteCustomSegment);
  yield takeEvery(CustomSegmentsActionsNames.DELETE_CUSTOM_SEGMENT_SUCCESS, deleteCustomSegmentSuccess);
  yield takeEvery(CustomSegmentsActionsNames.DELETE_CUSTOM_SEGMENT_FAILURE, deleteCustomSegmentFailure);
  yield takeEvery(
    AGENT_CUSTOM_PROPERTIES.FETCH_AGENT_CUSTOM_PROPERTIES[RequestAction.SUCCESS],
    handleCustomPropertiesFetched,
  );
}
