import { Icons } from 'constants/icons';
import { UserGuidedTourType } from 'constants/user-guided-tour-type';
import { getIsFeatureEnabled } from 'helpers/feature-flags';
import { HELPDESK_APP_URL } from 'helpers/helpdesk';
import { shouldShowNewBadge } from 'helpers/settings';
import { type ISideNavigationItem } from 'interfaces/side-navigation-item';
import { FeatureNames } from 'services/api/feature-control/interfaces';
import { AppStateProvider } from 'services/app-state-provider';
import { ReportViewsStep } from 'services/tour/report-views/steps';
import {
  getIsChatAnalyserInstalled,
  getIsHelpDeskInstalled,
  getIsInsightsInstalled,
} from 'store/entities/applications/selectors';
import { BadgeKey } from 'store/entities/badges/interfaces';
import { getBadge } from 'store/entities/badges/selectors';
import { selectIsDisableNativeTicketsExperiment } from 'store/entities/experiments/selectors';
import { getChatAnalyserFirstInstallationDate } from 'store/entities/integration-license-properties/selectors';

import { ReportType } from './report-type';

// TODO: STORE IMPORTS CLEANUP - because of custom checks that require access to the store it should be moved to selectors
export const NavigationItems: ISideNavigationItem[] = [
  {
    id: 'saved-views',
    name: 'My saved views',
    path: '/reports/saved-views',
    tour: {
      id: UserGuidedTourType.ReportViews,
      step: ReportViewsStep.MySavedViewsPage,
    },
  },
  {
    id: 'summary',
    name: 'Summary',
    children: [
      {
        id: ReportType.Last7days,
        name: 'Last 7 days',
        path: '/reports/last-7-days',
      },
      {
        id: 'dashboard',
        name: 'Dashboard',
        path: '/reports/dashboard',
      },
    ],
  },
  {
    id: 'chats',
    name: 'Chats',
    children: [
      {
        id: ReportType.TotalChats,
        name: 'Total chats',
        path: '/reports/total-chats',
      },
      {
        id: ReportType.ChatTopics,
        name: 'Chat topics',
        path: '/reports/chat-topics',
        betaBadge: true,
        customCheck: () => AppStateProvider.selectFromStore(getIsChatAnalyserInstalled),
        checkNewBadgeVisibility: (): boolean => {
          const firstInstallationDate = AppStateProvider.selectFromStore(getChatAnalyserFirstInstallationDate);
          AppStateProvider.selectFromStore(getIsChatAnalyserInstalled);
          if (!firstInstallationDate) {
            return false;
          }

          return shouldShowNewBadge(firstInstallationDate, 31);
        },
      },
      {
        id: ReportType.ChatEngagement,
        name: 'Chat engagement',
        path: '/reports/chat-engagement',
      },
      {
        id: ReportType.MissedChats,
        name: 'Missed chats',
        path: '/reports/missed-chats',
      },
      {
        id: ReportType.Greetings,
        name: 'Campaigns conversion',
        path: '/reports/campaigns',
      },
      {
        id: ReportType.TagsUsage,
        name: 'Tags usage',
        path: '/reports/tags-usage',
      },
      {
        id: ReportType.ChatRatings,
        name: 'Chat satisfaction',
        path: '/reports/chat-ratings',
      },
      {
        id: ReportType.ChatAvailability,
        name: 'Chat availability',
        path: '/reports/availability',
      },
      {
        id: ReportType.ChatForms,
        name: 'Chat forms',
        path: '/reports/chat-forms',
      },
      {
        id: ReportType.ChatDuration,
        name: 'Chat duration',
        path: '/reports/chat-duration',
      },
    ],
  },
  {
    id: 'agents',
    name: 'Agents',
    children: [
      {
        id: ReportType.AgentsPerformance,
        name: 'Agent performance',
        path: '/reports/agent-performance',
      },
      {
        id: ReportType.ChatResponseTime,
        name: 'Chat response times',
        path: '/reports/chat-response-times',
      },
      {
        id: ReportType.StaffingPrediction,
        name: 'Staffing prediction',
        path: '/reports/staffing-prediction',
      },
      {
        id: ReportType.Activity,
        name: 'Agent activity',
        path: '/reports/activity',
      },
    ],
  },
  {
    id: 'customers',
    name: 'Customers',
    children: [
      {
        id: ReportType.QueuedCustomers,
        name: 'Queued customers',
        path: '/reports/queued-visitors',
      },
      {
        id: ReportType.QueueAbandonment,
        name: 'Queue abandonment',
        path: '/reports/queue-abandonment',
      },
    ],
  },
  {
    id: 'insights',
    name: 'Insights',
    children: [
      {
        id: ReportType.Overview,
        name: 'Overview',
        path: '/reports/overview',
        badgeCount: () => AppStateProvider.selectFromStore(getBadge, BadgeKey.InsightsOverview),
        customCheck: () => getIsFeatureEnabled(FeatureNames.InsightsReportsEnabled),
      },
      {
        id: ReportType.RecentInsights,
        name: 'Recent Insights',
        path: '/reports/recent-insights',
        badgeCount: () => AppStateProvider.selectFromStore(getBadge, BadgeKey.InsightsRecentInsights),
        customCheck: () => getIsFeatureEnabled(FeatureNames.InsightsReportsEnabled),
      },
      {
        id: ReportType.TopCustomerQuestions,
        name: 'Top customer questions',
        path: '/reports/top-customer-questions',
        badgeCount: () => AppStateProvider.selectFromStore(getBadge, BadgeKey.InsightsTopCustomerQuestions),
      },
      {
        id: ReportType.Alerts,
        name: 'Alerts',
        path: '/reports/alerts',
      },
    ],
    customCheck: () => AppStateProvider.selectFromStore(getIsInsightsInstalled),
  },
  {
    id: 'tickets',
    name: 'Tickets',
    children: [
      {
        id: ReportType.NewTickets,
        name: 'New tickets',
        path: '/reports/new-tickets',
      },
      {
        id: ReportType.TicketRatings,
        name: 'Ticket satisfaction',
        path: '/reports/ticket-ratings',
      },
      {
        id: ReportType.TicketFirstResponseTime,
        name: 'First response time',
        path: '/reports/ticket-first-response-time',
      },
      {
        id: ReportType.SolvedTickets,
        name: 'Solved tickets',
        path: '/reports/solved-tickets',
      },
      {
        id: ReportType.TicketResolutionTime,
        name: 'Resolution time',
        path: '/reports/ticket-resolution-time',
      },
    ],
  },
  {
    id: 'ecommerce',
    name: 'Ecommerce',
    children: [
      {
        id: ReportType.Goals,
        name: 'Achieved goals',
        path: '/reports/goals',
      },
      {
        id: ReportType.Sales,
        name: 'Tracked sales',
        path: '/reports/sales',
      },
    ],
  },
  {
    id: 'export',
    name: 'Export raw data',
    children: [
      {
        id: 'generate-report',
        name: 'Generate report',
        path: '/reports/generate-report',
      },
      {
        id: ReportType.ScheduledReports,
        name: 'Scheduled reports',
        path: '/reports/scheduled-reports',
      },
    ],
  },
  {
    id: 'helpdesk',
    name: 'HelpDesk tickets',
    path: `${HELPDESK_APP_URL}/reports/last-7-days`,
    icon: Icons.OpenInNewWindow,
    external: true,
    customCheck: () =>
      AppStateProvider.selectFromStore(selectIsDisableNativeTicketsExperiment) &&
      AppStateProvider.selectFromStore(getIsHelpDeskInstalled),
  },
  {
    id: 'upgrade',
    name: 'Upgrade',
    path: '/reports/upgrade',
    hidden: true,
  },
  {
    id: 'reviews',
    name: 'Reviews',
    path: '/reports/reviews',
    customCheck: () => getIsFeatureEnabled(FeatureNames.ReviewsReportEnabled),
  },
];
