import { useQuery } from '@tanstack/react-query';
import { useSelector } from 'react-redux';

import { QueryKey } from 'constants/query-key';
import { type Subscriptions } from 'interfaces/subscription';
import type { ProductData } from 'services/api/accounts/interfaces';
import { ApiManager } from 'services/api/api-manager';
import { getOrganizationId } from 'store/entities/accounts/selectors';

const fetchMyProducts = async () => {
  const { result, error } = await ApiManager.accountsApi.getMyProducts();

  if (error) {
    throw error;
  }

  return result;
};

const fetchSubscriptions = async () => {
  const { result, error } = await ApiManager.billingApiV3.fetchActivatedSubscriptions();

  if (error) {
    throw error;
  }

  return result;
};

interface UseProductData {
  organizationId: string;
  installedProducts: ProductData[];
  subscriptions: Subscriptions;
}

export const useProductData = (): UseProductData => {
  const organizationId = useSelector(getOrganizationId);
  const { data: installedProducts } = useQuery([QueryKey.MyProducts], fetchMyProducts, {
    refetchOnWindowFocus: false,
    initialData: [],
  });
  const {
    data: { subscriptions },
  } = useQuery([QueryKey.ActivatedSubscriptions], fetchSubscriptions, {
    refetchOnWindowFocus: false,
    initialData: () => ({ subscriptions: {} }),
  });

  return {
    organizationId,
    installedProducts,
    subscriptions,
  };
};
