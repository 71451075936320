/* eslint-disable @typescript-eslint/no-unsafe-call */
// @ts-strict-ignore
import { addMinutes } from 'date-fns';
import { type SagaIterator } from 'redux-saga';
import { all, call, debounce, delay, put, race, select, take, takeEvery, takeLatest } from 'redux-saga/effects';

import {
  CustomDateRangeFilterId,
  type CustomDateRange,
  type CustomDateRangeFilter,
} from 'constants/filters/date-range-filter';
import { Filter, type FiltersOperators } from 'constants/filters/filter';
import { SurveyType } from 'constants/filters/survey-type-filter';
import { TagFilter } from 'constants/filters/tag-filter';
import { ReportDistribution } from 'constants/reports/distribution';
import { Last7DaysMode } from 'constants/reports/last-7-days-mode';
import { ReportType } from 'constants/reports/report-type';
import { SelectedViewSource } from 'constants/reports/selected-view-source';
import { Section } from 'constants/section';
import { ActionHandlerExecutionDelay, ToastAutoHideDelay, ToastContent } from 'constants/toasts';
import { UserGuidedTourType } from 'constants/user-guided-tour-type';
import { ViewActionSource } from 'constants/view-actions-source';
import { EventPlace } from 'helpers/analytics';
import * as analytics from 'helpers/analytics-reports';
import { calculateOptions, getCompareOptions } from 'helpers/date-range-filter';
import { getAvailableReportFilters } from 'helpers/get-available-report-filters';
import { type KeyMap } from 'helpers/interface';
import { getFiltersByReportType, parseChartData } from 'helpers/reports';
import { navigate } from 'helpers/routing';
import { getToastContent } from 'helpers/toast';
import type { TicketCreateParams } from 'interfaces/api/ticket';
import type {
  IBenchmarkDeserializer,
  IDeleteReportViewPayload,
  IFetchDataPayload,
  IReportCreatePayloadData,
  IReportView,
  IReportViewsById,
  ReportsViewFilters,
  IReportsViewSetQueryParamsFromURLPayload,
  IReportsViewSetReportDistributionPayload,
  IReportsViewUpdateFilterPayload,
  ISaveColumnsOrderPayload,
  ISaveColumnsVisibilityPayload,
  ISaveReportViewFailurePaylod,
  ISaveReportViewPaylod,
  IScheduledReportDeleteData,
  IScheduledReportEditData,
  ISelectReportViewPayload,
  IUpdateFilterOperatorPayload,
} from 'interfaces/reports';
import { ApiErrorMessagePart } from 'services/api/constants';
import { AppStateProvider } from 'services/app-state-provider';
import { browserHistory } from 'services/browser-history';
import { connectivityStatusClient } from 'services/connectivity/status/client';
import { trackEvent } from 'services/event-tracking';
import { CRUDAction, RequestAction } from 'store/entities/actions';
import { getLoggedInAgentLogin } from 'store/entities/agents/selectors';
import { ApplicationsActionNames } from 'store/entities/applications/actions';
import { hasFetchedApplications } from 'store/entities/applications/selectors';
import { CompanyDetailsActionNames, CompanyDetailsActions } from 'store/entities/company-details/actions';
import { GreetingActions } from 'store/entities/greetings/actions';
import {
  CreateReportActionNames,
  CreateTicketFromQueueAbandonmentActionNames,
  DeleteScheduledReportActionNames,
  EditScheduledReportActionNames,
  REPORT,
  ReportActions,
} from 'store/entities/reports/actions';
import {
  type ICreateReportPayload,
  type ICreateTicketFromQueueAbandonmentSuccessPayload,
  type IFetchReportSuccessPayload,
} from 'store/entities/reports/interfaces';
import { TagActions } from 'store/entities/tags/actions';
import { AGENT_CUSTOM_PROPERTIES, AgentCustomPropertiesActions } from 'store/features/agent-custom-properties/actions';
import { type IFetchAgentCustomPropertiesSuccessPayload } from 'store/features/agent-custom-properties/interfaces';
import { getReportViewsTourDone } from 'store/features/agent-custom-properties/selectors';
import { getIsOnSection } from 'store/features/routing/selectors';
import { isGhostLogin } from 'store/features/session/selectors';
import { ToastsActions } from 'store/features/toasts/actions';
import { ToastVariant } from 'store/features/toasts/interfaces';
import { UserGuidedTourActions } from 'store/features/user-guided-tours/actions';
import { type IActionWithPayload } from 'store/helper';
import { createHasFetchedSelector } from 'store/requests/selectors';

import { ReportsViewActions, ReportsViewActionsNames } from './actions';
import { getAvailableFilters } from './computed';
import { ReportViewsEvent } from './constants';
import reportDataDeserializers, { dataMutator } from './data-deserializers';
import { deserializeBenchmark } from './data-deserializers/benchmark-deserializer';
import { heatmapDeserializer } from './data-deserializers/heatmap-deserializer';
import {
  deserializeScheduledReportCreate,
  deserializeScheduledReportEdit,
} from './data-deserializers/scheduled-reports-deserializer';
import {
  createAgentCustomPropertiesFromReportView,
  createReportView,
  getReportViewAgentCustomPropertyKey,
  getReportViewsByIdFromEntries,
  reportColumnsOrderPropertyName,
  reportVisibleColumnsPropertyName,
} from './helpers/sagas';
import {
  getAllReportsCacheExpirationTime,
  getCurrentView,
  getDistribution,
  getFilterDateRange,
  getFilterGoal,
  getFilterOperator,
  getFilterTag,
  getFilters,
  getFiltersOperators,
  getLast7DaysMode,
  getReportCacheExpirationTime,
  getReportViewsSelector,
  getSelectedReportView,
  hasReportDateRangeFilterComparison,
  isReportAvailable,
  shouldBenchmarkDataBeFetched,
  shouldWaitForCompanyDetails as shouldWaitForCompanyDetailsSelector,
  getFilterCountry,
} from './selectors';

const reportsHeatmaps = {
  [ReportType.TotalChats]: ReportType.TotalChatsHeatmap,
  [ReportType.NewTickets]: ReportType.NewTicketsHeatmap,
  [ReportType.MissedChats]: ReportType.MissedChatsHeatmap,
  [ReportType.QueuedCustomers]: ReportType.QueuedCustomersHeatmap,
};

function hasHeatmap(reportType: ReportType): boolean {
  return !!reportsHeatmaps[reportType];
}

export function prepareBenchmarkPayload(
  data: any,
  deserializedData: IBenchmarkDeserializer,
  reportType: ReportType,
): void {
  switch (reportType) {
    case ReportType.TotalChats:
      data.totalChats.series.push(deserializedData.benchmarkSeries);
      data.totalChats.summary.push(deserializedData.benchmarkSummary);
      break;
    case ReportType.ChatDuration:
      data.chatDuration.series.push(deserializedData.benchmarkSeries);
      data.chatDuration.summary.push(deserializedData.benchmarkSummary);
      break;
    case ReportType.ChatResponseTime:
      data.firstResponseTime.series.push(deserializedData.benchmarkSeries);
      data.firstResponseTime.summary.push(deserializedData.benchmarkSummary);
      break;
    case ReportType.ChatRatings:
      data.chatRating.summary.push(deserializedData.benchmarkSummary);
      break;
    default:
      break;
  }
}

export function* updateReportViewData(action: IActionWithPayload<string, IFetchReportSuccessPayload>): any {
  const { payload } = action;

  if (payload.source === ViewActionSource.Reports) {
    let { data } = payload;
    const { benchmarkData, reportType } = payload;

    if (Object.values(reportsHeatmaps).includes(reportType)) {
      data = yield heatmapDeserializer(data, reportType);
    }

    const deserializer: (data: any, index?: number, arr?: any[]) => SagaIterator = reportDataDeserializers[reportType];
    if (deserializer) {
      if (Array.isArray(data)) {
        const dataSets = yield all(data.map((dataSet, index, arr) => deserializer(dataSet, index, arr)));

        const dateSetsMutator: (data: any) => any = dataMutator[reportType] || parseChartData;

        data = dateSetsMutator(
          // eslint-disable-next-line @typescript-eslint/no-unsafe-return
          data.map((dataSet, index) => ({
            ...dataSet,
            id: index,
            data: dataSets[index],
          })),
        );
      } else {
        data = yield deserializer({ data });
      }
    }

    if (benchmarkData) {
      const deserializedData = deserializeBenchmark(benchmarkData, payload.reportType);
      prepareBenchmarkPayload(data, deserializedData, reportType);
    }

    yield put(ReportsViewActions.setReportData(reportType, data));

    yield put(ReportsViewActions.setCacheExpirationTime(reportType, addMinutes(new Date(), 10)));
  }
}

const cacheActiveActions = [ReportsViewActionsNames.SET_CURRENT_VIEW, ReportsViewActionsNames.FETCH_DATA];

export function* fetchReport(action: IActionWithPayload<ReportsViewActionsNames, IFetchDataPayload>): any {
  const { payload, type } = action;

  const wereApplicationsFetched = yield select(hasFetchedApplications);
  const isGhost = yield select(isGhostLogin);

  if (!wereApplicationsFetched && !isGhost) {
    yield race([
      ApplicationsActionNames[CRUDAction.FETCH_COLLECTION][RequestAction.SUCCESS],
      ApplicationsActionNames[CRUDAction.FETCH_COLLECTION][RequestAction.FAILURE],
    ]);
  }

  /**
   * Prevent to use "not tagged" filter on Tags Usage Report
   */
  if (type === ReportsViewActionsNames.SET_CURRENT_VIEW && payload.reportType === ReportType.TagsUsage) {
    const tagFilter: ReturnType<typeof getFilterTag> = yield select(getFilterTag);

    if (tagFilter && tagFilter.includes(TagFilter.NotTagged)) {
      const newTagFilterValue = tagFilter.filter((tag: TagFilter) => tag !== TagFilter.NotTagged);
      yield put(
        ReportsViewActions.updateFilter<Filter.Tag>({
          name: Filter.Tag,
          value: newTagFilterValue.length ? newTagFilterValue : null,
        }),
      );
    }
  }

  /**
   * currentView doesn't work for HomePage so when content switcher is changed
   * we are setting currentView for Last7Days
   */
  const reportType = yield payload.reportType ||
    (action.type === ReportsViewActionsNames.SET_LAST_7_DAYS_MODE && ReportType.Last7days) ||
    select(getCurrentView);

  // GLOBAL ACCOUNTS TODO: LICENSE PLAN SCOPE CHECK
  const hasAccess = yield select(isReportAvailable, reportType);

  /**
   * We want to force using the compare if you go to agentsPerformance report first time
   */
  if (type === ReportsViewActionsNames.SET_CURRENT_VIEW && payload.reportType === ReportType.AgentsPerformance) {
    const wasAgentPerformanceFetched = yield select(
      createHasFetchedSelector(['ENTITIES/REPORT/FETCH_AGENTS_PERFORMANCE']),
    );
    const hasDateRangeComparison = yield select(hasReportDateRangeFilterComparison);

    if (hasAccess && !wasAgentPerformanceFetched && !hasDateRangeComparison) {
      const dateRange: CustomDateRangeFilter = yield select(getFilterDateRange);
      const comparisonOption = getCompareOptions(dateRange.from, dateRange.to)[0];
      yield put(
        ReportsViewActions.updateFilter<Filter.DateRange>({
          name: Filter.DateRange,
          value: {
            id: dateRange.id,
            from: dateRange.from,
            to: dateRange.to,
          },
          compare: {
            id: comparisonOption.id as CustomDateRangeFilterId,
            from: comparisonOption.value.from,
            to: comparisonOption.value.to,
          },
        }),
      );

      return;
    }
  }

  /**
   * Update filters after navigation from settings/ecommerce/goals reports
   * Probably should be rewritten to more generic / ReportType independent
   */
  if (type === ReportsViewActionsNames.SET_CURRENT_VIEW && payload.reportType === ReportType.Goals) {
    if (hasAccess) {
      const goals = yield select(getFilterGoal);
      yield put(
        ReportsViewActions.updateFilter<Filter.Goal>({
          name: Filter.Goal,
          value: goals,
        }),
      );

      return;
    }
  }

  /**
   * Dont fetch data if only filter operator was set without filter value
   */
  if (type === ReportsViewActionsNames.UPDATE_FILTER_OPERATOR) {
    const { filter } = payload as unknown as IUpdateFilterOperatorPayload;
    const tags: ReturnType<typeof getFilterTag> = yield select(getFilterTag);
    const country: ReturnType<typeof getFilterCountry> = yield select(getFilterCountry);
    const hasTagOperatorChangedWithEmptyFilterValue = filter === Filter.Tag && !tags?.length;
    const hasCountryOperatorChangedWithEmptyFilterValue = filter === Filter.CountryISO && !country?.length;

    if (hasTagOperatorChangedWithEmptyFilterValue || hasCountryOperatorChangedWithEmptyFilterValue) {
      return;
    }
  }

  const reportExpirationTime = yield select(getReportCacheExpirationTime, reportType);

  const isReportCached = Boolean(
    cacheActiveActions.includes(action.type) && reportExpirationTime && reportExpirationTime > new Date(),
  );

  if (!isReportCached && Object.values(ReportType).includes(reportType) && hasAccess) {
    const distribution = yield select(getDistribution);
    const filtersOperators = yield select(getFiltersOperators);

    const shouldWaitForCompanyDetails = yield select(shouldWaitForCompanyDetailsSelector);

    if (shouldWaitForCompanyDetails) {
      yield take(CompanyDetailsActionNames.FETCH_COMPANY_DETAILS_SUCCESS);
    }

    const shouldBenchmarkFetch = yield select(shouldBenchmarkDataBeFetched);

    const availableFilters = yield select(getAvailableFilters);

    yield put(
      ReportActions.fetch({
        reportType,
        source: ViewActionSource.Reports,
        filters: availableFilters,
        filtersOperators,
        distribution,
        shouldBenchmarkFetch,
      }),
    );

    if (hasHeatmap(reportType)) {
      yield put(
        ReportActions.fetchHeatMap({
          reportType: reportsHeatmaps[reportType],
          source: ViewActionSource.Reports,
          filters: availableFilters,
          filtersOperators,
          distribution,
        }),
      );
    }
  }
}

function* fetchReportFailure(data: any): SagaIterator {
  const { action } = data.payload;
  const { payload } = action;

  yield delay(2000);
  const isOnline = connectivityStatusClient.getIsOnline();

  if (isOnline) {
    return;
  }

  // We don't want to display toast for different section than reports
  const areReportsOpen = yield select(getIsOnSection, Section.Reports);
  if (!areReportsOpen) {
    return;
  }

  if (payload.retry >= 2) {
    yield put(
      ToastsActions.createToast({
        content: getToastContent(ToastContent.DEFAULT_ERROR),
        autoHideDelayTime: ToastAutoHideDelay.Long,
        kind: ToastVariant.Error,
      }),
    );
  } else {
    yield put(
      ToastsActions.createToast({
        content: getToastContent(ToastContent.REPORT_FETCH_ERROR),
        autoHideDelayTime: ToastAutoHideDelay.Long,
        kind: ToastVariant.Error,
        action: {
          label: 'Try again',
          onClick: () => {
            payload.retry += 1;
            setTimeout(() => AppStateProvider.dispatch(action), ActionHandlerExecutionDelay.Long);
          },
          closeOnClick: true,
        },
      }),
    );
  }
}

function* createReport(action: IActionWithPayload<string, IReportCreatePayloadData>): SagaIterator {
  yield put(
    ReportActions.createReport({
      data: action.payload.data,
      source: ViewActionSource.Reports,
    }),
  );
}

function* createReportSuccess(action: IActionWithPayload<string, ICreateReportPayload>): any {
  const deserializedData = yield action.payload.data ? deserializeScheduledReportCreate(action.payload.data) : null;

  yield put(ReportsViewActions.createReportSuccess(deserializedData));
  if (deserializedData) {
    navigate('/reports/scheduled-reports');
    yield put(
      ToastsActions.createToast({
        content: getToastContent(ToastContent.REPORT_CREATE_SUCCESS),
        autoHideDelayTime: ToastAutoHideDelay.Long,
        kind: ToastVariant.Success,
      }),
    );
  }
}

function* createReportFailure(): SagaIterator {
  yield put(
    ToastsActions.createToast({
      content: getToastContent(ToastContent.REPORT_CREATE_ERROR),
      autoHideDelayTime: ToastAutoHideDelay.Long,
      kind: ToastVariant.Error,
    }),
  );
}

function* editScheduledReport(action: IActionWithPayload<string, IScheduledReportEditData>): SagaIterator {
  yield put(
    ReportActions.editScheduledReport({
      data: action.payload.data,
      source: ViewActionSource.Reports,
    }),
  );
}

function* editScheduledReportSuccess(action: IActionWithPayload<string, IScheduledReportEditData>): any {
  const deserializedData = yield deserializeScheduledReportEdit(action.payload.data);

  yield put(ReportsViewActions.editScheduledReportSuccess(deserializedData));
  navigate('/reports/scheduled-reports');
  yield put(
    ToastsActions.createToast({
      content: getToastContent(ToastContent.REPORT_SAVE_SUCCESS),
      autoHideDelayTime: ToastAutoHideDelay.Long,
      kind: ToastVariant.Success,
    }),
  );
}

function* editScheduledReportFailure(): SagaIterator {
  yield put(
    ToastsActions.createToast({
      content: getToastContent(ToastContent.REPORT_SAVE_ERROR),
      autoHideDelayTime: ToastAutoHideDelay.Long,
      kind: ToastVariant.Error,
    }),
  );
}

function* deleteScheduledReport(action: IActionWithPayload<string, IScheduledReportDeleteData>): SagaIterator {
  yield put(
    ReportActions.deleteScheduledReport({
      data: { id: action.payload.data.id },
      source: ViewActionSource.Reports,
    }),
  );
}

function* deleteScheduledReportSuccess(action: IActionWithPayload<string, IScheduledReportDeleteData>): SagaIterator {
  const data = { id: action.payload.data.id };
  yield put(ReportsViewActions.deleteScheduledReportSuccess(data));
  yield put(
    ToastsActions.createToast({
      content: getToastContent(ToastContent.REPORT_DELETE_SUCCESS),
      autoHideDelayTime: ToastAutoHideDelay.Long,
      kind: ToastVariant.Success,
    }),
  );
}

function* deleteScheduledReportFailure(): SagaIterator {
  yield put(
    ToastsActions.createToast({
      content: getToastContent(ToastContent.REPORT_DELETE_ERROR),
      autoHideDelayTime: ToastAutoHideDelay.Long,
      kind: ToastVariant.Error,
    }),
  );
}

function* createTicketFromQueueAbandonmentSuccess(
  action: IActionWithPayload<string, ICreateTicketFromQueueAbandonmentSuccessPayload>,
): SagaIterator {
  const { ticketId } = action.payload;

  yield put(
    ToastsActions.createToast({
      content: getToastContent(ToastContent.CREATE_TICKET_FROM_QUEUE_ABANDONMENT_SUCCESS),
      autoHideDelayTime: ToastAutoHideDelay.Long,
      kind: ToastVariant.Success,
      action: {
        label: 'View in tickets',
        onClick: () => {
          navigate(`/tickets/${ticketId}`);
        },
        closeOnClick: true,
      },
    }),
  );
  analytics.messageFollowupSentSuccess(ReportType.QueueAbandonment);
}

function* createTicketFromQueueAbandonmentFailure(): SagaIterator {
  yield put(
    ToastsActions.createToast({
      content: getToastContent(ToastContent.CREATE_TICKET_FROM_QUEUE_ABANDONMENT_ERROR),
      autoHideDelayTime: ToastAutoHideDelay.Long,
      kind: ToastVariant.Error,
    }),
  );
  analytics.messageFollowupSentFailure(ReportType.QueueAbandonment);
}

function* fetchGreetings(): SagaIterator {
  const wasGreetingsFetched = yield select(createHasFetchedSelector(['FETCH_COLLECTION_GREETING']));

  if (!wasGreetingsFetched) {
    yield put(GreetingActions.fetchCollection({}));
  }
}

function* fetchTags(): SagaIterator {
  const wasTagsFetched = yield select(createHasFetchedSelector(['FETCH_COLLECTION_TAG']));

  if (!wasTagsFetched) {
    yield put(TagActions.fetchCollection({}));
  }
}

function* fetchCompanyDetails(): SagaIterator {
  const wasCompanyDetailsFetched = yield select(
    createHasFetchedSelector(['ENTITIES/COMPANY_DETAILS/FETCH_COMPANY_DETAILS']),
  );

  if (!wasCompanyDetailsFetched) {
    yield put(CompanyDetailsActions.fetch());
  }
}

function* trackDistributionChange(
  action: IActionWithPayload<ReportsViewActionsNames.SET_DISTRIBUTION, IReportsViewSetReportDistributionPayload>,
): SagaIterator {
  const { type: distribution } = action.payload;

  const currentReport = yield select(getCurrentView);

  analytics.trackDistributionChange(distribution === ReportDistribution.Hour, currentReport);
}

function* trackFiltersChange<T extends keyof ReportsViewFilters>(
  action: IActionWithPayload<ReportsViewActionsNames.UPDATE_FILTER, IReportsViewUpdateFilterPayload<T>>,
): SagaIterator {
  const { name, compare, value, untracked = false } = action.payload;

  if (untracked) {
    return;
  }

  const currentReport = yield select(getCurrentView);

  const filters: ReportsViewFilters = yield select(getFilters);
  const { filters: availableFilters } = getFiltersByReportType(currentReport);
  const notEmptyFilters = Object.keys(filters).filter(
    (filterName) => availableFilters.includes(filterName as Filter) && filters[filterName],
  );

  let comparison;

  if (compare) {
    comparison = name;

    if (name === Filter.DateRange) {
      comparison = (compare as CustomDateRange).id;
    }
  }

  const filterParams: { [key: string]: string } = {};

  if (name === Filter.DateRange) {
    filterParams.period = value ? (value as CustomDateRange).id : CustomDateRangeFilterId.Last7Days;
  }

  filterParams.operator = yield select(getFilterOperator, name);

  analytics.trackFiltersChange(currentReport, name, notEmptyFilters.length, comparison, filterParams);
}

function* trackFilterOperatorChange(
  action: IActionWithPayload<ReportsViewActionsNames.UPDATE_FILTER_OPERATOR, IUpdateFilterOperatorPayload>,
): SagaIterator {
  const { filter, operator } = action.payload;

  const currentReport = yield select(getCurrentView);

  const filters: ReportsViewFilters = yield select(getFilters);
  const { filters: availableFilters } = getFiltersByReportType(currentReport);
  const notEmptyFilters = Object.keys(filters).filter(
    (filterName) => availableFilters.includes(filterName as Filter) && filters[filterName],
  );

  const filterParams: { [key: string]: string } = {};

  filterParams.operator = operator;

  analytics.trackFiltersChange(currentReport, filter, notEmptyFilters.length, null, filterParams);
}

function* setLast7DaysFilters(action): SagaIterator {
  const { resetFilters } = action.payload;

  const agentEmail = yield select(getLoggedInAgentLogin);
  const last7DaysMode = yield select(getLast7DaysMode);
  const option = calculateOptions(new Date()).find(
    ({ id }) => (id as CustomDateRangeFilterId) === CustomDateRangeFilterId.Last7Days,
  );

  yield put(
    ReportsViewActions.updateFilter({
      name: Filter.DateRange,
      value: {
        id: option.id as CustomDateRangeFilterId,
        ...option.value,
      },
    }),
  );

  yield put(
    ReportsViewActions.updateFilter({
      name: Filter.Agent,
      value: last7DaysMode === Last7DaysMode.Agent && !resetFilters ? [agentEmail] : null,
    }),
  );
}

function* cleanCaches(action: IActionWithPayload<string, KeyMap>): SagaIterator {
  const cacheExpirationTime = yield select(getAllReportsCacheExpirationTime);

  /**
   * Clean cache expiration time rules:
   * - if report isn't Last7Days (have no filters and distribution) or it is but only on mode change (content switcher)
   * - if report is queue abandonment and page has changed
   */
  const reportsToClean = Object.keys(cacheExpirationTime).filter(
    (key) =>
      (action.type === ReportsViewActionsNames.SET_LAST_7_DAYS_MODE && key === ReportType.Last7days) ||
      key !== ReportType.Last7days,
  );

  yield all(
    reportsToClean.map((reportType) => put(ReportsViewActions.setCacheExpirationTime(reportType as ReportType, null))),
  );
}

function* createTicketFromQueueAbandonment(action: IActionWithPayload<string, TicketCreateParams>): SagaIterator {
  yield put(ReportActions.createTicketFromQueueAbandonment({ data: action.payload }));
}

export function* deleteReportView(action: IActionWithPayload<string, IDeleteReportViewPayload>): SagaIterator {
  const id = action.payload;

  const { [id]: view } = yield select(getReportViewsSelector);

  if (!view) {
    return;
  }

  const key = getReportViewAgentCustomPropertyKey(id);

  yield put(AgentCustomPropertiesActions.deleteAgentCustomProperty({ id: key }));

  const { failure } = yield race({
    success: take(AGENT_CUSTOM_PROPERTIES.DELETE_AGENT_CUSTOM_PROPERTY[RequestAction.SUCCESS]),
    failure: take(AGENT_CUSTOM_PROPERTIES.DELETE_AGENT_CUSTOM_PROPERTY[RequestAction.FAILURE]),
  });

  if (failure) {
    const { error } = failure;

    yield put(
      ReportsViewActions.deleteReportViewFailure({
        error,
      }),
    );

    return;
  }

  yield put(ReportsViewActions.deleteReportViewSuccess(view));
}

function* deleteReportViewFailure(): SagaIterator {
  yield put(
    ToastsActions.createToast({
      content: getToastContent(ToastContent.REPORT_VIEWS_DELETE_FAILURE),
      kind: ToastVariant.Error,
    }),
  );
}

function* deleteReportViewSuccess(action: IActionWithPayload<string, IReportView>): SagaIterator {
  const { type, params } = action.payload;

  yield put(
    ToastsActions.createToast({
      content: getToastContent(ToastContent.REPORT_VIEWS_DELETE_SUCCESS),
      kind: ToastVariant.Success,
    }),
  );

  trackEvent(ReportViewsEvent.ViewDeleted, EventPlace.Reports, {
    reportType: type,
    params: Object.keys(params),
  });
}

export function* saveReportView(action: IActionWithPayload<string, ISaveReportViewPaylod>): SagaIterator {
  const type: ReportType = yield select(getCurrentView);
  const filters: ReportsViewFilters = yield select(getFilters);
  const distribution: ReportDistribution = yield select(getDistribution);
  const operators: FiltersOperators = yield select(getFiltersOperators);

  const availableFilters = getAvailableReportFilters(filters, type);

  const view = createReportView(action.payload, type, availableFilters, distribution, operators);

  trackEvent(ReportViewsEvent.SaveView, EventPlace.Reports, {
    reportType: type,
    params: Object.keys(view.params),
  });

  const customAgentProperties = createAgentCustomPropertiesFromReportView(view);

  yield put(AgentCustomPropertiesActions.setAgentCustomProperty(customAgentProperties));

  const { failure } = yield race({
    success: take(AGENT_CUSTOM_PROPERTIES.SET_AGENT_CUSTOM_PROPERTY[RequestAction.SUCCESS]),
    failure: take(AGENT_CUSTOM_PROPERTIES.SET_AGENT_CUSTOM_PROPERTY[RequestAction.FAILURE]),
  });

  if (failure) {
    const { error } = failure;

    yield put(
      ReportsViewActions.saveReportViewFailure({
        error,
      }),
    );

    return;
  }

  yield put(ReportsViewActions.saveReportViewSuccess(view));
}

function* saveReportViewFailure(action: IActionWithPayload<string, ISaveReportViewFailurePaylod>): SagaIterator {
  const { error } = action.payload;
  const hasExceededMaxLength = error.includes(ApiErrorMessagePart.ExceededMaxStringLength);

  const toastContent = hasExceededMaxLength
    ? ToastContent.REPORT_VIEWS_SAVE_FAILURE_TOO_COMPLEX_FILTERS
    : ToastContent.REPORT_VIEWS_SAVE_FAILURE;

  yield put(
    ToastsActions.createToast({
      content: getToastContent(toastContent),
      kind: ToastVariant.Error,
    }),
  );
}

function* saveReportViewSuccess(action: IActionWithPayload<string, IReportView>): SagaIterator {
  const { type, params } = action.payload;

  yield put(
    ToastsActions.createToast({
      content: getToastContent(ToastContent.REPORT_VIEWS_SAVE_SUCCESS),
      kind: ToastVariant.Success,
    }),
  );

  trackEvent(ReportViewsEvent.ViewSaved, EventPlace.Reports, {
    reportType: type,
    params: Object.keys(params),
  });

  const isReportViewsTourDone = yield select(getReportViewsTourDone);

  if (!isReportViewsTourDone) {
    yield all([
      put(UserGuidedTourActions.tourReady(UserGuidedTourType.ReportViews, true)),
      put(UserGuidedTourActions.showTour(UserGuidedTourType.ReportViews)),
    ]);
  }
}

function* loadReportViews(action: IActionWithPayload<string, IFetchAgentCustomPropertiesSuccessPayload>): SagaIterator {
  const views = getReportViewsByIdFromEntries(action.payload);

  yield put(ReportsViewActions.loadReportViews(views));
}

export function* unselectReportView(): SagaIterator {
  yield put(ReportsViewActions.unselectReportView());
}

export function* selectReportView(action: IActionWithPayload<string, ISelectReportViewPayload>): SagaIterator {
  if (!action.payload) {
    yield put(ReportsViewActions.setFilters({}));

    return;
  }

  const { id, source } = action.payload;
  const { [id]: selectedView }: IReportViewsById = yield select(getReportViewsSelector);

  if (!selectedView) {
    yield put(ReportsViewActions.setFilters({}));

    return;
  }

  const reportType = yield select(getCurrentView);

  const { distribution, operators, ...filters } = selectedView.params;

  if (ReportType.ChatForms === reportType) {
    const updatedFilters = {
      ...filters,
      [Filter.SurveyType]: SurveyType.PreChat,
    };

    yield put(
      ReportsViewActions.setFilters({
        ...updatedFilters,
        distribution,
        operators,
      }),
    );
  }

  if (selectedView.type !== reportType) {
    return;
  }

  trackEvent(ReportViewsEvent.ViewSelected, EventPlace.Reports, {
    reportType,
    params: Object.keys(selectedView.params),
    source,
  });

  yield put(
    ReportsViewActions.setFilters({
      ...filters,
      distribution,
      operators,
    }),
  );
}

export function* selectViewFromUrl(
  action: IActionWithPayload<string, IReportsViewSetQueryParamsFromURLPayload>,
): SagaIterator {
  const selectedViewId: string | null = action.payload.params.selectedView;

  if (!selectedViewId) {
    return;
  }

  yield put(ReportsViewActions.selectReportView({ id: selectedViewId, source: SelectedViewSource.URL }));
}

export function* handleSetCurrentView(action: IActionWithPayload<string, { reportType: ReportType }>): SagaIterator {
  const { reportType } = action.payload;

  const queryParams = browserHistory.queryParams;
  const state = browserHistory.getState();
  const source =
    state?.from && state.from === '/reports/saved-views' ? SelectedViewSource.SavedViews : SelectedViewSource.URL;

  if (queryParams?.selected_view && typeof queryParams.selected_view === 'string') {
    yield put(
      ReportsViewActions.selectReportView({
        id: queryParams.selected_view,
        source,
      }),
    );

    return;
  }

  const selectedView: IReportView | undefined = yield select(getSelectedReportView);

  if (!selectedView || selectedView.type === reportType) {
    return;
  }

  yield put(ReportsViewActions.unselectReportView());
}

function* updateReportColumnsVisibility(
  action: IActionWithPayload<string, ISaveColumnsVisibilityPayload>,
): SagaIterator {
  const {
    payload: { type, visibleColumns },
  } = action;
  const visibleColumnsForType = visibleColumns.join(',');
  const propertyName = reportVisibleColumnsPropertyName(type);

  yield put(AgentCustomPropertiesActions.setAgentCustomProperty({ [propertyName]: visibleColumnsForType }));
  yield put(ReportsViewActions.setColumnsVisibility({ visibleColumns: { [type]: visibleColumns } }));
}

function* updateReportColumnsOrder(action: IActionWithPayload<string, ISaveColumnsOrderPayload>): SagaIterator {
  const {
    payload: { type, columnsOrder },
  } = action;

  const columnsOrderForType = columnsOrder.join(',');
  const propertyName = reportColumnsOrderPropertyName(type);

  yield put(AgentCustomPropertiesActions.setAgentCustomProperty({ [propertyName]: columnsOrderForType }));
  yield put(ReportsViewActions.setColumnsOrder({ columnsOrder: { [type]: columnsOrder } }));
}

function* loadReportColumnsConfiguration(
  action: IActionWithPayload<string, IFetchAgentCustomPropertiesSuccessPayload>,
): SagaIterator {
  const properties = action.payload;

  for (const reportType of Object.values(ReportType)) {
    const orderKey = reportColumnsOrderPropertyName(reportType);
    const selectedKey = reportVisibleColumnsPropertyName(reportType);

    const order = properties[orderKey];
    const selected = properties[selectedKey];

    if (selected) {
      yield put(
        ReportsViewActions.setColumnsVisibility({
          visibleColumns: { [reportType]: (selected as string).split(',') },
        }),
      );
    }

    if (order) {
      yield put(
        ReportsViewActions.setColumnsOrder({
          columnsOrder: { [reportType]: (order as string).split(',') },
        }),
      );
    }
  }
}

function* handleCustomPropertiesFetched(
  action: IActionWithPayload<string, IFetchAgentCustomPropertiesSuccessPayload>,
): SagaIterator {
  yield call(loadReportViews, action);
}

export default function* reportsViewSagas(): SagaIterator {
  yield all([
    takeEvery(
      [
        ReportsViewActionsNames.SET_CURRENT_VIEW,
        ReportsViewActionsNames.SET_DISTRIBUTION,
        ReportsViewActionsNames.SET_BENCHMARK_ENABLED,
        ReportsViewActionsNames.RESET_FILTERS,
        ReportsViewActionsNames.UPDATE_FILTER,
        ReportsViewActionsNames.SET_FILTERS,
        ReportsViewActionsNames.UPDATE_FILTER_OPERATOR,
        ReportsViewActionsNames.FETCH_DATA,
        ReportsViewActionsNames.SET_PAGE,
        ReportsViewActionsNames.SET_LAST_7_DAYS_MODE,
      ],
      fetchReport,
    ),
    takeEvery(
      [
        ReportsViewActionsNames.SET_DISTRIBUTION,
        ReportsViewActionsNames.RESET_FILTERS,
        ReportsViewActionsNames.UPDATE_FILTER,
        ReportsViewActionsNames.SET_FILTERS,
        ReportsViewActionsNames.UPDATE_FILTER_OPERATOR,
        ReportsViewActionsNames.SET_PAGE,
        ReportsViewActionsNames.SET_LAST_7_DAYS_MODE,
        ReportsViewActionsNames.SET_BENCHMARK_ENABLED,
        ReportsViewActionsNames.SET_QUERY_PARAMS_FROM_URL,
      ],
      cleanCaches,
    ),
    takeEvery(ReportsViewActionsNames.SET_DISTRIBUTION, trackDistributionChange),
    takeEvery(ReportsViewActionsNames.UPDATE_FILTER, trackFiltersChange),
    takeEvery(ReportsViewActionsNames.UPDATE_FILTER_OPERATOR, trackFilterOperatorChange),
    takeEvery(ReportsViewActionsNames.CREATE_REPORT, createReport),
    takeEvery(ReportsViewActionsNames.EDIT_SCHEDULED_REPORT, editScheduledReport),
    takeEvery(ReportsViewActionsNames.DELETE_SCHEDULED_REPORT, deleteScheduledReport),
    takeEvery(ReportsViewActionsNames.CREATE_TICKET_FROM_QUEUE_ABANDONMENT, createTicketFromQueueAbandonment),
    takeEvery(ReportsViewActionsNames.FETCH_GREETINGS, fetchGreetings),
    takeEvery(ReportsViewActionsNames.FETCH_TAGS, fetchTags),
    takeEvery(ReportsViewActionsNames.FETCH_COMPANY_DETAILS, fetchCompanyDetails),
    takeEvery(CreateReportActionNames[RequestAction.SUCCESS], createReportSuccess),
    takeEvery(CreateReportActionNames[RequestAction.FAILURE], createReportFailure),
    takeEvery(EditScheduledReportActionNames[RequestAction.SUCCESS], editScheduledReportSuccess),
    takeEvery(EditScheduledReportActionNames[RequestAction.FAILURE], editScheduledReportFailure),
    takeEvery(DeleteScheduledReportActionNames[RequestAction.SUCCESS], deleteScheduledReportSuccess),
    takeEvery(DeleteScheduledReportActionNames[RequestAction.FAILURE], deleteScheduledReportFailure),
    takeEvery(
      CreateTicketFromQueueAbandonmentActionNames[RequestAction.SUCCESS],
      createTicketFromQueueAbandonmentSuccess,
    ),
    takeEvery(
      CreateTicketFromQueueAbandonmentActionNames[RequestAction.FAILURE],
      createTicketFromQueueAbandonmentFailure,
    ),
    ...Object.values(ReportType).map((reportName) =>
      takeEvery(REPORT[reportName][RequestAction.SUCCESS], updateReportViewData),
    ),
    debounce(
      500,
      Object.values(ReportType).map((reportName) => {
        return REPORT[reportName][RequestAction.FAILURE];
      }),
      fetchReportFailure,
    ),
    takeEvery(ReportsViewActionsNames.SET_LAST_7_DAYS_FILTERS, setLast7DaysFilters),
    takeEvery(ReportsViewActionsNames.SAVE_REPORT_VIEW, saveReportView),
    takeEvery(ReportsViewActionsNames.SAVE_REPORT_VIEW_SUCCESS, saveReportViewSuccess),
    takeEvery(ReportsViewActionsNames.SAVE_REPORT_VIEW_FAILURE, saveReportViewFailure),
    takeEvery(ReportsViewActionsNames.DELETE_REPORT_VIEW, deleteReportView),
    takeEvery(ReportsViewActionsNames.DELETE_REPORT_VIEW_SUCCESS, deleteReportViewSuccess),
    takeEvery(ReportsViewActionsNames.DELETE_REPORT_VIEW_FAILURE, deleteReportViewFailure),
    takeLatest(ReportsViewActionsNames.SELECT_REPORT_VIEW, selectReportView),
    takeLatest(
      [
        ReportsViewActionsNames.UPDATE_FILTER,
        ReportsViewActionsNames.SET_DISTRIBUTION,
        ReportsViewActionsNames.UPDATE_FILTER_OPERATOR,
      ],
      unselectReportView,
    ),
    takeEvery(ReportsViewActionsNames.SET_CURRENT_VIEW, handleSetCurrentView),
    takeEvery(
      AGENT_CUSTOM_PROPERTIES.FETCH_AGENT_CUSTOM_PROPERTIES[RequestAction.SUCCESS],
      handleCustomPropertiesFetched,
    ),
    takeLatest(ReportsViewActionsNames.SAVE_COLUMNS_VISIBILITY, updateReportColumnsVisibility),
    takeLatest(ReportsViewActionsNames.SAVE_COLUMNS_ORDER, updateReportColumnsOrder),
    takeLatest(
      AGENT_CUSTOM_PROPERTIES.FETCH_AGENT_CUSTOM_PROPERTIES[RequestAction.SUCCESS],
      loadReportColumnsConfiguration,
    ),
  ]);
}
