// @ts-strict-ignore
import { differenceInCalendarDays } from 'date-fns';
import { type SagaIterator } from 'redux-saga';
import { call, delay, put, select, takeEvery } from 'redux-saga/effects';

import { ChatType } from 'constants/chat-type';
import { CHAT_FEED_TEXT_AREA_TEST_ID } from 'constants/chats/chat-message-box';
import { ReasonCode } from 'constants/knowledge-sources-reason-codes';
import { HALF_MINUTE_IN_MS, ReplySuggestionEvent } from 'constants/reply-suggestions';
import { SprigEvents } from 'constants/sprig-events';
import { UserType } from 'constants/user-type';
import { EventPlace } from 'helpers/analytics';
import { mapSources } from 'helpers/copilot/copilot';
import { getIsFeatureEnabled } from 'helpers/feature-flags';
import { type KeyMap } from 'helpers/interface';
import { uniqueId } from 'helpers/string';
import { FeatureNames } from 'services/api/feature-control/interfaces';
import { type ApiClientResponse } from 'services/connectivity/http/types';
import { aiEngineClient } from 'services/connectivity/ml-gateway-api/ai-engine/client';
import {
  type GenerateReplyError,
  type GenerateReplyRequest,
  type GenerateReplyResponse,
} from 'services/connectivity/ml-gateway-api/ai-engine/types';
import { trackEvent } from 'services/event-tracking';
import { knowledgeSourcesEventCollector } from 'services/knowledge-sources-event-collector';
import { getSprigService } from 'services/sprig';
import { AIAgent } from 'store/entities/ai-agents/constants';
import { getAIAgentId } from 'store/entities/ai-agents/selectors';
import { ChatsEntitiesActionNames } from 'store/entities/chats/actions';
import { getMessageForReplySuggestions } from 'store/entities/chats/computed';
import { type ChatEventEntity, type ChatThreadEntity, type INewMessagePayload } from 'store/entities/chats/interfaces';
import { getThread, getThreadEvents } from 'store/entities/chats/selectors';
import { AgentCustomPropertiesActions } from 'store/features/agent-custom-properties/actions';
import {
  AgentCustomPropertyName,
  type IReplySuggestionsPromoCustomProperty,
} from 'store/features/agent-custom-properties/interfaces';
import {
  getReplySuggestionsOnboardingSeen,
  getReplySuggestionsPromo,
} from 'store/features/agent-custom-properties/selectors';
import { type IActionWithPayload } from 'store/helper';

import { ChatsViewActions, ChatsViewActionsNames } from '../actions';
import type { IReplySuggestionsGenerateReplyPayload } from '../interfaces';
import {
  getCanUseReplySuggestionsFeature,
  getCurrentMessageBoxValue,
  getIsReplySuggestionTriggered,
  getNumberOfCustomerMessagesInThread,
  getReplySuggestionsCurrentSessionId,
  getSelectedThreadId,
} from '../selectors';

import { getHasAnyKnowledgeSources, getIsTheSameMessage } from './helpers';

const MAXIMUM_MESSAGES_COUNT = 5;

function* handleReplySuggestionPromo(): SagaIterator {
  const replySuggestionsPromo: IReplySuggestionsPromoCustomProperty = yield select(getReplySuggestionsPromo);

  if (!replySuggestionsPromo || replySuggestionsPromo?.displayedSprigFormCount >= 2) {
    return;
  }

  const { replySuggestionCount, dateOfFirstUsage, displayedSprigFormCount } = replySuggestionsPromo;

  const updatedReplySuggestionCount = replySuggestionCount + 1;
  const updatedDateOfFirstUsage = dateOfFirstUsage ? dateOfFirstUsage : new Date();
  const differenceInDaysWithFirstUsage = differenceInCalendarDays(new Date(), updatedDateOfFirstUsage);

  const isFirstUsage = updatedReplySuggestionCount === 1;
  const isSecondUsage =
    updatedReplySuggestionCount > 15 && differenceInDaysWithFirstUsage > 7 && displayedSprigFormCount < 2;
  const shouldDisplayReplySuggestionForm = isFirstUsage || isSecondUsage;

  const updatedDisplayedSprigFormCount = shouldDisplayReplySuggestionForm
    ? displayedSprigFormCount + 1
    : displayedSprigFormCount;

  if (shouldDisplayReplySuggestionForm) {
    const sprigEvent = isFirstUsage
      ? SprigEvents.ReplySuggestionGeneratedFirstTime
      : SprigEvents.ReplySuggestionGenerated;

    const sprig = getSprigService();
    yield call([sprig, sprig.initSprigEvent], sprigEvent);
  }

  yield put(
    AgentCustomPropertiesActions.setAgentCustomProperty({
      [AgentCustomPropertyName.ReplySuggestionsPromo]: {
        dateOfFirstUsage: updatedDateOfFirstUsage,
        replySuggestionCount: updatedReplySuggestionCount,
        displayedSprigFormCount: updatedDisplayedSprigFormCount,
      },
    }),
  );
}

function handleReplySuggestionEvents(reasonCode?: ReasonCode): void {
  switch (reasonCode) {
    case ReasonCode.NoAgents:
    case ReasonCode.NoSkills: {
      trackEvent(ReplySuggestionEvent.NoSourcesErrorShown, EventPlace.Chats);
      break;
    }

    case ReasonCode.NoKnowledge: {
      trackEvent(ReplySuggestionEvent.NoDataErrorShown, EventPlace.Chats);
      break;
    }

    default:
      trackEvent(ReplySuggestionEvent.ErrorShown, EventPlace.Chats);
  }
}

export function* generateReply(payload: GenerateReplyRequest): SagaIterator {
  const { result, error }: ApiClientResponse<GenerateReplyResponse, GenerateReplyError> = yield call(() =>
    aiEngineClient.generateReply(payload),
  );

  return { result, error };
}

function* generateResponse(action: IActionWithPayload<string, IReplySuggestionsGenerateReplyPayload>): SagaIterator {
  const { threadId } = action.payload;
  const customerMessagesCount = yield select(getNumberOfCustomerMessagesInThread, threadId);
  const replySuggestionsAIAgentId: string | null = yield select(getAIAgentId, AIAgent.ReplySuggestions);

  // TODO-KS: How to handle missing reply suggestions ai agent id?
  if (!customerMessagesCount || !replySuggestionsAIAgentId) {
    return;
  }

  const isFullMessageContextEnabled = getIsFeatureEnabled(FeatureNames.ReplySuggestionsFullMessageContext);
  const message: string = yield select(
    getMessageForReplySuggestions,
    threadId,
    isFullMessageContextEnabled ? undefined : MAXIMUM_MESSAGES_COUNT,
  );

  yield put(
    ChatsViewActions.saveReplySuggestionToolbarState({
      threadId,
      isLoading: true,
      metadata: {
        message,
        replyTime: 0,
      },
    }),
  );

  const sessionId = uniqueId();
  const payload: GenerateReplyRequest = {
    messages: [
      {
        value: message,
      },
    ],
    /* eslint-disable-next-line @typescript-eslint/naming-convention */
    session_id: sessionId,
    executor: 'simple',
    /* eslint-disable-next-line @typescript-eslint/naming-convention */
    agent_id: replySuggestionsAIAgentId,
  };

  yield put(
    ChatsViewActions.setReplySuggestionSessionId({
      threadId,
      sessionId,
    }),
  );

  const { result, error }: ApiClientResponse<GenerateReplyResponse, GenerateReplyError> = yield call(
    generateReply,
    payload,
  );

  const currentRequestId = yield select(getReplySuggestionsCurrentSessionId, threadId);

  if (error) {
    const isExternalError = !error.http?.innerError;

    if (error.http?.innerError?.session_id !== sessionId && !isExternalError) {
      return;
    }

    handleReplySuggestionEvents(error.http?.innerError?.reason_code);

    yield put(
      ChatsViewActions.saveReplySuggestionToolbarState({
        threadId,
        isLoading: false,
        error: {
          code: error.http?.innerError?.code || 500,
          message: error.http?.innerError?.message,
          reasonCode: error.http?.innerError?.reason_code,
        },
        metadata: {
          message,
          replyTime: 0,
        },
      }),
    );

    return;
  }

  if (!result || result.session_id !== currentRequestId) {
    return;
  }

  knowledgeSourcesEventCollector.addEvent(threadId, {
    agentResponse: '',
    requestId: sessionId,
    useButtonClick: false,
    isAutomaticSuggestion: false,
  });

  yield call(handleReplySuggestionPromo);

  yield put(
    ChatsViewActions.saveReplySuggestionToolbarState({
      threadId,
      isLoading: false,
      metadata: {
        message,
        replyTime: result.metadata.response_time,
        traceId: result.trace_id,
      },
      data: {
        response: result.response,
        sources: mapSources(result.sources),
      },
    }),
  );

  trackEvent(ReplySuggestionEvent.ReplyReceived, EventPlace.Chats, { version: 'B' });
}

export function* getShouldShowReplySuggestionsOnboarding(payload: INewMessagePayload): SagaIterator {
  const { threadId, message } = payload;
  const selectedThreadId: string = yield select(getSelectedThreadId);
  const threadEvents: KeyMap<ChatEventEntity> = yield select(getThreadEvents, selectedThreadId);
  const currentMessageBoxValue: string = yield select(getCurrentMessageBoxValue);

  const isTheSameThread = threadId === selectedThreadId;
  const isTheSameMessage = getIsTheSameMessage(threadEvents, message.id);
  const hasAnyKnowledgeSources = getHasAnyKnowledgeSources();

  const isMessageBoxFocused =
    document.activeElement instanceof HTMLElement
      ? document.activeElement.dataset?.testid === CHAT_FEED_TEXT_AREA_TEST_ID
      : false;

  const isAgentTyping = isMessageBoxFocused || currentMessageBoxValue.length > 0;

  return !isAgentTyping && hasAnyKnowledgeSources && isTheSameThread && isTheSameMessage;
}

export function* showReplySuggestionOnboarding({
  payload,
}: IActionWithPayload<string, INewMessagePayload>): SagaIterator {
  const isOnboardingAlreadySeen = yield select(getReplySuggestionsOnboardingSeen);
  const canUseReplySuggestion = yield select(getCanUseReplySuggestionsFeature);
  const thread: ChatThreadEntity = yield select(getThread, payload.threadId);

  const isMyChat = thread.type === ChatType.My;
  const isCustomerMessage = payload.message.authorType === UserType.Customer;

  /**
   * There are two checks if onboarding can be seen - one can be done at start to provide early return
   * the other checks needs to be verified after ONE_MINUTE as requirement from business perspective
   */
  if (isOnboardingAlreadySeen || !canUseReplySuggestion || !isMyChat || !isCustomerMessage) {
    return;
  }

  yield delay(HALF_MINUTE_IN_MS);

  const shouldShowReplySuggestionsOnboarding = yield call(getShouldShowReplySuggestionsOnboarding, payload);
  const isReplySuggestionTriggered = yield select(getIsReplySuggestionTriggered, payload.threadId);
  const replySuggestionsAiAgent = yield select(getAIAgentId, AIAgent.ReplySuggestions);

  if (!shouldShowReplySuggestionsOnboarding || isReplySuggestionTriggered) {
    return;
  }

  const isFullMessageContextEnabled = getIsFeatureEnabled(FeatureNames.ReplySuggestionsFullMessageContext);
  const message: string = yield select(
    getMessageForReplySuggestions,
    payload.threadId,
    isFullMessageContextEnabled ? undefined : MAXIMUM_MESSAGES_COUNT,
  );
  const sessionId = uniqueId();

  const requestPayload: GenerateReplyRequest = {
    messages: [
      {
        value: message,
      },
    ],

    /* eslint-disable @typescript-eslint/naming-convention */
    session_id: sessionId,
    auto_triggered: true,
    agent_id: replySuggestionsAiAgent,
    /* eslint-enable @typescript-eslint/naming-convention */
    executor: 'simple',
  };

  const response: ApiClientResponse<GenerateReplyResponse, GenerateReplyError> = yield call(
    generateReply,
    requestPayload,
  );

  const isReplySuggestionTriggeredAfterGenerate = yield select(getIsReplySuggestionTriggered, payload.threadId);

  if (isReplySuggestionTriggeredAfterGenerate || response.error) {
    return;
  }

  knowledgeSourcesEventCollector.addEvent(payload.threadId, {
    agentResponse: '',
    requestId: sessionId,
    useButtonClick: false,
    isAutomaticSuggestion: true,
  });

  yield put(
    ChatsViewActions.saveReplySuggestionToolbarState({
      threadId: payload.threadId,
      isLoading: false,
      metadata: {
        message,
        replyTime: response.result.metadata.response_time,
      },
      data: {
        response: response.result.response,
        sources: mapSources(response.result.sources),
      },
      isOnboardingSuggestion: true,
    }),
  );

  trackEvent(ReplySuggestionEvent.ReplySuggestionOnboardingShown, EventPlace.Chats);
}

export function* replySuggestionsSaga(): SagaIterator {
  yield takeEvery(ChatsViewActionsNames.GENERATE_REPLY_SUGGESTIONS_REPLY, generateResponse);
  yield takeEvery(ChatsEntitiesActionNames.NEW_MESSAGE, showReplySuggestionOnboarding);
}
