import { css } from '@emotion/css';

import { COPILOT_ENABLED_OFFSET } from './constants';

export const inviteAgents = (hasGapFromRight = false): string => css`
  position: absolute;
  right: ${hasGapFromRight ? COPILOT_ENABLED_OFFSET : 0};

  div[data-testid='tooltip-trigger'] {
    z-index: 0;
  }
`;
