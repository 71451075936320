import { type FC, type ReactNode } from 'react';

import { cx } from '@emotion/css';
import { ArrowBack } from '@livechat/design-system-icons';
import { Heading, Icon, type IconSource } from '@livechat/design-system-react-components';

import liveChatLogo from 'assets/img/logo-livechat.svg';
import { FakeLink } from 'components/fake-link/FakeLink';
import { useEffectOnce } from 'hooks/use-effect-once';
import type { ErrorType } from 'routes/error-pages/components/types';
import { trackEvent } from 'services/event-tracking';
import { removeSessionAndRedirectToAccounts } from 'services/session';

import * as styles from './styles';

interface Props {
  errorType?: ErrorType;
  iconNode?: ReactNode;
  iconSource?: IconSource;
  title?: string;
  actionButtons?: ReactNode;
  showBackToLogin?: boolean;
}

export const FullscreenError: FC<Props> = ({
  errorType,
  iconNode,
  iconSource,
  title = '',
  actionButtons,
  showBackToLogin = true,
  children,
}) => {
  useEffectOnce(() => {
    if (errorType) {
      trackEvent('Error page viewed', null, { type: errorType });
    }
  });

  return (
    <div className={styles.wrapper}>
      <header className={styles.header}>
        <a href="https://www.livechat.com/">
          <img className={styles.logo} src={liveChatLogo} alt="LiveChat logo" />
        </a>
        {showBackToLogin && (
          <FakeLink onClick={() => void removeSessionAndRedirectToAccounts()} className={styles.backButton}>
            <Icon source={ArrowBack} /> Back to login
          </FakeLink>
        )}
      </header>
      <div className={cx(styles.content, 'light-theme')}>
        {iconNode && <div className={styles.iconNode}>{iconNode}</div>}
        {iconSource && <Icon source={iconSource} size="xlarge" className={styles.iconNode} />}
        {title && (
          <Heading size="md" className={styles.heading}>
            {title}
          </Heading>
        )}
        {children && <div className={styles.childrenWrapper}>{children}</div>}
        {actionButtons && <div className={styles.actionRow}>{actionButtons}</div>}
      </div>
    </div>
  );
};
